import { ReactNode, useMemo } from 'react';
import {
	ModelElement,
	ModelElementType,
} from '../../models/ModelElement.entity';
import { pipePlacementFn } from '../utils/PlacementFn';
import { SelectionMesh } from '../SelectionMesh';
import {
	ComponentId,
	PipeGeometry,
	SubElement,
} from '../../../../SharedTypes/API/Explorer';

// Define the properties for the RealisticPipeMesh component
interface PipeMeshProps {
	pipes: ModelElement[];
	pipeSegments?: number; // Optional property to define the number of segments in each pipe
	isHighlighted?: boolean;
}

// Calculate the grouped pipes based on the geometry. This function is used to group pipes with the same diameter
// We are forced to use this type of sorting instead of the other type used in the other realistic meshes as
// the subElements play a much bigger role and we are dependant on them, and can't use the parent element to sort
// Function to determine the key used for grouping pipes
function determineGroupingKey(geometry: PipeGeometry): string {
	const startDiameter = geometry[0];
	const endDiameter = geometry[1];
	return startDiameter !== endDiameter
		? `${startDiameter},${endDiameter}` // Tapered section diameters as key
		: 'noTaperedSection'; // Common key for non-tapered sections
}

// Function to add a pipe model element to the correct group
function addToGroup(
	newGroupedPipes: Record<string, ModelElement[]>,
	key: string,
	pipeModelElement: ModelElement
): void {
	if (!newGroupedPipes[key]) {
		newGroupedPipes[key] = [];
	}
	newGroupedPipes[key].push(pipeModelElement);
}

// Main function for grouping pipes
const calculateGroupedPipes = (
	pipes: ModelElement[]
): Record<string, ModelElement[]> => {
	const newGroupedPipes: Record<string, ModelElement[]> = {};

	pipes.forEach((pipe) => {
		const subElements = pipe[4] as SubElement[];

		subElements.forEach((element, index) => {
			const geometry = element[2] as PipeGeometry;
			const medianDiameter = (geometry[0] + geometry[1]) / 2;
			const key = determineGroupingKey(geometry);

			if (medianDiameter !== 0) {
				const pipeModelElement: ModelElement = createPipeModelElement(
					pipe,
					element
				);
				addToGroup(newGroupedPipes, key, pipeModelElement);

				// Special case for adding the pipe itself
				if (index === subElements.length - 1 && key === 'noTaperedSection') {
					addToGroup(newGroupedPipes, key, pipe);
				}
			}
		});
	});

	return newGroupedPipes;
};

const createPipeModelElement = (
	pipe: ModelElement,
	element: SubElement
): ModelElement => {
	return [
		element[0] as ComponentId, // id from subElement
		'pipe' as ModelElementType, // type
		pipe[2], // color from pipe
		pipe[3], // unitVector from pipe
		[element], // empty array for subElements
		...element[1], // coordinates from subElement
	] as ModelElement; // cast to ModelElement by force
};

// The RealisticPipeMesh component, responsible for generating the mesh for multiple pipes
export const RealisticPipeMesh = ({
	pipes,
	pipeSegments = 32,
	isHighlighted,
}: PipeMeshProps) => {
	const groupedPipes = useMemo(() => calculateGroupedPipes(pipes), [pipes]);

	return (
		<>
			{Object.keys(groupedPipes).length > 0 &&
				Object.entries(groupedPipes).map(([key, elements]) => {
					const [primaryValue, secondaryValue] = key.split(',');
					const scale = key === 'noTaperedSection';

					return (
						<PipeGroup
							key={key}
							pipes={elements}
							pipeSegments={pipeSegments}
							radiusBottom={scale ? 1 : parseFloat(secondaryValue) / 2}
							radiusTop={scale ? 1 : parseFloat(primaryValue) / 2}
							scale={scale}
							isHighlighted={isHighlighted}
						/>
					);
				})}
		</>
	);
};

// Define the properties for the PipeGroup component
interface PipeGroupProps {
	pipes: ModelElement[];
	pipeSegments: number;
	radiusBottom?: number;
	radiusTop?: number;
	scale?: boolean;
	isHighlighted?: boolean;
}

// The PipeGroup component, responsible for rendering a group of pipes with the same radius
const PipeGroup = ({
	pipes,
	pipeSegments,
	radiusBottom,
	radiusTop,
	scale,
	isHighlighted: hasEffectOutline,
}: PipeGroupProps) => {
	// Component for instanced geometry with cylinder buffer geometry
	const InstancedGeometry = ({ children }: { children: ReactNode }) => (
		<cylinderGeometry
			args={[
				radiusBottom,
				radiusTop,
				1,
				pipeSegments,
				1,
				false,
				Math.PI * 0.25,
			]} // Arguments for the cylinder geometry
			attach="geometry"
		>
			{children}
		</cylinderGeometry>
	);

	// Render a SelectionMesh with the calculated geometry and placement function
	return (
		<>
			<SelectionMesh
				category="element"
				components={pipes}
				InstancedGeometry={InstancedGeometry}
				placementFn={pipePlacementFn}
				scale={scale}
				isHighlighted={hasEffectOutline}
			/>
		</>
	);
};
