import { FC } from 'react';
import './ChartWarning.scss';

type Props = {
	dateString: string;
	showDateWarning?: boolean;
};

export const ChartWarning: FC<Props> = ({
	dateString,
	showDateWarning = true,
}) => (
	<div className="ChartWarning">
		<div className="ChartWarning__Message">
			<p className="ChartWarning__Message--Bold">
				<b>Notice: Data is shown for {dateString}.&nbsp;</b>
				{showDateWarning && (
					<>Select a date range of maximum 24 hours to inspect hourly data</>
				)}
			</p>
		</div>
	</div>
);
