import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { Button } from '../Core/components/Button/Button';
import { FormInput } from '../Core/components/FormInput/FormInput';
import './LoginForm.scss';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ColorLookup } from '../Core/utils/ColorLookup';
import classNames from 'classnames';
import { api, useLoginUserMutation } from '../Core/Api';
import { useAppDispatch } from '../Core/redux/useAppDispatch';
import { useBoolean, useEffectOnce } from 'usehooks-ts';
import { never } from '../Core/utils/Function';
import { resetLoginFlow, startOnboardingFlow } from './Login.slice';

const schema = z.object({
	username: z.string().email('Please check your e-mail'),
	password: z.string(),
});

type Schema = z.infer<typeof schema>;

export const LoginForm = () => {
	const navigate = useNavigate();
	const [loginUser, response] = useLoginUserMutation();
	const dispatch = useAppDispatch();

	// Set the initial login flow state on mount
	useEffectOnce(() => {
		dispatch(resetLoginFlow());
	});

	// State for wether login errored out
	const { value: hasError, setValue: setHasError } = useBoolean(false);

	// Setup React Hook Form handler
	const { control, handleSubmit, getValues } = useForm<Schema>({
		resolver: zodResolver(schema),
		mode: 'onBlur',
		shouldFocusError: true,
		defaultValues: {
			password: '',
			username: '',
		},
	});

	// Handle the submit call
	const onSubmit = handleSubmit(({ username, password }) => {
		setHasError(false);
		loginUser({ username, password });
	});

	// Control error state based on response
	useEffect(() => {
		if (response.isLoading) {
			setHasError(false);
			return;
		}

		if (response.isError) {
			setHasError(true);
		}

		if (response.isSuccess && response.data.state === 'unauthorized') {
			setHasError(true);
		}
	});

	// Handle different signin/onboarding flows
	useEffect(() => {
		if (!response.data) {
			return;
		}

		switch (response.data.state) {
			case 'authorized':
				// Remove any cached queries from RTK
				dispatch(api.util.resetApiState());

				// Navigate to root to "start over"
				navigate('/');

				return;
			case 'newPasswordRequired':
				// Remove any cached queries from RTK
				dispatch(api.util.resetApiState());

				// Get the email and password value to start the onboarding flow
				const { username, password } = getValues();

				// Start the onboarding flow
				dispatch(
					startOnboardingFlow({
						email: username,
						oldPassword: password,
					})
				);

				// Navigate to the onboarding flow
				navigate(`/onboarding/create-password`);
				return;
			case 'unauthorized':
				// Handled elsewhere
				return;
			default:
				never(response.data.state);
		}
	}, [dispatch, getValues, navigate, response.data]);

	return (
		<div className="LoginForm">
			<div
				className={classNames(
					hasError
						? ['LoginForm__Error', 'LoginForm__Error--Active']
						: ['LoginForm__Error']
				)}
				style={{ backgroundColor: ColorLookup.forGraph['red'] }}
			>
				Could not sign in with provided credentials
			</div>

			<form className="LoginForm__Form" onSubmit={onSubmit}>
				<div className="LoginForm__Input">
					<FormInput
						theme="Login"
						control={control}
						name="username"
						label="E-mail"
					/>
				</div>
				<div className="LoginForm__Input">
					<FormInput
						theme="Login"
						control={control}
						name="password"
						label="Password"
						type="password"
					/>
				</div>

				<div className="LoginForm__Actions">
					<div className="LoginForm__Submit">
						<Button type="submit" theme="Blue" height="Large">
							Log in
						</Button>
					</div>
					<div className="LoginForm__ForgotPassword">
						<Button
							type="button"
							theme="GreyText"
							onClick={() => navigate('/forgotpassword')}
						>
							Forgot password
						</Button>
					</div>
				</div>
			</form>
		</div>
	);
};
