import { ReactNode, useMemo } from 'react';
import { ExtrudeGeometryOptions, Shape } from 'three';

interface IBeamExtrusionProps {
	children?: ReactNode;
	height?: number;
	topWidth?: number;
	topThickness?: number;
	bottomWidth?: number;
	bottomThickness?: number;
	webThickness?: number;
}



// This is the extrusion of the IBeam shape. It is only used in the RealisticIBeamMesh component.
export const IBeamExtrusion = ({
	children,
	height = 1.4,
	topWidth = 1.4,
	topThickness = 0.2,
	bottomWidth = 1.4,
	bottomThickness = 0.2,
	webThickness = 0.2,
}: IBeamExtrusionProps) => {
	// This is the extrusion settings of the IBeam.
	// bevel is disabled because it is not needed and it is expensive to calculate + it looks bad :)
	// It is on purpose the depth is set to 1, because the IBeam is scaled in the RealisticIBeamMesh component.
	const extrudeSettings: ExtrudeGeometryOptions = useMemo(
		() => ({
			bevelEnabled: false,
			depth: 1,
		}),
		[]
	);

	// This is the shape of the IBeam. It is only used in the RealisticIBeamMesh component.
	const shape = useMemo(
		() =>
			createIBeamShape({
				height,
				topWidth,
				topThickness,
				bottomWidth,
				bottomThickness,
				webThickness,
			}),
		[height, topWidth, topThickness, bottomWidth, bottomThickness, webThickness]
	);

	return (
		<extrudeGeometry args={[shape, extrudeSettings]}>
			{children}
		</extrudeGeometry>
	);
};

interface IBeamShapeParams {
	height: number;
	topWidth: number;
	topThickness: number;
	bottomWidth: number;
	bottomThickness: number;
	webThickness: number;
}

// Function to create the I-beam shape
function createIBeamShape({
	height,
	topWidth,
	topThickness,
	bottomWidth,
	bottomThickness,
	webThickness,
}: IBeamShapeParams): Shape {
	const shape = new Shape();
	const halfWebHeight = (height - (topThickness + bottomThickness)) / 2;

	// Constructing the I-beam shape
	shape.moveTo(webThickness / 2, 0);
	shape.lineTo(webThickness / 2, halfWebHeight);
	shape.lineTo(topWidth / 2, halfWebHeight);
	shape.lineTo(topWidth / 2, halfWebHeight + topThickness);
	shape.lineTo(-topWidth / 2, halfWebHeight + topThickness);
	shape.lineTo(-topWidth / 2, halfWebHeight);
	shape.lineTo(-webThickness / 2, halfWebHeight);
	shape.lineTo(-webThickness / 2, -halfWebHeight);
	shape.lineTo(-bottomWidth / 2, -halfWebHeight);
	shape.lineTo(-bottomWidth / 2, -halfWebHeight - bottomThickness);
	shape.lineTo(bottomWidth / 2, -halfWebHeight - bottomThickness);
	shape.lineTo(bottomWidth / 2, -halfWebHeight);
	shape.lineTo(webThickness / 2, -halfWebHeight);

	return shape;
}
