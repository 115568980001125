// TODO Replace with i18next versions i guess?
const months = [
	'January',
	'February',
	'March',
	'April',
	'May',
	'June',
	'July',
	'August',
	'September',
	'October',
	'November',
	'December',
];

export const getMonthShort = (date: Date | number | string): string => {
	const month = new Date(date).getMonth();
	return months[month].slice(0, 3);
};

export const getMonth = (date: Date | number | string): number => {
	// This seems to be zero indexed, whilst the rest is 1 indexed, strange
	return new Date(date).getMonth() + 1;
};

export const getYear = (date: Date | number | string): number => {
	return new Date(date).getFullYear();
};

export const getDate = (date: Date | number | string): number => {
	return new Date(date).getDate();
};

export const getDay = (date: Date | number | string): number => {
	return new Date(date).getDay();
};

export const getHour = (date: Date | number | string): number => {
	return new Date(date).getHours();
};

export const getMinutes = (date: Date | number | string): number => {
	return new Date(date).getMinutes();
};

export const getPadded = (value: number): string => {
	if (value < 10) {
		return `0${value}`;
	}

	return value.toString();
};

/** Get a scrubbed date representing today at 00:00 */
export const getToday = (): Date => {
	const today = new Date();

	return new Date(today.getFullYear(), today.getMonth(), today.getDate());
};

/** Get a date representing today at 00:00 with the given days added (negative numbers look back in time) */
export const getDaysFromToday = (days: number): Date => {
	// Get a scrubbed date representing today at 00:00
	const today = getToday();

	// Add the days to it
	return new Date(today.getTime() + days * 24 * 60 * 60 * 1000);
};

export const toUTC = (date: Date | number | string): string => {
	return new Date(date).toUTCString();
};
