// @ts-ignore
import { FC } from 'react';
import { useForm } from 'react-hook-form';
import { Button } from '../../Core/components/Button/Button';
import { FormInput } from '../../Core/components/FormInput/FormInput';
import './AdminAccountForm.scss';

type Props = {
	name: string;
	slug: string;
	updateSite: ({ name, slug }: { name: string; slug: string }) => void;
};

// Throws error without the type annotation on Windows for some reason
type UserSlug = {
	name: string;
	slug: string;
};

export const AdminSiteForm: FC<Props> = ({ name, slug, updateSite }) => {
	const { handleSubmit, control } = useForm({
		defaultValues: {
			name,
			slug,
		},
	});

	const saveChanges = ({ name, slug }: UserSlug) => {
		return updateSite({ name, slug });
	};

	return (
		<form className="AdminAccountForm" onSubmit={handleSubmit(saveChanges)}>
			<FormInput control={control} label="Name" name="name" theme="Admin" />
			<FormInput control={control} label="Slug" name="slug" theme="Admin" />

			<Button className="AdminAccountForm__Submit" type="submit" theme="Blue">
				Save site
			</Button>
		</form>
	);
};
