/** Determine if an array includes a given value */
export const includes =
	<T extends unknown>(arr: T[]) =>
	(value: T) =>
		arr.includes(value);

export const any = (arr: any[]) => arr.length > 0;

export const last = <T>(arr: T[]) =>
	arr.length > 0 ? arr[arr.length - 1] : null;

export const createArrayWith = <T>(numberOfItems: number, item: T): T[] =>
	Array.from({ length: numberOfItems }, () => item);

/**
 * @param array - The array to be updated
 * @param indexA - The index of the first element to be switched
 * @param indexB - The index of the second element to be switched
 * @returns A new array with the elements at indexA and indexB switched
 * @example
 * const array = [1, 2, 3, 4, 5];
 * console.log(switchArrayElements(array, 0, 4)); // [5, 2, 3, 4, 1]
 */

export const switchArrayElements = (
	array: any[],
	indexA: number,
	indexB: number
) => {
	const newArray = [...array];
	[newArray[indexA], newArray[indexB]] = [newArray[indexB], newArray[indexA]];
	return newArray;
};
