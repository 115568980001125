import { ResultColor } from '../../SharedTypes/API/Explorer';

const green = '#06BB75';
const orange = '#F57800';
const yellow = '#deb73f';
const red = '#E00039';
const grey = '#517886';
const blue = '#009DE0';
const blue2 = '#94dfff';

// const green = '#00DEB9';
// const orange = '#E0621E';
// const yellow = '#E0A024';
// const red = '#E11453';
// const grey = '#517886';

const forElement: Record<ResultColor, string> = {
	green,
	orange,
	yellow,
	red,
	grey,
	none: '#deb73f',
	neutral: '#ccc',
	blue,
	blue2,
};

const forJoint: Record<ResultColor, string> = {
	green,
	orange,
	yellow,
	red,
	grey,
	none: '#fff',
	neutral: '#ccc',
	blue,
	blue2,
};

const forGraph: Record<ResultColor, string> = {
	green,
	orange,
	yellow,
	red,
	grey,
	none: '#fff',
	neutral: '#ccc',
	blue,
	blue2,
};

export const ColorLookup = {
	forElement,
	forJoint,
	forGraph,
};
