import './DragOverScrollSection.scss';
import { smoothHorizontalScroll } from '../../Core/utils/Scroll';

const useScrollHorizontal = (scrollRef: React.RefObject<HTMLDivElement>) => {
	const handleScroll = (direction: 'left' | 'right') => {
		const resultsTable = scrollRef.current;

		if (!resultsTable) return;

		const target =
			resultsTable.scrollLeft + (direction === 'left' ? -100 : 100);
		smoothHorizontalScroll(resultsTable, target, 200);
	};

	return { handleScroll };
};

type Props = {
	enabled: boolean;
	scrollRef: React.RefObject<HTMLDivElement>;
};

export const DragOverScrollSection = ({ enabled, scrollRef }: Props) => {
	const { handleScroll } = useScrollHorizontal(scrollRef);

	return (
		<>
			{enabled && (
				<div className="DragOverScrollSection">
					<div
						onDragOver={() => {
							handleScroll('left');
						}}
						className="DragOverScrollSection__Left"
					/>
					<div
						onDragOver={() => {
							handleScroll('right');
						}}
						className="DragOverScrollSection__Right"
					/>
				</div>
			)}
		</>
	);
};
