import { marked } from 'marked';

export const MarkdownRenderer = ({ markdown }: { markdown: string }) => {
	const parsedMarkdown = marked(markdown);

	// Since parsedMarkdown can be a promise, we return null if it is
	if (typeof parsedMarkdown !== 'string') {
		return null;
	}

	return <div dangerouslySetInnerHTML={{ __html: parsedMarkdown }}></div>;
};
