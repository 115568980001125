import { ReactNode } from 'react';
import { ModelElement } from '../../models/ModelElement.entity';
import { SelectionMesh } from '../SelectionMesh';
import { iBeamPlacementFn } from '../utils/PlacementFn';
import { IBeamExtrusion } from './geometry/IBeamExtrusion';
import { IGeometry } from '../../../../SharedTypes/API/Explorer';
import { sortArrays } from '../utils/SortArrays';

interface IBeamMeshProps {
	iBeams: ModelElement[];
	isHighlighted?: boolean;
}

export const RealisticIBeamMesh = ({
	iBeams,
	isHighlighted,
}: IBeamMeshProps) => {
	const sortedIBeams = sortArrays(iBeams);

	return (
		<>
			{sortedIBeams.map((group) => (
				<IBeamGroup
					key={group.key}
					iBeams={group.elements}
					isHighlighted={isHighlighted}
				/>
			))}
		</>
	);
};

const IBeamGroup = ({ iBeams, isHighlighted }: IBeamMeshProps) => {
	const geometry = iBeams[0][4][0][2] as IGeometry; // Get the geometry of the first element

	const InstancedIBeamsGeometry = ({ children }: { children: ReactNode }) => (
		<IBeamExtrusion
			height={geometry[0] * 2}
			topWidth={geometry[1] * 2}
			topThickness={geometry[2] * 2}
			bottomWidth={geometry[3] * 2}
			bottomThickness={geometry[4] * 2}
			webThickness={geometry[5] * 2}
		>
			{children}
		</IBeamExtrusion>
	);
	return (
		<SelectionMesh
			category="element"
			components={iBeams}
			InstancedGeometry={InstancedIBeamsGeometry}
			placementFn={iBeamPlacementFn}
			isHighlighted={isHighlighted}
		/>
	);
};
