import {
	SubElement,
	Geometry,
	IGeometry,
	BoxGeometry,
} from '../../../../SharedTypes/API/Explorer';
import { ModelElement } from '../../models/ModelElement.entity';

// This function sorts an array of ModelElements into groups based on their geometry.
// Each group is identified by a unique key which describes the geometry of the elements in that group.
// It returns an array of objects, each with a 'key' describing the geometry and 'elements' which is an array of ModelElements sharing that geometry.
export function sortArrays(
	modelElements: ModelElement[]
): { key: string; elements: ModelElement[] }[] {
	// Initialize a Map to hold the groups of elements sorted by their geometry.
	// The key is a string representation of the geometry, and the value is an array of ModelElements.
	const sortedMap = new Map<string, ModelElement[]>();

	// Iterate through each ModelElement in the input array.
	for (const element of modelElements) {
		// Extract the SubElements from the ModelElement.
		const subElements: SubElement[] = element[4];

		// Iterate through each SubElement.
		for (const subElement of subElements) {
			// Extract the Geometry from the SubElement.
			const geometry: Geometry = subElement[2];

			// Assign the key string which will represent the geometry.
			const key: string = (() => {
				switch (element[1]) {
					case 'box':
						return getKeyForBoxGeometry(geometry as BoxGeometry);
					case 'I':
						return getKeyForIGeometry(geometry as IGeometry);
					// Add more cases as needed
					default:
						throw new Error(`Unknown ModelElementType: ${element[1]}`);
				}
			})();

			// Check if the key already exists in the map. If not, add it with an empty array.
			if (!sortedMap.has(key)) {
				sortedMap.set(key, []);
			}
			// Add the current ModelElement to the array for the corresponding key in the map.
			sortedMap.get(key)?.push(element);
		}
	}

	// Initialize an array to hold the sorted groups.
	const sortedArray: { key: string; elements: ModelElement[] }[] = [];
	// Convert the map to the array structure, with each entry containing the key and its corresponding elements.
	sortedMap.forEach((value, key) => {
		sortedArray.push({ key, elements: value });
	});

	// Return the array of sorted groups.
	return sortedArray;
}

// Function to generate key string for a BoxGeometry
function getKeyForBoxGeometry(bg: BoxGeometry): string {
	return `BoxGeometry: height=${bg[0]}, width=${bg[1]}, flangeThickness=${bg[2]} webThickness=${bg[3]}`;
}

// Function to generate key string for an IGeometry
function getKeyForIGeometry(ig: IGeometry): string {
	return `IGeometry: height=${ig[0]}, topWidth=${ig[1]}, topThickness=${ig[2]}, bottomWidth=${ig[3]}, bottomThickness=${ig[4]}, webThickness=${ig[5]}`;
}
