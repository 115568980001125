import React, { useRef, useState, useEffect } from 'react';
import { Mesh, Camera, MeshPhongMaterial } from 'three';
import { useCamera } from '@react-three/drei';
import { useThree } from '@react-three/fiber';
import { animate, AnimationPlaybackControls } from 'framer-motion';
import { Vector3 } from 'three';
import { createTextureFromText } from '../utils/CreateTextureFromText';
import { useViewCubeFunctions } from '../hooks/useViewCubeFunctions';
import { OrbitControls as OrbitControlsImpl } from 'three-stdlib';
import { useCubeHover } from '../state/state';

// Add the type for PlaneComponentProps
export interface PlaneComponentProps {
	planeRotation: [number, number, number];
	planePosition: [number, number, number];
	planeSize: [number, number];
	text: string;
	opacity: number;
	fadeInDuration: number;
	fadeOutDuration: number;
	vbCamera: React.MutableRefObject<Camera>;
	targetCameraPosition: React.MutableRefObject<Vector3 | undefined>;
	cubeSize: number;
}

const PlaneComponent: React.FC<PlaneComponentProps> = ({
	planeRotation,
	planePosition,
	planeSize,
	text,
	opacity,
	fadeInDuration,
	fadeOutDuration,
	vbCamera,
	targetCameraPosition,
	cubeSize: cubeNoHoverSize,
}) => {
	const controls = useThree((state) => state.controls) as OrbitControlsImpl;
	const { camera } = useThree();
	const planeRef = useRef<Mesh>(null!); // Each plane has their own ref. Makes it easier to control.
	const [sectionHover, setSectionHover] = useState(false); // Controls state for individual section (planes, edges, corners) of hover.
	const useVbCamera = useCamera(vbCamera);
	const cubeHover = useCubeHover();

	const { handlePointerOut, handlePointerMove, handleCubeClick } =
		useViewCubeFunctions(camera, controls, targetCameraPosition);

	// This increases the size of the view cube planes to hide the edges and corners during the time of no hover.
	// Needed to avoid ugly hover transitions.
	if (!cubeHover) {
		planeSize[0] = cubeNoHoverSize;
		planeSize[1] = cubeNoHoverSize;
	}

	const animationControls = useRef<AnimationPlaybackControls>(); // Used to stop the animation when the cube is no longer hovered
	// This useEffect handles animations for opacity during view cube hover state change.
	useEffect(() => {
		const material = planeRef.current.material as MeshPhongMaterial;

		// Fade in. Currently set at 0 ms. Instant.
		if (material && cubeHover) {
			animationControls.current && animationControls.current.stop(); // To avoid hover bug
			animationControls.current = animate(opacity, 1, {
				duration: fadeInDuration,
				onUpdate: (value) => {
					material.opacity = value;
					material.needsUpdate = true;
				},
			});
		}
		// Fade out.
		else if (material && !cubeHover) {
			animationControls.current = animate(1, opacity, {
				duration: fadeOutDuration,
				onUpdate: (value) => {
					material.opacity = value;
					material.needsUpdate = true;
				},
			});
			setSectionHover(false);
		}
	}, [cubeHover, opacity, fadeInDuration, fadeOutDuration, text]);

	return (
		<mesh
			ref={planeRef}
			raycast={useVbCamera}
			position={planePosition}
			rotation={planeRotation}
			onPointerOut={(e) => {
				handlePointerOut(e, setSectionHover);
			}}
			onPointerMove={(e) => {
				handlePointerMove(e, setSectionHover);
			}}
			onClick={(e) => {
				setSectionHover(false);
				handleCubeClick(planePosition, e);
			}}
		>
			<planeGeometry args={planeSize} />

			{/*"RIGHT, LEFT, etc. name texture"*/}
			<meshPhongMaterial
				map={createTextureFromText({ sectionHover, cubeHover, text })}
				transparent
			/>
		</mesh>
	);
};

export default PlaneComponent;
