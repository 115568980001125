import { LoginForm } from './LoginForm';
import background from './login-background.jpg';
import { Icon } from '../Core/components/Icon/Icon';
import { Button } from '../Core/components/Button/Button';
import './Login.scss';
import { Outlet } from 'react-router-dom';

export const LoginPage = () => {
	return (
		<div className="Login">
			<div
				className="Login__BackgroundImage"
				style={{ backgroundImage: `url(${background})` }}
			></div>
			<div className="Login__Logo">
				<Icon name="RambollLogoNegative" width={100} />
			</div>
			<div className="Login__Form">
				<div className="Login__Icon">
					<Icon name="RiamsLogo" width={50} />
				</div>
				<Outlet />
				<div className="Login__TOS">
					<Button theme="GreyText">
						<a
							className="Login__Link"
							href={`/assets/${encodeURIComponent(
								'RIAMS - Terms and Conditions for use.pdf'
							)}`}
						>
							Terms of use
						</a>
					</Button>
					<Button theme="GreyText">
						<a
							className="Login__Link"
							href={`/assets/${encodeURIComponent(
								'RIAMS - Privacy policy.pdf'
							)}`}
						>
							Privacy policy
						</a>
					</Button>
				</div>
			</div>
		</div>
	);
};

export const Login = () => (
	<>
		<h1 className="Login__Title">Sign in</h1>
		<LoginForm />
	</>
);
