import classNames from 'classnames';
import React, { useMemo, useRef } from 'react';
import { CSSProperties } from 'react';
import { throttle } from 'lodash';
import { useEventListener } from 'usehooks-ts';

import { noop } from '../../utils/Function';
import './DataTable.scss';
import { SelectedDivider, SelectedDividerButton } from './SelectedDivider';
import { useDragging } from '../../../RIAMS/AnalysisExplorer/ResultsTable/state/ResultsState';
import { DragOverScrollSection } from '../../../RIAMS/AnalysisExplorer/DragOverScrollSection';

// --- Types ---

/** The table column definition */
export type TableColumn = {
	key: string;
	title: string;
	subtitle: string;
	width: string;
	isSticky: boolean;
};

// For the row render-component we supply styles to be applied to each cell for correct sticky behavior
export type TableCellColumn = TableColumn & { style: CSSProperties };

// --- Utility functions ---

// Get the template-columns value for each table row
const getGridTemplateColumns = ({
	columns,
}: {
	columns: TableColumn[];
}): string => columns.map((column) => column.width).join(' ');

// Get the total width of all the columns to get the correct scroll values
const getTotalWidth = ({ columns }: { columns: TableColumn[] }): string =>
	// Laymans Functor
	[columns]
		// Get the width of each column
		.map((columns) => columns.map((column) => column.width))
		// Make a string that sums each value
		.map((columns) => columns.join(' + '))
		// Wrap in a calc() wrapper
		.map((calcString) => `calc(${calcString})`)
		// Back to a string again
		.join();

// --- Sub-components ---

// Render each row with the supplied component with calculated styles
const DataTableRow = <T extends object>({
	row,
	columns,
	rowComponent,
	totalWidth,
}: {
	row: T;
	columns: TableColumn[];
	rowComponent: (props: {
		row: T;
		columns: TableCellColumn[];
		style: CSSProperties;
	}) => JSX.Element;
	totalWidth: string;
}) => {
	// Possible optimization: Use CSS vars instead of inlining styles on each row
	const style: CSSProperties = {
		display: 'grid',
		gridTemplateColumns: getGridTemplateColumns({ columns }),
		width: totalWidth,
	};

	// We add pre-computed styles here for the table cells
	const tableCellColumns: TableCellColumn[] = columns.map((column) => ({
		...column,
		style: {
			...(column.isSticky
				? {
						position: 'sticky',
						zIndex: 1,
						left: 0,
				  }
				: {}),
		},
	}));

	return rowComponent({ columns: tableCellColumns, row, style });
};

// Render the table header with the supplied header component with calculated styles
const DataTableHeaderRow = ({
	columns,
	headerRowComponent,
	totalWidth,
}: {
	columns: TableColumn[];
	headerRowComponent: (props: {
		columns: TableCellColumn[];
		style: CSSProperties;
	}) => JSX.Element;
	totalWidth: string;
}) => {
	// Possible optimization: Use CSS vars instead of inlining styles on each row
	const style: CSSProperties = {
		display: 'grid',
		position: 'sticky',
		top: 0,
		gridTemplateColumns: getGridTemplateColumns({ columns }),
		width: totalWidth,
		zIndex: 2,
		backgroundColor: '#002532',
	};

	// We add pre-computed styles here for the table cells
	const tableCellColumns: TableCellColumn[] = columns.map((column) => ({
		...column,
		style: {
			...(column.isSticky
				? {
						position: 'sticky',
						zIndex: 1,
						left: 0,
						top: 0,
				  }
				: {}),
		},
	}));

	return headerRowComponent({ columns: tableCellColumns, style });
};

interface Props<T> {
	rows: T[];
	selectedRows: T[];
	columns: TableColumn[];
	style?: CSSProperties;
	headerComponent: (props: {
		columns: TableCellColumn[];
		style: CSSProperties;
	}) => JSX.Element;
	selectedRowComponent: (props: {
		row: T;
		columns: TableCellColumn[];
		style: CSSProperties;
	}) => JSX.Element;
	rowComponent: (props: {
		row: T;
		columns: TableCellColumn[];
		style: CSSProperties;
	}) => JSX.Element;
	className?: string;
	onLoadMore?: () => void;
	onClearSelection?: () => void;
	isDragging?: boolean;
}

export const useScrollToBottomEvent = ({
	elementRef,
	fn,
	throttleTimeout,
}: {
	elementRef: React.RefObject<HTMLDivElement>;
	fn: () => void;
	throttleTimeout: number;
}) => {
	const handleScroll = useMemo(() => {
		function isScrolledToBottom(element: HTMLDivElement) {
			return element.scrollHeight - element.scrollTop === element.clientHeight;
		}

		return throttle(() => {
			if (!elementRef || elementRef.current == null) {
				return;
			}

			if (isScrolledToBottom(elementRef.current)) {
				fn();
			}
		}, throttleTimeout);
	}, [fn, elementRef, throttleTimeout]);

	useEventListener('scroll', handleScroll, elementRef);
};

export const DataTable = <T extends { key: string }>({
	rows,
	selectedRows,
	columns,
	style = {},
	headerComponent,
	selectedRowComponent,
	rowComponent,
	className,
	onLoadMore = noop,
	onClearSelection = noop,
}: Props<T>) => {
	const scrollElementRef = useRef<HTMLDivElement>(null);

	const totalRowWidth = getTotalWidth({ columns });

	// Setup infinite scroll
	useScrollToBottomEvent({
		elementRef: scrollElementRef,
		fn: onLoadMore,
		throttleTimeout: 300,
	});

	// Render the rows with the supplied (render prop) row component
	const Rows = rows.map((row) => (
		<DataTableRow
			row={row}
			columns={columns}
			rowComponent={rowComponent}
			totalWidth={totalRowWidth}
			key={row.key}
		/>
	));

	// Render the selectedrows with the supplied (render prop) row component
	const SelectedRows = selectedRows.map((row) => (
		<DataTableRow
			row={row}
			columns={columns}
			rowComponent={selectedRowComponent}
			totalWidth={totalRowWidth}
			key={row.key}
		/>
	));

	// Render the header with the supplied (render prop) header component
	const Header = (
		<DataTableHeaderRow
			columns={columns}
			headerRowComponent={headerComponent}
			totalWidth={totalRowWidth}
		/>
	);

	const isDragging = useDragging();

	return (
		<>
			{scrollElementRef.current && (
				<DragOverScrollSection
					enabled={isDragging}
					scrollRef={scrollElementRef}
				/>
			)}

			<div
				className={classNames('DataTable', className)}
				style={style}
				ref={scrollElementRef}
			>
				{Header}
				<div
					className={`DataTableContent ${
						useDragging() ? 'DataTableContent__isDragging' : ''
					}`}
				>
					{SelectedRows}
					{selectedRows.length > 0 ? (
						<SelectedDivider width={totalRowWidth}>
							<SelectedDividerButton onClick={onClearSelection}>
								Clear selection
							</SelectedDividerButton>
						</SelectedDivider>
					) : (
						<></>
					)}
					{Rows}
				</div>
			</div>
		</>
	);
};
