import { FC, PropsWithChildren, ReactNode, useRef } from 'react';
import { motion, MotionProps, Variants } from 'framer-motion';
import { useMeasure } from '../../hooks/useMeasure';

const ContainerComponent: FC<PropsWithChildren<MotionProps>> = ({ children, ...props }) => (
	<motion.div style={{ overflow: 'hidden' }} {...props}>
		{children}
	</motion.div>
);

type Props = {
	isVisible: boolean;
	ease?: string;
	duration?: number;
	className?: string;
	variants: Variants;
	children: ReactNode | ((ref: React.MutableRefObject<any>) => ReactNode) | undefined;
};

export const AnimateHeight: FC<Props> = ({
	duration,
	ease,
	variants,
	isVisible,
	children,
	...other
}) => {
	const ref = useRef(null);
	const bounds = useMeasure(ref);

	return (
		<ContainerComponent
			initial={isVisible ? 'open' : 'collapsed'}
			animate={isVisible ? 'open' : 'collapsed'}
			inherit={false}
			variants={variants}
			transition={{
				ease,
				duration:
					typeof duration === 'number'
						? duration
						: getAutoHeightDuration(bounds.height) / 1000,
			}}
			{...other}
		>
			{typeof children === 'function' ? (
				children(ref)
			) : (
				<div ref={ref}>{children}</div>
			)}
		</ContainerComponent>
	);
};

/**
 * Get the duration of the animation depending upon
 * the height provided.
 * @param {number} height of container
 */

function getAutoHeightDuration(height: number): number {
	if (!height) return 0;
	const constant = height / 36;
	return Math.round((4 + 15 * constant ** 0.25 + constant / 5) * 10);
}
