import { ChartDataSetData } from '../../../SharedTypes/API/Dashboard';
import { ColorLookup } from '../../utils/ColorLookup';
import { getDate, getMonth, getYear } from '../../utils/Dates';

export const calculateChartColor = (
	entry: ChartDataSetData | null,
	order: 'asc' | 'desc'
): string => {
	if (!entry) {
		return ColorLookup.forGraph['green'];
	}

	let color = ColorLookup.forGraph['green'];
	if (order === 'desc') {
		if (entry.thresholds[0] && entry.y && entry.y <= entry.thresholds[0]) {
			color = ColorLookup.forGraph['yellow'];
		}

		if (entry.thresholds[1] && entry.y && entry.y <= entry.thresholds[1]) {
			color = ColorLookup.forGraph['orange'];
		}

		if (entry.thresholds[2] && entry.y && entry.y <= entry.thresholds[2]) {
			color = ColorLookup.forGraph['red'];
		}

		return color;
	}

	if (entry.thresholds[0] && entry.y && entry.y >= entry.thresholds[0]) {
		color = ColorLookup.forGraph['yellow'];
	}

	if (entry.thresholds[1] && entry.y && entry.y >= entry.thresholds[1]) {
		color = ColorLookup.forGraph['orange'];
	}

	if (entry.thresholds[2] && entry.y && entry.y >= entry.thresholds[2]) {
		color = ColorLookup.forGraph['red'];
	}

	return color;
};

type GetChartDownloadName = {
	dateRange?: [Date, Date];
	name: string;
};

export const getChartDownloadName = ({
	dateRange,
	name,
}: GetChartDownloadName): string => {
	const formattedDates = dateRange?.map(
		(date) => `${getDate(date)}-${getMonth(date)}-${getYear(date)}`
	) ?? ['no-date-range'];

	const formattedName = name.includes('|>') ? name.split('|>')[1] : name;

	return `${formattedDates.join(' - ')} - ${formattedName}`;
};

export const roundValueToNearestIncrement = (
	value: number,
	increment: number = 0.05
): number => {
	return Math.round(value / increment) * increment;
};
