import { ReactNode, useState } from 'react';

import './Configurator.scss';
import { useQueryParam } from '../../hooks/useQueryParam';

type Props = {
	children: ReactNode;
};

export const Configurator = ({ children }: Props) => {
	const [width, setWidth] = useState(50);

	function updateSize() {
		window.addEventListener('mousemove', handleMouseMove);
		window.addEventListener('mouseup', () => {
			window.removeEventListener('mousemove', handleMouseMove);
		});
	}

	function handleMouseMove(event: MouseEvent) {
		const widthPx = window.innerWidth - event.clientX;
		const widthVw = (widthPx / window.innerWidth) * 100 + 1;
		setWidth(widthVw);
	}

	const [fullscreen] = useQueryParam('fullscreen');

	const isFullscreen = fullscreen === 'true';

	const containerStyle = {
		width: isFullscreen ? '100%' : `${width}vw`,
		maxWidth: isFullscreen ? 'none' : '70vw',
		minWidth: isFullscreen ? 'none' : '500px',
	};

	return (
		<div className="Configurator" style={containerStyle}>
			<SizeDragButton onMouseDown={() => updateSize()} />
			{children}
		</div>
	);
};

interface SizeDragButtonProps {
	onMouseDown: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

export const SizeDragButton = ({ onMouseDown }: SizeDragButtonProps) => {
	return (
		<div className="SizeDragContainer" onMouseDown={onMouseDown}>
			<div className="SizeDragContainer__Button">
				<div className="SizeDragContainer__Button__Shape" />
				<div className="SizeDragContainer__Button__Shape" />
			</div>
		</div>
	);
};
