import classNames from 'classnames';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useGetElementMetricsQuery } from '../../Core/Api';
import { Icon } from '../../Core/components/Icon/Icon';
import { LoadingBlock } from '../../Core/components/LoadingBlock/LoadingBlock';
import { last } from '../../Core/utils/Array';
import {
	getDatesFromDataSet,
	isSelectedDatePresent,
} from '../../Core/utils/Chart';
import { ColorLookup } from '../../Core/utils/ColorLookup';
import {
	ChartDataSet,
	ChartDataSetData,
	SensorLocation,
} from '../../SharedTypes/API/Dashboard';
import { setSensorData } from '../Explorer/Explorer.slice';
import {
	setSelectedDate,
	setZoomRange,
	useDateRange,
	useSelectedDate,
	useZoomRange,
} from './Dashboard.slice';
import { DashboardCard } from './DashboardCard';
import { calculateStatusForTesting } from './DashboardDataGenerator';

type Props = {
	isFullscreen: boolean;
	handleSelectMetric: (id: string, title: string) => void;
};

export const DashboardMetric: FC<Props> = ({
	isFullscreen,
	handleSelectMetric,
}) => {
	const zoomRange = useZoomRange();
	const dispatch = useDispatch();
	const dateRange = useDateRange();
	const selectedDate = useSelectedDate();

	const datesValid = useMemo(() => {
		if (dateRange && dateRange[0] && dateRange[1]) {
			return true;
		}
		return false;
	}, [dateRange]);

	const params = useParams<{ '*': string; siteId: string }>();
	const paramsArray =
		params['*'] && params['*'].includes('/')
			? params['*'].split('/')
			: params['*']
			? [params['*']]
			: [];

	const { data, isFetching } = useGetElementMetricsQuery(
		{
			siteId: params.siteId!,
			keys: paramsArray,
			start: dateRange![0]!.getTime(),
			stop: dateRange![1]!.getTime(),
			maxDataPoints: 30,
		},
		{
			skip: !datesValid,
		}
	);

	useEffect(() => {
		return () => {
			dispatch(setZoomRange(null));
		};
		// There must be a better way to implement something like "onUnmount" right...
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		/** Effectivly ignore this effect if we have zoomRange (which means the user has zoomed the charts) */
		if (zoomRange) {
			return;
		}

		if (!data) {
			// If we have no data, we can't do anything
			return;
		}

		// If it is not a line chart, we can't do our tricks with it
		if (data[0][0].chartType !== 'line') {
			return;
		}

		// Inspect the data, to get the stop time
		if (
			!selectedDate ||
			(dateRange?.[1] && selectedDate > dateRange[1].getTime()) ||
			(dateRange?.[1] &&
				!isSelectedDatePresent(selectedDate, getDatesFromDataSet(data[0][0])))
		) {
			// Set the selected date to be last point in data fetched from the server
			const lastDate = data[0][0].data.at(-1)?.x;
			if (lastDate) dispatch(setSelectedDate(lastDate));
		}
	}, [data, selectedDate, dateRange, dispatch, zoomRange]);

	const [openCards, setOpenCards] = useState<string[]>([]);

	/** A side effect that will ensure the first card is open upon loading the page (And data is loaded) */
	useEffect(() => {
		if (openCards.length > 0) {
			return;
		}

		if (data && data.length > 0) {
			setOpenCards([data[0][0].id]);
		}
	}, [data, openCards]);

	const handleSelectCard = (type: ChartDataSet['type']) => (cardId: string) => {
		if (type === 'navigation') {
			const cardInfo = data?.find((card) => card[0].id === cardId);

			if (!cardInfo) {
				// Do nothing...
				return;
			}

			handleSelectMetric(cardId, cardInfo[0].name);
		}

		if (openCards?.includes(cardId)) {
			setOpenCards((o) => o?.filter((text) => text !== cardId));
		} else {
			setOpenCards((o) => [...o, cardId]);
		}
	};

	// Finds the thresholds for the given date (or default to the last threshold set)
	const getThresholdsOnDate = useCallback(
		(dataSet: ChartDataSetData[]) => {
			if (!selectedDate || selectedDate > (dateRange?.[1]?.getTime() ?? 0)) {
				return last(dataSet)?.thresholds ?? [];
			}

			const dateAsNumber = new Date(selectedDate).getTime();

			return (
				dataSet.find((entry) => entry.x === dateAsNumber)?.thresholds ?? []
			);
		},
		[dateRange, selectedDate]
	);

	// Finds the value on a given date (defaults to last value)
	const getValueOnDate = useCallback(
		(dataSet: ChartDataSetData[]) => {
			if (!selectedDate || selectedDate > (dateRange?.[1]?.getTime() ?? 0)) {
				return last(dataSet)?.y ?? 0;
			}

			const dateAsNumber = new Date(selectedDate).getTime();

			return dataSet.find((entry) => entry.x === dateAsNumber)?.y ?? 0;
		},
		[dateRange, selectedDate]
	);

	// Returns the "color" of the chart based on the values on a given day
	const getStatusOnDate = useCallback(
		(set: ChartDataSet) => {
			if (!selectedDate || selectedDate > (dateRange?.[1]?.getTime() ?? 0)) {
				return calculateStatusForTesting(
					last(set.chartType === 'scatter' ? set.data[0] : set.data)
						?.thresholds ?? [],
					last(set.chartType === 'scatter' ? set.data[0] : set.data)?.y ?? 0,
					set.thresholdOrder
				);
			}

			return calculateStatusForTesting(
				getThresholdsOnDate(
					set.chartType === 'scatter' ? set.data[0] : set.data
				),
				getValueOnDate(set.chartType === 'scatter' ? set.data[0] : set.data),
				set.thresholdOrder
			);
		},
		[dateRange, getThresholdsOnDate, getValueOnDate, selectedDate]
	);

	useEffect(() => {
		if (!data) {
			return;
		}

		const sensors: SensorLocation[] = data[0].map((d) => {
			const status = getStatusOnDate(d);
			// We need to map the status to a color somehow...
			const colorOptions = {
				default: ColorLookup['forGraph'].green,
				alert: ColorLookup['forGraph'].yellow,
				warning: ColorLookup['forGraph'].orange,
				critical: ColorLookup['forGraph'].red,
			};

			const renderOrder = {
				default: 0,
				alert: 1,
				warning: 2,
				critical: 3,
			};

			return {
				color: colorOptions[status],
				coordinates: d.sensorCoordinates,
				metricIdentifier: d.id,
				sensorLabel: d.shortName,
				renderOrder: renderOrder[status],
			};
		});

		dispatch(setSensorData(sensors));

		return () => {
			dispatch(setSensorData([]));
		};
	}, [getStatusOnDate, data, dispatch]);

	return (
		<div
			className={classNames(['Dashboard__Metric'], {
				'Dashboard__Metric--Fullscreen': isFullscreen,
			})}
		>
			{data &&
				dateRange &&
				data.map((set, index) => (
					<DashboardCard
						key={set[0].id}
						id={set[0].id}
						dateRange={dateRange}
						selectCard={handleSelectCard(set[0].type)}
						value={
							getValueOnDate(
								set[0].chartType === 'scatter' ? set[0].data[0] : set[0].data
							) ?? 0
						}
						icon={set[0].icon && <Icon name={set[0].icon} width={29} />}
						title={set[0].shortName}
						chartDataSets={set}
						dateClicked={(date) =>
							dispatch(setSelectedDate(new Date(date).getTime()))
						}
						selectedDate={selectedDate}
						size={isFullscreen && index === 0 ? 'fullscreen' : 'extended'}
						expanded={
							isFullscreen ||
							openCards.includes(set[0].id) ||
							set[0].type === 'navigation'
						}
						expandControls={
							!isFullscreen && set[0].type === 'endpoint' && !set[0].isParent
						}
						interactable={!isFullscreen}
					/>
				))}
			{isFetching && (
				<div className="Dashboard__Loading">
					<LoadingBlock iconSize={200} />
				</div>
			)}
		</div>
	);
};
