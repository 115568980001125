import classNames from 'classnames';
import { FC, useMemo } from 'react';
import { useGetLatestPointTimeQuery } from '../../Api';
import './RealtimeIndicator.scss';

type Props = {
	pulsating: boolean;
	siteId: string;
};

export const RealtimeIndicator: FC<Props> = ({ siteId, pulsating }) => {
	const { data } = useGetLatestPointTimeQuery({
		siteId,
	});

	const isEndDateInRange = useMemo(() => {
		if (!data) {
			return false;
		}

		// Reduced today by two days
		const compareDate = new Date().getTime() - 1000 * 3600 * 24 * 2;

		if (data > compareDate) {
			return true;
		}

		return false;
	}, [data]);

	return (
		<div className="RealtimeIndicator">
			<div
				className={classNames(['RealtimeIndicator__Dot'], {
					'RealtimeIndicator__Dot--Pulsating': pulsating,
					'RealtimeIndicator__Dot--Offline': !isEndDateInRange,
				})}
			></div>
			<div className="RealtimeIndicator__Title">
				{isEndDateInRange ? 'Real-time' : 'Offline'}
			</div>
		</div>
	);
};
