import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../../../../Core/redux/store';
import { useAppSelector } from '../../../../../Core/redux/useAppSelector';

const initialState = {
	cubeHover: false,
	isCubePressed: false,
	sectionActive: true,
	defaultCameraPosition: [230, 80, 190],
	resetView: false,
};

export const viewcube = createSlice({
	name: 'viewcube',
	initialState: initialState,
	reducers: {
		setCubeHover: (state, { payload }: PayloadAction<boolean>) => {
			state.cubeHover = payload;
		},
		setIsCubePressed: (state, { payload }: PayloadAction<boolean>) => {
			state.isCubePressed = payload;
		},
		setSectionActive: (state, { payload }: PayloadAction<boolean>) => {
			state.sectionActive = payload;
		},
		setDefaultCameraPosition: (state, { payload }: PayloadAction<number[]>) => {
			state.defaultCameraPosition = payload;
		},
		setResetView: (state, { payload }: PayloadAction<boolean>) => {
			state.resetView = payload;
		},
	},
});

export const {
	setCubeHover,
	setIsCubePressed,
	setSectionActive,
	setResetView,
} = viewcube.actions;

export const useCubeHover = (): boolean =>
	useAppSelector((state: RootState) => state.viewcube.cubeHover);
export const useIsCubePressed = (): boolean =>
	useAppSelector((state: RootState) => state.viewcube.isCubePressed);
export const useSectionActive = (): boolean =>
	useAppSelector((state: RootState) => state.viewcube.sectionActive);
export const useDefaultCameraPosition = (): number[] =>
	useAppSelector((state: RootState) => state.viewcube.defaultCameraPosition);
export const useResetView = (): boolean =>
	useAppSelector((state: RootState) => state.viewcube.resetView);
