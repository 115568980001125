import { useEffect, useState } from 'react';
import { Icon } from '../../../../../Core/components/Icon/Icon';
import { useAppDispatch } from '../../../../../Core/redux/useAppDispatch';
import { useAppSelector } from '../../../../../Core/redux/useAppSelector';
import { setCubeHover, useCubeHover, setResetView } from '../state/state';
import './ResetView.scss';
import { ViewCubeSizeOptions } from '../../../ExplorerSettings/Explorer.Settings.slice';

const useResetViewPosition = () => {
	const viewCubeSize = useAppSelector(
		(state) => state.explorerSettings.viewCubeSize
	);
	const [resetViewPosition, setResetViewPosition] = useState({
		top: 15,
		right: 220,
	});

	useEffect(() => {
		const positions: Record<
			ViewCubeSizeOptions,
			{ top: number; right: number }
		> = {
			small: { top: 15, right: 160 },
			regular: { top: 15, right: 220 },
			large: { top: 15, right: 280 },
			veryLarge: { top: 15, right: 340 },
		};

		setResetViewPosition(positions[viewCubeSize.label as ViewCubeSizeOptions]);
	}, [viewCubeSize]);

	return resetViewPosition;
};

export const ResetView = () => {
	// Used to handle hide and show of the reset view button in relation to the ViewCube state
	const cubeHover = useCubeHover();
	const dispatch = useAppDispatch();
	const resetViewPosition = useResetViewPosition();

	const style = {
		top: `${resetViewPosition.top}px`,
		right: `${resetViewPosition.right}px`,
	};

	const handleClick = () => {
		dispatch(setResetView(true));
	};

	return (
		<div
			style={style}
			className={`ResetViewContainer ${cubeHover ? 'show' : 'hide'}`}
			onClick={handleClick}
			onMouseEnter={() => dispatch(setCubeHover(true))}
			onMouseLeave={() => dispatch(setCubeHover(false))}
		>
			<Icon name="ResetView" width={16} />
		</div>
	);
};
