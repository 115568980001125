import { useMemo } from 'react';
import { useAppDispatch } from '../../Core/redux/useAppDispatch';
import {
	ComponentSelectionId,
	deserializeComponentSelectionId,
	serializeComponentSelectionId,
	setComponentHighlight,
	stopComponentHighlight,
	toggleComponentSelection,
} from './Explorer.slice';
import {
	selectElementSortedDimension,
	selectHighlightedComponent,
	selectJointSortedDimension,
	selectSelectedComponents,
} from './Explorer.selectors';
import { useAppSelector } from '../../Core/redux/useAppSelector';
import { ModelJointFactory } from './models/ModelJoint.factory';
import { ModelElementFactory } from './models/ModelElement.factory';
import { useSectionResult } from '../UseSectionResult';
import {
	ComponentId,
	SectionResultElement,
} from '../../SharedTypes/API/Explorer';
import { ModelJointSubResultFactory } from './models/ModelJointSubResult.factory';
import { ModelExplorerProps } from './objects/ModelExplorer';
import { StructureTypeOptions } from './ExplorerSettings/Explorer.Settings.slice';
import { ModelElement } from './models/ModelElement.entity';

export const useAnalysisExplorerData = ({
	sectionId,
}: {
	sectionId: string;
}):
	| { modelData: ModelExplorerProps; seaLevel: number; soilLevel: number }
	| 'loading'
	| 'error' => {
	const dispatch = useAppDispatch();

	const { sectionResult, state: sectionResultState } = useSectionResult({
		sectionId,
	});

	const elementSortedDimension = useAppSelector(selectElementSortedDimension);
	const jointSortedDimension = useAppSelector(selectJointSortedDimension);

	const selectedComponents = useAppSelector(selectSelectedComponents);
	const highlightedComponent = useAppSelector(selectHighlightedComponent);

	// const showLoading = useMemo(
	// 	() => isLoading || isFetching,
	// 	[isLoading, isFetching]
	// );

	const joints = useMemo(() => {
		const joints = sectionResult?.joints.results;

		if (typeof joints === 'undefined') {
			return [];
		}

		// Get the parent results
		const parents: SectionResultElement[] = joints
			// Map to a simpler SectionResultElement
			.map(
				([id, name, type, coordinates, dimensionResults]) =>
					[
						id,
						name,
						type,
						coordinates,
						dimensionResults,
						undefined,
					] as SectionResultElement
			);

		// Map to ModelJoints
		const out = parents.map((sectionResultElement) =>
			ModelJointFactory.fromSectionResult({
				sectionResultElement,
				selectedDimension: jointSortedDimension,
			})
		);

		return out;
	}, [sectionResult, jointSortedDimension]);

	const jointSubResults = useMemo(() => {
		const joints = sectionResult?.joints.results;

		if (typeof joints === 'undefined') {
			return [];
		}

		// Get the subResults
		const parents: SectionResultElement[] = joints
			// Map to a simpler SectionResultElement
			.flatMap((parent) => {
				const children: SectionResultElement[] = parent[7];

				return children ? children : [];
			});

		// Map to ModelJointSubResultss
		const out = parents.map((sectionResultElement) =>
			ModelJointSubResultFactory.fromSectionResult({
				sectionResultElement,
				selectedDimension: jointSortedDimension,
			})
		);

		return out;
	}, [sectionResult, jointSortedDimension]);

	const structureType: StructureTypeOptions = useAppSelector(
		(state) => state.explorerSettings.structureType.label
	);

	const elements = useMemo(() => {
		const elements = sectionResult?.elements.results;

		if (typeof elements === 'undefined') {
			return [];
		}

		// Get the parent elements
		// Filter away any parents with children, since
		// they should be visualized as children only
		let parents: SectionResultElement[];

		if (structureType === 'realistic') {
			parents = elements
				// Map to a simpler SectionResultElement
				.map(([id, name, type, coordinates, dimensionResults, unitVector]) => {
					return [
						id,
						name,
						type,
						coordinates,
						dimensionResults,
						unitVector,
					] as SectionResultElement;
				});
		} else {
			parents = elements
				// Map to a simpler SectionResultElement
				.map(
					([id, name, type, coordinates, dimensionResults, unitVector]) =>
						[
							id,
							name,
							type,
							coordinates,
							dimensionResults,
							unitVector,
						] as SectionResultElement
				);
		}

		// Get any children elements in the result
		const children: SectionResultElement[] = elements.flatMap((parent) => {
			// Extract the unit vector from the parent
			const unitVector = parent[5] as
				| [x: number, y: number, z: number]
				| undefined;

			// Check if parent[7] is an array and update each child, else return an empty array
			return Array.isArray(parent[7])
				? parent[7].map((child) => {
						// Clone the child and update its fifth element with the parent's unit vector
						const updatedChild = [...child] as SectionResultElement;
						updatedChild[2] = parent[2];
						updatedChild[5] = unitVector;
						return updatedChild;
				  })
				: [];
		});

		// Add children elements alongside the parents
		const allElements = parents.concat(children);

		// Used for lookup of child results
		const mappedSectionResultElementResults = new Map(
			sectionResult?.elements.results.map((el) => [el[0], el])
		);

		// The default color for beams should be none when there are no results and neutral when there are any results
		const defaultColor = [elementSortedDimension, jointSortedDimension].some(
			(v) => v !== null
		)
			? 'neutral'
			: 'none';

		// Map to ModelElements
		const out = allElements.map((sectionResultElement, index) => {
			const sectionChildResults: any = sectionResult?.elements.results[index];
			let childResults: SectionResultElement[] = [];

			if (sectionChildResults) {
				childResults = sectionChildResults[7];
			} else {
				// The section results for highlighting need child data to show. So to satisfy for children they point on their own geometric data.
				mappedSectionResultElementResults
					.get(
						(sectionResultElement[0].split('-')[0] + '-0') as ComponentId
					)?.[7]
					.forEach((child) => {
						if (Object.keys(child[4]).length > 0) {
							childResults.push(child);
						}
					});
			}

			return ModelElementFactory.fromSectionResult({
				sectionResultElement,
				childResults,
				selectedDimension: elementSortedDimension,
				defaultColor,
				structureType,
			});
		});

		// Filter away any undefined elements
		return out.filter(
			(element): element is ModelElement => element !== undefined
		);
	}, [
		sectionResult?.elements.results,
		structureType,
		elementSortedDimension,
		jointSortedDimension,
	]);

	const seaLevel = useMemo(
		() => sectionResult?.sea.seaLevel ?? 0,
		[sectionResult]
	);

	const soilLevel = useMemo(
		() => sectionResult?.sea.soilLevel ?? -55,
		[sectionResult]
	);

	function onComponentClick(componentSelectionId: ComponentSelectionId) {
		// Logic to select the parent element when a child is clicked
		const { componentId, componentType } =
			deserializeComponentSelectionId(componentSelectionId);

		const componentSelectionIdToToggle = (() => {
			// If the type is an element, we check if it has a parent
			if (componentType === 'element') {
				// Get the inferred parent
				const inferredParentId = ModelElementFactory.inferParentId(componentId);

				// Serialize as the parent id if defined, otherwise return the original id
				return inferredParentId === null
					? componentSelectionId
					: serializeComponentSelectionId({
							componentType,
							componentId: inferredParentId,
					  });
			}

			// If it is a joint we do nothing
			return componentSelectionId;
		})();

		console.log(
			'Component selected',
			componentSelectionId,
			componentSelectionIdToToggle
		);
		dispatch(
			toggleComponentSelection({
				componentSelectionId: componentSelectionIdToToggle,
			})
		);
	}

	function onComponentHover(componentSelectionId: ComponentSelectionId | null) {
		dispatch(
			componentSelectionId === null
				? stopComponentHighlight({})
				: setComponentHighlight({
						componentSelectionId: componentSelectionId,
				  })
		);
	}

	if (sectionResultState === 'loading') {
		return 'loading';
	}

	if (sectionResultState === 'error') {
		return 'error';
	}

	const modelData: ModelExplorerProps = {
		joints,
		jointSubResults,
		elements,
		onComponentClick,
		onComponentHover,
		highlightedComponent,
		selectedComponents,
	};

	return {
		modelData,
		seaLevel,
		soilLevel,
	};
};
