import React from 'react';
import {
	useGetElementDimensionsQuery,
	useGetJointDimensionsQuery,
} from '../../Core/Api';

import { AnalysisExplorer } from './AnalysisExplorer';
import { useAppDispatch } from '../../Core/redux/useAppDispatch';
import {
	clearComponentSelection,
	closeDimensionChooser,
	ComponentSelectionId,
	foldInElement,
	foldInJoint,
	foldOutElement,
	foldOutJoint,
	openDimensionChooser,
	removeElementDimension,
	removeJointDimension,
	setComponentHighlight,
	setElementSort,
	setJointSort,
	stopComponentHighlight,
	toggleComponentSelection,
	updateChosenDimensions,
} from '../Explorer/Explorer.slice';
import { useAppSelector } from '../../Core/redux/useAppSelector';
import {
	selectDimensionChooserState,
	selectElementDimensionConfiguration,
	selectJointDimensionConfiguration,
	selectSelectedComponents,
} from '../Explorer/Explorer.selectors';
import { DimensionChooser } from './DimensionChooser';
import { never } from '../../Core/utils/Function';
import {
	DimensionCategory,
	DimensionConfigurationDimension,
} from '../../SharedTypes/API/Explorer';
import { isUndefined } from '../../Core/utils/Logic';
import { useSectionResult } from '../UseSectionResult';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useQueryParam } from '../../Core/hooks/useQueryParam';

type UseElementDimensionCategories = (args: { siteId: string }) => {
	elementDimensionCategories: DimensionCategory[];
	state: 'loading' | 'error' | 'success';
};
const useElementDimensionCategories: UseElementDimensionCategories = ({
	siteId,
}) => {
	const { isError, isFetching, isLoading, data } = useGetElementDimensionsQuery(
		{
			siteId,
		}
	);

	if (isError) {
		return { elementDimensionCategories: [], state: 'error' };
	}

	if (isFetching || isLoading || isUndefined(data)) {
		return { elementDimensionCategories: [], state: 'loading' };
	}

	return {
		elementDimensionCategories: data.dimensionCategories,
		state: 'success',
	};
};

type UseJointDimensionCategories = (args: { siteId: string }) => {
	jointDimensionCategories: DimensionCategory[];
	state: 'loading' | 'error' | 'success';
};
const useJointDimensionCategories: UseJointDimensionCategories = ({
	siteId,
}) => {
	const { isError, isFetching, isLoading, data } = useGetJointDimensionsQuery({
		siteId,
	});

	if (isError) {
		return { jointDimensionCategories: [], state: 'error' };
	}

	if (isFetching || isLoading || isUndefined(data)) {
		return { jointDimensionCategories: [], state: 'loading' };
	}

	return {
		jointDimensionCategories: data.dimensionCategories,
		state: 'success',
	};
};

const useDimensionSelectorState = () => {
	const dimensionsSelector = useAppSelector(selectDimensionChooserState);

	let dimensionSelectorState: 'elements' | 'joints' | null;

	switch (dimensionsSelector) {
		case 'showElement':
			dimensionSelectorState = 'elements';
			break;

		case 'showJoint':
			dimensionSelectorState = 'joints';
			break;
		case 'hidden':
			dimensionSelectorState = null;
			break;
		default:
			never(dimensionsSelector);
			dimensionSelectorState = null;
	}

	return { dimensionSelectorState };
};

export const AnalysisExplorerContainer = () => {
	const [fullscreen, setFullscreen] = useQueryParam('fullscreen');
	const isFullscreen = fullscreen === 'true';

	const handleToggleFullscreen = () => {
		setFullscreen(isFullscreen ? 'false' : 'true');
	};
	const navigate = useNavigate();
	const { sectionId = '', siteId = '' } = useParams<{
		sectionId: string;
		siteId: string;
	}>();

	const location = useLocation();

	const { sectionResult, state: fetchState } = useSectionResult({
		sectionId,
	});
	const { dimensionSelectorState } = useDimensionSelectorState();
	const elementDimensionConfig = useAppSelector(
		selectElementDimensionConfiguration
	);
	const jointDimensionConfig = useAppSelector(
		selectJointDimensionConfiguration
	);

	const selectedComponents = useAppSelector(selectSelectedComponents);

	const dispatch = useAppDispatch();

	function handleClose() {
		// Due to an error with the navigate(-1) trick due to using query params, we have to do some location black magic
		const locationWithoutAnalysis = location.pathname.replace('/analysis', '');
		navigate(locationWithoutAnalysis);
	}

	function handleOpenDimensionChooser(args: { type: 'element' | 'joint' }) {
		console.log('handleOpenDimensionChooser');
		dispatch(openDimensionChooser({ type: args.type }));
	}

	function handleCloseDimensionChooser() {
		console.log('handleCloseDimensionChooser');

		dispatch(closeDimensionChooser());
	}

	function handleUpdateElementDimensions(
		dimensions: DimensionConfigurationDimension[]
	) {
		dispatch(updateChosenDimensions({ dimensions, type: 'elements' }));
		dispatch(closeDimensionChooser());
	}

	function handleUpdateJointDimensions(
		dimensions: DimensionConfigurationDimension[]
	) {
		dispatch(updateChosenDimensions({ dimensions, type: 'joints' }));
		dispatch(closeDimensionChooser());
	}

	function handleRemoveElementDimension(dimensionId: string) {
		dispatch(removeElementDimension({ dimensionId }));
	}

	function handleRemoveJointDimension(dimensionId: string) {
		dispatch(removeJointDimension({ dimensionId }));
	}

	function handleSortElementDimension(args: {
		dimensionId: string;
		direction: 'desc' | 'asc';
	}) {
		dispatch(
			setElementSort({
				dimensionId: args.dimensionId,
				direction: args.direction,
			})
		);
	}

	function handleSortJointDimension(args: {
		dimensionId: string;
		direction: 'desc' | 'asc';
	}) {
		dispatch(
			setJointSort({
				dimensionId: args.dimensionId,
				direction: args.direction,
			})
		);
	}

	function handleToggleElementFoldOut(args: {
		componentId: string;
		newState: 'open' | 'closed';
	}) {
		args.newState === 'open'
			? dispatch(foldOutElement({ componentId: args.componentId }))
			: dispatch(foldInElement({ componentId: args.componentId }));
	}

	function handleToggleJointFoldOut(args: {
		componentId: string;
		newState: 'open' | 'closed';
	}) {
		args.newState === 'open'
			? dispatch(foldOutJoint({ componentId: args.componentId }))
			: dispatch(foldInJoint({ componentId: args.componentId }));
	}

	function handleTogglePanelSize() {
		handleToggleFullscreen();
	}

	function handleComponentSelectionToggle(
		componentSelectionId: ComponentSelectionId
	) {
		dispatch(toggleComponentSelection({ componentSelectionId }));
	}

	const handleComponentHighlight =
		(action: 'start' | 'stop') =>
		(componentSelectionId: ComponentSelectionId) => {
			switch (action) {
				case 'start':
					dispatch(setComponentHighlight({ componentSelectionId }));
					return;
				case 'stop':
					dispatch(stopComponentHighlight({ componentSelectionId }));
					return;
			}
		};

	function handleClearSelection() {
		dispatch(clearComponentSelection());
	}

	// if (isError) {
	// 	return <div>Loading error...</div>;
	// }

	// if (isFetching || isLoading) {
	// 	return <div>Loading...</div>;
	// }

	return (
		<>
			{dimensionSelectorState === 'elements' ? (
				<ElementDimensionChooser
					siteId={siteId}
					onCancel={handleCloseDimensionChooser}
					onSave={handleUpdateElementDimensions}
				/>
			) : null}
			{dimensionSelectorState === 'joints' ? (
				<JointDimensionChooser
					siteId={siteId}
					onCancel={handleCloseDimensionChooser}
					onSave={handleUpdateJointDimensions}
				/>
			) : null}
			<AnalysisExplorer
				elementDimensionConfig={elementDimensionConfig}
				jointDimensionConfig={jointDimensionConfig}
				fetchState={fetchState}
				onClose={handleClose}
				onToggleSize={handleTogglePanelSize}
				onOpenDimensionChooser={handleOpenDimensionChooser}
				selectedComponents={selectedComponents}
				sectionResult={sectionResult}
				onSortElementDimension={handleSortElementDimension}
				onSortJointDimension={handleSortJointDimension}
				onRemoveElementDimension={handleRemoveElementDimension}
				onRemoveJointDimension={handleRemoveJointDimension}
				onToggleElementFoldOut={handleToggleElementFoldOut}
				onToggleJointFoldOut={handleToggleJointFoldOut}
				onToggleComponentSelection={handleComponentSelectionToggle}
				onStartComponentHighlight={handleComponentHighlight('start')}
				onStopComponentHighlight={handleComponentHighlight('stop')}
				onClearSelection={handleClearSelection}
			/>
		</>
	);
};

// We derive the handlers from DimensionChooser so we don't have to maintain them in several places
interface DimensionChooserProps {
	siteId: string;
	onCancel: Parameters<typeof DimensionChooser>[0]['onCancel'];
	onSave: Parameters<typeof DimensionChooser>[0]['onSave'];
}

const ElementDimensionChooser = ({
	siteId,
	onCancel,
	onSave,
}: DimensionChooserProps) => {
	const { elementDimensionCategories, state } = useElementDimensionCategories({
		siteId,
	});
	const dimensionConfiguration = useAppSelector(
		selectElementDimensionConfiguration
	);

	return (
		<>
			<DimensionChooser
				type="elements"
				dimensionCategories={elementDimensionCategories}
				chosenDimensions={dimensionConfiguration.dimensions}
				onCancel={onCancel}
				onSave={onSave}
				state={state}
			/>
		</>
	);
};

const JointDimensionChooser = ({
	onCancel,
	onSave,
	siteId,
}: DimensionChooserProps) => {
	const { jointDimensionCategories, state } = useJointDimensionCategories({
		siteId,
	});
	const dimensionConfiguration = useAppSelector(
		selectJointDimensionConfiguration
	);

	return (
		<>
			<DimensionChooser
				type="joints"
				dimensionCategories={jointDimensionCategories}
				chosenDimensions={dimensionConfiguration.dimensions}
				onCancel={onCancel}
				onSave={onSave}
				state={state}
			/>
		</>
	);
};
