import { ColorLookup } from '../../../Core/utils/ColorLookup';
import {
	BraceGeometry,
	ComponentId,
	DimensionId,
	Result,
	ResultColor,
	SectionResultElement,
} from '../../../SharedTypes/API/Explorer';
import {
	ModelJointSubResult,
	ModelJointSubResultColor,
	ModelJointSubResultType,
} from './ModelJointSubResult.entity';

const fromSectionResult = (() => {
	const createJointSubResultType = (): ModelJointSubResultType =>
		'jointSubResult' as ModelJointSubResultType;
	const createJointSubResultColor = (input: string): ModelJointSubResultColor =>
		input as ModelJointSubResultColor;

	return (args: {
		sectionResultElement: SectionResultElement;
		selectedDimension: DimensionId | null;
	}): ModelJointSubResult => {
		const id: ComponentId = args.sectionResultElement[0];
		const coordinates: [x: number, y: number, z: number][] =
			args.sectionResultElement[3];
		const dimensionResults: {
			[dimensionId: string]: Result;
		} = args.sectionResultElement[4];

		let color: ResultColor = args.selectedDimension
			? dimensionResults?.[args.selectedDimension]?.[2] ?? 'neutral'
			: 'none';

		const brace: BraceGeometry = args
			.sectionResultElement[5] as unknown as BraceGeometry;

		const hexColor = ColorLookup.forJoint[color];

		return [
			id,
			createJointSubResultType(),
			createJointSubResultColor(hexColor),
			...coordinates,
			brace,
		] as ModelJointSubResult;
	};
})();

const inferParentId = (id: ComponentId): ComponentId | null => {
	const [jointId] = id.split('#');

	return jointId as ComponentId;
};

export const ModelJointSubResultFactory = {
	fromSectionResult,
	inferParentId,
};
