import { ReactNode, useRef } from 'react';
import { useOnClickOutside } from 'usehooks-ts';

import './Modal.scss';

type Props = {
	children: ReactNode;
	title: string;
	footerContent: ReactNode;
	width?: string;
	onClickOutside?: () => void;
};

export const Modal = ({
	children,
	title,
	footerContent,
	width = '600px',
	onClickOutside,
}: Props) => {
	const style = {
		width,
	};

	// Handles onClickOutside event - Intended to be used to close modal and run a callback if needed
	const modalOverlayRef = useRef<HTMLDivElement | null>(null);

	useOnClickOutside(modalOverlayRef, () => {
		const popMenuItem = document.querySelector('.PopMenu__Item');

		if (popMenuItem) {
			return;
		}

		if (onClickOutside) {
			onClickOutside();
		}
	});

	return (
		<>
			<div className="ModalOverlay">
				<div className="Modal" ref={modalOverlayRef} style={style}>
					<div className="Modal__Header">
						<div
							className="Modal__Title"
							dangerouslySetInnerHTML={{ __html: title }}
						></div>
					</div>
					<div className="Modal__Body">{children}</div>
					<div className="Modal__Footer">{footerContent}</div>
				</div>
			</div>
		</>
	);
};
