import { Vector3 } from 'three';
import { useCallback } from 'react';
import { ThreeEvent } from '@react-three/fiber';
import {
	setCubeHover,
	setIsCubePressed,
	useIsCubePressed,
	useSectionActive,
} from '../state/state';
import { useAppDispatch } from '../../../../../Core/redux/useAppDispatch';

/**
 * Handles the functions of the cube and the compass.
 * This includes the hover and click events.
 * @param camera The camera object.
 * @param controls The controls object.
 * @param isCubePressed A boolean that is used to check if the cube is pressed.
 * @param setIsCubePressed A function that sets the isCubePressed state.
 * @param setCubeHover A function that sets the cubeHover state.
 * @param targetCameraPosition A ref that holds the target position of the camera.
 * @param sectionActive A ref that holds the active state of the section.
 * @returns An object with the functions.
 *
 */

type CameraType = {
	position: Vector3;
};

type ControlsType = {
	target: Vector3;
};

type HandleCubeClickFunction = (
	position: [number, number, number],
	e?: ThreeEvent<MouseEvent>
) => void;

type HandlePointerEnterHoverAreaFunction = (e?: ThreeEvent<MouseEvent>) => void;

type HandlePointerLeaveHoverAreaFunction = () => void;

type HandlePointerMoveFunction = (
	e: ThreeEvent<MouseEvent>,
	setSectionHover: React.Dispatch<React.SetStateAction<boolean>>
) => void;

type HandlePointerOutFunction = (
	e: ThreeEvent<MouseEvent>,
	setSectionHover: React.Dispatch<React.SetStateAction<boolean>>
) => void;

export const useViewCubeFunctions = (
	camera: CameraType,
	controls: ControlsType | null,
	targetCameraPosition: React.MutableRefObject<Vector3 | undefined>
): {
	handleCubeClick: HandleCubeClickFunction;
	handlePointerEnterHoverArea: HandlePointerEnterHoverAreaFunction;
	handlePointerLeaveHoverArea: HandlePointerLeaveHoverAreaFunction;
	handlePointerMove: HandlePointerMoveFunction;
	handlePointerOut: HandlePointerOutFunction;
} => {
	const dispatch = useAppDispatch();
	const sectionActive = useSectionActive();
	const isCubePressed = useIsCubePressed();

	// This is the function that is called when a plane or edge/corner is pressed.
	const handleCubeClick: HandleCubeClickFunction = useCallback(
		(position: [number, number, number], e?: ThreeEvent<MouseEvent>) => {
			if (!controls) return;

			// Ensures the CubeHover to stay Active. - Will bug out if not.
			dispatch(setCubeHover(true));

			e?.stopPropagation();
			// Takes the distance to origo into account.
			const [x, y, z] = position;
			const distanceToPivotPoint = camera.position.distanceTo(controls.target);

			targetCameraPosition.current = new Vector3(x, y, z);

			// This sets the distance to origo for the target position so the camera doesn't end up too close to the pivot point.
			const targetDistance = targetCameraPosition.current
				.clone()
				.normalize()
				.multiplyScalar(distanceToPivotPoint);

			// Sets the target position to the target distance.
			targetCameraPosition.current = targetDistance.clone();

			// Adds the pivot point to the target position so the camera will rotate around the pivot point.
			targetCameraPosition.current.add(controls.target);

			// Lets the useFrame code run. See first useFrame hook
			if (!isCubePressed) {
				dispatch(setIsCubePressed(true));
			}
		},
		[controls, dispatch, camera.position, targetCameraPosition, isCubePressed]
	);

	// This is the function that is called when the hover plane is hovered.
	const handlePointerEnterHoverArea: HandlePointerEnterHoverAreaFunction =
		useCallback(
			(e?: ThreeEvent<MouseEvent>) => {
				// Stops the event from bubbling up to the parent.
				e && e.stopPropagation();
				// Checks if the section is active. If not, it will not run. Specifically used to avoid state interaction when there is a mousedown event.
				if (sectionActive) {
					dispatch(setCubeHover(true));
				}
			},
			[sectionActive, dispatch]
		);

	// This is the function that is called when the hover plane is not hovered.
	const handlePointerLeaveHoverArea: HandlePointerLeaveHoverAreaFunction =
		useCallback(() => {
			if (sectionActive) {
				dispatch(setCubeHover(false));
			}
		}, [sectionActive, dispatch]);

	// This is function that activates the individual sections of the viewcube and compas causing a rerender and a new texture animation.
	const handlePointerMove: HandlePointerMoveFunction = useCallback(
		(
			e: ThreeEvent<MouseEvent>,
			setSectionHover: React.Dispatch<React.SetStateAction<boolean>>
		) => {
			e.stopPropagation();

			if (sectionActive) {
				setSectionHover(true);
			}
		},
		[sectionActive]
	);

	// Same as above, but vice versa.
	const handlePointerOut: HandlePointerOutFunction = useCallback(
		(
			e: ThreeEvent<MouseEvent>,
			setSectionHover: React.Dispatch<React.SetStateAction<boolean>>
		) => {
			e.stopPropagation();
			if (sectionActive && setSectionHover) {
				setSectionHover(false);
			}
		},
		[sectionActive]
	);

	return {
		handleCubeClick,
		handlePointerEnterHoverArea,
		handlePointerLeaveHoverArea,
		handlePointerMove,
		handlePointerOut,
	};
};
