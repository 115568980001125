import { configureStore } from '@reduxjs/toolkit';
import { login } from '../../Login/Login.slice';
import { dashboard } from '../../RIAMS/Dashboard/Dashboard.slice';
import { elementDetailsReducer } from '../../RIAMS/ElementDetails/ElementDetailsOverlay/elementDetails.slice';
import { explorer } from '../../RIAMS/Explorer/Explorer.slice';
import { explorerSettings } from '../../RIAMS/Explorer/ExplorerSettings/Explorer.Settings.slice';
import { viewcube } from '../../RIAMS/Explorer/objects/ViewCube/state/state';
import { results } from '../../RIAMS/AnalysisExplorer/ResultsTable/state/ResultsState';
import { api } from '../Api';
import { authorizationMiddleware } from './authorizationMiddleware';

export const store = configureStore({
	reducer: {
		elementDetails: elementDetailsReducer,
		[api.reducerPath]: api.reducer,
		explorer: explorer.reducer,
		explorerSettings: explorerSettings.reducer,
		dashboard: dashboard.reducer,
		login: login.reducer,
		viewcube: viewcube.reducer,
		results: results.reducer,
	},
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			// Disable serializable check since it is slow with the amount of data in Results
			serializableCheck: false,
		})
			// Add authorization middleware (redirects to login if unauthorized)
			.concat(authorizationMiddleware)
			// Add API middleware
			.concat(api.middleware),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
