import { Route, Routes, useNavigate } from 'react-router-dom';
import { Login, LoginPage } from './Login/Login';

import { NoMatch } from './NoMatch';
import { Playground } from './Playground/Playground';
import { Playground2 } from './Playground/Playground2';
import { Profile } from './Profile/Profile';
import { RIAMS } from './RIAMS/RIAMS';
import { SiteSelector } from './SiteSelector/SiteSelector';
import { OnboardingCreatePassword } from './Login/OnboardingCreatePassword';
import { RequireLogin } from './Core/RequireLogin';
import { useGetUserQuery } from './Core/Api';
import { useEffect } from 'react';
import { ForgotPassword } from './Login/ForgotPassword';
import { ForgotPasswordConfirmCode } from './Login/ForgotPasswordConfirmCode';

export function AppRoutes() {
	return (
		<Routes>
			<Route path="/" element={<RedirectManager />} />
			<Route path="/" element={<LoginPage />}>
				<Route path="login" element={<Login />} />
				<Route path="forgotpassword" element={<ForgotPassword />} />
				<Route
					path="forgotpassword/confirm"
					element={<ForgotPasswordConfirmCode />}
				/>
			</Route>
			<Route
				path="/onboarding/create-password"
				element={<OnboardingCreatePassword />}
			/>
			<Route
				path="/riams/*"
				element={
					<RequireLogin>
						<RIAMS />
					</RequireLogin>
				}
			/>
			<Route
				path="/sites/*"
				element={
					<RequireLogin>
						<SiteSelector />
					</RequireLogin>
				}
			/>
			<Route
				path="/profile/*"
				element={
					<RequireLogin>
						<Profile />
					</RequireLogin>
				}
			/>
			{/* Only show playgrounds in dev */}
			{process.env.NODE_ENV === 'development' && (
				<>
					<Route path="/playground" element={<Playground />} />
					<Route path="/playground2" element={<Playground2 />} />
				</>
			)}
			<Route path="*" element={<NoMatch />} />
		</Routes>
	);
}

const RedirectManager = () => {
	const navigate = useNavigate();
	const { data, isLoading } = useGetUserQuery({});

	useEffect(() => {
		if (isLoading) {
			return;
		}

		if (!data?.isLoggedIn) {
			navigate('/login');
			return;
		}

		if (data?.isLoggedIn && data?.user?.group === 'Superadmin') {
			navigate('/riams/admin');
		} else {
			navigate('/sites?redirectToSingles=true');
		}
	}, [data, isLoading, navigate]);

	return null;
};
