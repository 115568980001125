import classNames from 'classnames';
import { FC, useEffect, useMemo, useRef, useState } from 'react';
import {
	Control,
	ControllerFieldState,
	ControllerRenderProps,
	Path,
	useController,
} from 'react-hook-form';
import { FormTheme } from '../../types/FormTheme';
import { ColorLookup } from '../../utils/ColorLookup';
import { noop } from '../../utils/Function';
import { Icon } from '../Icon/Icon';
import { PopMenu, PopMenuItems, PopMenuState } from '../PopMenu/PopMenu';
import { ToggleButton } from '../ToggleButton/ToggleButton';
import './FormInput.scss';

export type InputType = 'text' | 'toggle' | 'select' | 'number';

type FormInputProps<FormValues extends Record<string, unknown>> =
	JSX.IntrinsicElements['input'] & {
		label: string;
		theme: FormTheme;
		control: Control<FormValues>;
		name: Path<FormValues>;
		inputType?: InputType;
		options?: PopMenuItems;

		blurEvent?: (fieldValue: string) => void;
	};

export const FormInput = <T extends Record<string, unknown>>({
	theme,
	label,
	control,
	name,
	type,
	inputType = 'text',
	options,
	blurEvent = noop,
	disabled = false,
	...props
}: FormInputProps<T>) => {
	// Note the use of ts-ignore here - There is something fishy with react-hook-form
	// That makes it very hard to type thing properly, even with use of generics
	// @ts-ignore
	const { field, fieldState } = useController({ control, name });
	const [focus, setFocus] = useState(false);

	// Find the component we want to render besides the label, and pass it the props it needs
	// This has to be done like this, since field and fieldState is not available in the parent component
	// And cloneElement with props is a hassle with Typescript
	const formInputType = useMemo(() => {
		switch (inputType) {
			case 'text':
				return (
					<FormInputText
						disabled={disabled}
						type={type}
						theme={theme}
						// @ts-ignore
						field={field}
						fieldState={fieldState}
						setFocus={setFocus}
						customBlurEvent={blurEvent}
					/>
				);

			case 'toggle':
				// @ts-ignore
				return <ToggleButton theme={theme} field={field} />;

			case 'select':
				return (
					<FormInputSelect
						disabled={disabled}
						// @ts-ignore
						field={field}
						options={options ?? []}
						theme={theme}
						setFocus={setFocus}
					/>
				);
			case 'number':
				return (
					<FormInputText
						disabled={disabled}
						type={type}
						theme={theme}
						// @ts-ignore
						field={field}
						fieldState={fieldState}
						setFocus={setFocus}
						customBlurEvent={blurEvent}
						allowNumbersOnly={true}
					/>
				);
		}
	}, [inputType, disabled, type, theme, field, fieldState, blurEvent, options]);

	return (
		<div
			className={classNames(['FormInput', `FormInput--${theme}`])}
			{...props}
		>
			<label
				className={classNames(
					[
						'FormInput__Label',
						`FormInput__Label--${theme}`,
						field.value || focus ? `FormInput__Label--${theme}--dirty` : '',
					],
					{
						'FormInput__Label--Disabled': disabled,
					}
				)}
				htmlFor={field.name}
				style={{
					color: fieldState.error ? ColorLookup.forGraph['red'] : undefined,
				}}
			>
				{label}
			</label>

			<div className="FormInput__Input">{formInputType}</div>
			{/* Error messages are only implemented for the Login theme at this time */}
			{theme === 'Login' && (
				<div
					className={classNames([
						'FormInput__Error',
						`FormInput__Error--${theme}`,
					])}
					style={{ color: ColorLookup.forGraph['red'] }}
				>
					{fieldState.error && <p>{fieldState.error.message}</p>}
				</div>
			)}
		</div>
	);
};

type FormInputTextProps = {
	theme: FormTheme;
	field: ControllerRenderProps<any, Path<any>>;
	fieldState: ControllerFieldState;
	setFocus: (value: boolean) => void;
	customBlurEvent: (fieldValue: string) => void;
	allowNumbersOnly?: boolean;
} & JSX.IntrinsicElements['input'];

const FormInputText: FC<FormInputTextProps> = ({
	type,
	theme,
	field,
	fieldState,
	setFocus,
	customBlurEvent,
	disabled,
	allowNumbersOnly = false,
}) => {
	const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (allowNumbersOnly && /[^-\d.]/.test(event.target.value)) {
			event.target.value = event.target.value.replace(/[^-\d.]/g, '');
		}

		// Ensure that there's at most one '-' at the start and one '.' in the middle
		const value = event.target.value;
		if (allowNumbersOnly) {
			const parts = value.split('.');
			if (parts.length > 2 || (parts.length === 2 && parts[1].includes('-'))) {
				event.target.value = value.slice(0, -1); // Remove last character if invalid
			} else if (value.indexOf('-') > 0) {
				event.target.value = value.replace(/-/g, ''); // Remove all '-' except the first character
			}
		}

		field.onChange(event);
	};

	return (
		<input
			{...field}
			type={type}
			className={classNames(['FormInputText', `FormInputText--${theme}`])}
			style={{
				borderColor: fieldState.error ? ColorLookup.forGraph['red'] : undefined,
			}}
			onChange={handleInputChange}
			onBlur={() => {
				setFocus(false);
				customBlurEvent(field.value);
				field.onBlur();
			}}
			onFocus={() => setFocus(true)}
			disabled={disabled}
			// Thanks to our Chrome overlords for this beauty
			// https://stackoverflow.com/questions/15738259/disabling-chrome-autofill
			autoComplete={
				field.name === 'username'
					? 'nope'
					: field.name === 'password'
					? 'new-password'
					: undefined
			}
		/>
	);
};

type FormInputSelectProps = {
	theme: FormTheme | 'Dark';
	field: ControllerRenderProps<any, string>;
	options: PopMenuItems;
	setFocus: (value: boolean) => void;
} & Omit<JSX.IntrinsicElements['input'], 'theme'>;

const FormInputSelect: FC<FormInputSelectProps> = ({
	theme,
	field,
	options,
	setFocus,
	disabled,
}) => {
	// Used by PopMenu component
	const menuRef = useRef<HTMLDivElement>(null);
	const [isMenuOpen, setIsMenuOpen] = useState(false);

	/** Emits a signal up the tree, to let the label know to move up and out of the way */
	useEffect(() => {
		setFocus(isMenuOpen);
	}, [isMenuOpen, setFocus]);

	return (
		<PopMenu
			isEnabled={!disabled}
			theme={theme}
			items={options}
			placement="bottom-start"
			onChangeState={(state: PopMenuState) => setIsMenuOpen(state === 'open')}
			onSelectItem={(value) => field.onChange(value)}
			elementRef={menuRef}
			className={classNames(
				['FormInputSelect__Select', `FormInputSelect__Select--${theme}`],
				{
					isOpen: isMenuOpen,
					isDisabled: disabled,
				}
			)}
		>
			<div
				tabIndex={0}
				ref={menuRef}
				className={classNames(['FormInputSelect', `FormInputSelect--${theme}`])}
			>
				<p
					className={classNames([
						'FormInputSelect__Value',
						`FormInputSelect__Value--${theme}`,
					])}
				>
					{field.value
						? options.find((opt) => opt.key === field.value)?.label
						: ''}
				</p>
				<Icon
					className={isMenuOpen ? 'FormInputSelect--Open' : ''}
					name="CaretDown"
					width={12}
				/>
			</div>
		</PopMenu>
	);
};
