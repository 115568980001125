import { ReactNode } from 'react';
import { Matrix4, Object3D, Quaternion, Vector3 } from 'three';
import * as N from '../../../Core/utils/Number';
import { ModelJointSubResult } from '../models/ModelJointSubResult.entity';
import { SelectionMesh } from './SelectionMesh';
import { BraceGeometry, Geometry } from '../../../SharedTypes/API/Explorer';

const diameter = 1.25;
const opacity = 0.9;

const matrixHelper = new Object3D();

interface Props {
	jointSubResults: ModelJointSubResult[];
	segments?: number;
	isHighlighted?: boolean;
}

export const JointSubResultMesh = ({
	jointSubResults,
	segments,
	isHighlighted,
}: Props) => {
	const sortedJointSubResults = sortArrays(jointSubResults);

	return (
		<>
			{sortedJointSubResults.map((group) => (
				<JointSubResultMeshGroup
					key={group.key}
					jointSubResults={group.jointSubResult}
					segments={segments}
					isHighlighted={isHighlighted}
				/>
			))}
		</>
	);
};

const JointSubResultMeshGroup = ({
	jointSubResults,
	segments = 16,
	isHighlighted = false,
}: Props) => {
	const isOpenEnded = !isHighlighted;

	const braceGeometry = jointSubResults[0][5] as BraceGeometry;

	// The size factor is used to scale the brace geometry
	const sizeFactor = 1.1;

	const InstancedGeometry = ({ children }: { children: ReactNode }) => (
		<cylinderGeometry
			args={[
				(braceGeometry[0] / 2) * sizeFactor,
				(braceGeometry[1] / 2) * sizeFactor,
				1,
				segments,
				1,
				isOpenEnded,
			]}
		>
			{children}
		</cylinderGeometry>
	);

	// The function that maps the element to the 3d space
	const placementFn = (element: ModelJointSubResult) => {
		const [, , , from, to] = element;

		// The as number[] here are only included as TS seems to not be able to correctly
		// infer the ...coordinates: number[][] in ModelElement
		const vectorFrom = new Vector3(...(from as number[]));
		const vectorTo = new Vector3(...(to as number[]));
		const totalLength = new Vector3().subVectors(vectorTo, vectorFrom).length();
		const displayLength = N.clamp(0.01)(1)(totalLength / 3);
		const direction = new Vector3()
			.subVectors(vectorTo, vectorFrom)
			.normalize();

		const tempObject2 = matrixHelper.clone();

		tempObject2.applyMatrix4(
			new Matrix4()
				.multiply(
					new Matrix4().makeTranslation(...(from as [number, number, number]))
				)
				.multiply(
					new Matrix4().makeRotationFromQuaternion(
						new Quaternion().setFromUnitVectors(new Vector3(0, 1, 0), direction)
					)
				)
				.multiply(new Matrix4().makeScale(diameter, displayLength, diameter))
				.multiply(new Matrix4().makeTranslation(0, 0.5 - 0.001, 0)) // Push by a small amount to avoid z fighting the underlying pipe element
		);

		tempObject2.updateMatrix();

		return tempObject2.matrix;
	};

	return (
		<SelectionMesh
			category="brace"
			components={jointSubResults}
			InstancedGeometry={InstancedGeometry}
			placementFn={placementFn}
			sides="double"
			opacity={isHighlighted ? 0 : opacity}
			shadows={!isHighlighted}
			isHighlighted={isHighlighted}
		/>
	);
};

function sortArrays(
	modelJointSubResults: ModelJointSubResult[]
): { key: string; jointSubResult: ModelJointSubResult[] }[] {
	const sortedMap = new Map<string, ModelJointSubResult[]>();

	for (const modelJointSubResult of modelJointSubResults) {
		const geometry: Geometry = modelJointSubResult[5];

		// Handle IGeometry
		const bg: BraceGeometry = geometry as BraceGeometry;
		let key: string = `BraceGeometry: startDiameter=${bg[0]}, endDiameter=${bg[1]}`;

		// Add to the map
		if (!sortedMap.has(key)) {
			sortedMap.set(key, []);
		}
		sortedMap.get(key)?.push(modelJointSubResult);
	}

	// Convert the map to an array
	const sortedArray: { key: string; jointSubResult: ModelJointSubResult[] }[] =
		[];
	sortedMap.forEach((value, key) => {
		sortedArray.push({ key, jointSubResult: value });
	});

	return sortedArray;
}
