import { ApexOptions } from 'apexcharts';
import { FC, useMemo } from 'react';
import { renderToString } from 'react-dom/server';
import Chart from 'react-apexcharts';
import { Icon } from '../Icon/Icon';
import './ScatterPlotChart.scss';
import { ScatterDataSet } from '../../../SharedTypes/API/Dashboard';
import { ChartNoData } from './ChartNoData';
import { ColorLookup } from '../../utils/ColorLookup';
import { createArrayWith } from '../../utils/Array';
import { IndicatorDot } from '../IndicatorDot/IndicatorDot';
import {
	useDateRange,
	useSelectedDate,
} from '../../../RIAMS/Dashboard/Dashboard.slice';
import { useParams } from 'react-router-dom';
import { useGetMetricQuery } from '../../Api';
import { ChartWarning } from './ChartWarning';
import {
	getDate,
	getHour,
	getMinutes,
	getMonth,
	getPadded,
	getYear,
} from '../../utils/Dates';
import { getChartDownloadName } from './ChartOptions';
import { AnimatePresence, motion } from 'framer-motion';
import { upperFirst } from '../../utils/String';

type ScatterPlotChartProps = {
	dataSet: ScatterDataSet;
	height?: string;
	dateRange: [Date, Date];
};

export const IntegrityScatterPlotChart: FC<
	Omit<ScatterPlotChartProps, 'dateRange'>
> = ({ dataSet: dataSetFromProps, height = '95%' }) => {
	const selectedDate = useSelectedDate();
	const dateRange = useDateRange();
	const params = useParams();

	const siteId = useMemo(() => {
		return params.siteId!;
	}, [params]);

	/**
	 * Checks if the date range is a single, 24 hours period
	 * Because of the way dates are picked in the dae picker,
	 * the first day in the picker is the the actual date we want, whereas the second date is the day after
	 * Thus the logic here is, that consecutive dates mean a single day
	 */
	const dateRangeIsSingleDay = useMemo(() => {
		const date1 = dateRange?.[0];
		const date2 = dateRange?.[1];

		// No date range. We are basically in an invalid state
		if (!date1 || !date2) {
			return false;
		}

		// Determine if date1 is one day before date2
		const date2OneDayBefore = new Date(date2.getTime());
		date2OneDayBefore.setDate(date2OneDayBefore.getDate() - 1);

		// If date1 is one day before date2, we are in single day mode
		// Use toDateString to only compare dates and not time
		return date1.toDateString() === date2OneDayBefore.toDateString();
	}, [dateRange]);

	/** Checks if we should query data, other than the default dataSet we get via props */
	const shouldFetchData = useMemo(() => {
		// We only fetch new data if we are in "single hour" mode,
		// Which is when the date range is a single day
		if (dateRangeIsSingleDay === false) {
			console.log('ShouldFetchData: No. We are in multi day mode');

			return false;
		}

		if (!selectedDate && !dateRange?.[1]) {
			console.log('ShouldFetchData: No. No selected date or date range end');
			return false;
		}

		if (selectedDate === dateRange?.[1].getTime()) {
			console.log(
				'ShouldFetchData: No. Selected date is the same as the end of the date range'
			);

			return false;
		}

		console.log(
			'ShouldFetchData: Yes. There is both a selected date and range end and they are not the same'
		);

		return true;
	}, [dateRange, dateRangeIsSingleDay, selectedDate]);

	const { data: fetchedDataSet, isFetching: isFetchingDataSet } =
		useGetMetricQuery(
			{
				siteId,
				metricId: dataSetFromProps.id,
				start: selectedDate - 1000 * 60 * 60,
				stop: selectedDate,
			},
			{
				skip: !shouldFetchData,
			}
		);

	const dataSet = useMemo(() => {
		// If the fetch is not needed, we show the data from props
		if (!shouldFetchData) {
			return dataSetFromProps;
		}

		if (!fetchedDataSet || fetchedDataSet[0].chartType !== 'scatter') {
			return null;
		}

		return fetchedDataSet[0];
	}, [shouldFetchData, fetchedDataSet, dataSetFromProps]);

	// The date depicted on the graph
	// This can either be the selected date on the graph
	// Or the end of the date range in the date picker
	const dateDepicted = useMemo(() => {
		if (!dateRange?.[1]) {
			console.log('No date range');
			return '';
		}

		const date = shouldFetchData ? selectedDate : dateRange[1].getTime();

		const day = getPadded(getDate(date));
		const month = getPadded(getMonth(date));
		const year = getYear(date);
		const hour = getPadded(getHour(date));
		const minute = getPadded(getMinutes(date));

		return `${day}/${month}/${year} ${hour}:${minute}`;
	}, [dateRange, selectedDate, shouldFetchData]);

	// Wether or not to show the date warning
	// This should only be shown in multi-date mode,
	// since the selected date does not have any impact on the graph in multi-date mode
	const showDateWarning = useMemo(() => {
		// The warning should only be shown in multi-date mode
		const dateRangeIsMultipleDays = !dateRangeIsSingleDay;

		// We neeed an end date to compare with the selected date
		const isDateRangeEndAvailable = dateRange?.[1] ? true : false;

		// The selected date should be different from the end of the date range
		// ie. the selected date is not the one depicted on thte graph
		// and thus a warning should be shown
		const isDateRangeEndDifferentFromSelectedDate =
			selectedDate !== dateRange?.[1].getTime();

		// All statements should be true for the warning to be shown
		return (
			dateRangeIsMultipleDays &&
			isDateRangeEndAvailable &&
			isDateRangeEndDifferentFromSelectedDate
		);
	}, [dateRangeIsSingleDay, dateRange, selectedDate]);

	return (
		<div className="ScatterPlotChart">
			{!dataSet ||
				dataSet.data.length === 0 ||
				(dateRange === null && <ChartNoData />)}

			{dataSet !== null && dateRange !== null && (
				<>
					<ScatterPlotChart
						dataSet={dataSet}
						height={height}
						dateRange={dateRange}
					/>
					<ChartWarning
						dateString={dateDepicted}
						showDateWarning={showDateWarning}
					/>
				</>
			)}
			<AnimatePresence>
				{isFetchingDataSet && (
					<motion.div
						className="LineChart__LoadingOverlay"
						initial={{ opacity: 0 }}
						animate={{ opacity: 1 }}
						exit={{ opacity: 0 }}
					>
						<Icon name="LoadingSpinner" width={14} />
					</motion.div>
				)}
			</AnimatePresence>
		</div>
	);
};

export const ScatterPlotChart: FC<ScatterPlotChartProps> = ({
	dateRange,
	dataSet,
	height,
}) => {
	const scatterDataSets = dataSet.dataSetNames.filter(
		(set) => set.type === 'data'
	);
	const scatterDataLimitsAmount = dataSet.dataSetNames.filter(
		(set) => set.type === 'limit'
	).length;

	const dashes = createArrayWith(scatterDataLimitsAmount, 10);
	const widths = createArrayWith(scatterDataLimitsAmount, 1);
	const colors = createArrayWith(
		scatterDataLimitsAmount,
		'rgba(255,255,255,0.6)'
	);

	const markerColors = createArrayWith(scatterDataLimitsAmount, 'transparent');

	const dataDashes = createArrayWith(scatterDataSets.length, 0);
	const dataWidths = createArrayWith(scatterDataSets.length, 4);
	const allDataColors = [
		ColorLookup.forGraph['blue'],
		ColorLookup.forGraph['blue2'],
	];
	const dataColors = scatterDataSets.map((_set, index) => allDataColors[index]);

	const dataMarkerColors = createArrayWith(scatterDataSets.length, '#fff');

	const legendLabelColors = createArrayWith(
		dataSet.dataSetNames.length,
		'rgba(255,255,255,0.8)'
	);

	const legendLabels = scatterDataSets.map((set) => set.name).map(upperFirst);

	const legendFillColors = dataColors;

	// Create the export download name
	const exportDownloadName = getChartDownloadName({
		dateRange: dateRange,
		name: dataSet.name,
	});

	// Create the options for the chart
	const options: ApexOptions = {
		chart: {
			background: '#003347',
			width: '100%',
			height: '100%',
			animations: {
				enabled: true,
				speed: 150,
				easing: 'easeout',
				animateGradually: {
					enabled: false,
				},
				dynamicAnimation: {
					enabled: true,
					speed: 150,
				},
			},
			zoom: {
				enabled: true,
				type: 'xy',
			},
			toolbar: {
				show: true,
				tools: {
					pan: false,
					zoom: renderToString(<Icon name="ChartSearch" width={16} />),
					download: renderToString(<Icon name="ChartBurgerMenu" width={16} />),
					zoomin: renderToString(<Icon name="ChartIn" width={16} />),
					zoomout: renderToString(<Icon name="ChartOut" width={16} />),
					reset: renderToString(<Icon name="Reset" width={18} />),
				},
				offsetX: 0,
				offsetY: -25,
				export: {
					png: {
						filename: exportDownloadName,
					},
					svg: {
						filename: exportDownloadName,
					},
					csv: {
						filename: exportDownloadName,
					},
				},
			},
			events: {
				beforeZoom: (_event, { xaxis }) => {
					// Ensures we do not zoom out further than the present datapoints
					const first = dataSet.data[0][0].x;
					const last = dataSet.data.at(-1)?.[0].y;

					const calculatedFirst = first < 0 ? first * -1 : first;
					const calulatedLast = last && last < 0 ? last * -1 : last;

					const mainDifference = (calulatedLast ?? 0) - calculatedFirst;
					const zoomDifference = xaxis.max - xaxis.min;

					if (zoomDifference < mainDifference)
						return {
							// dont zoom out any further
							xaxis: {
								min: first,
								max: last,
							},
						};
					else {
						return {
							// keep on zooming
							xaxis: {
								min: xaxis.min,
								max: xaxis.max,
							},
						};
					}
				},
			},
		},
		yaxis: {
			forceNiceScale: true,
			tickAmount: 5,
			labels: {
				style: {
					colors: ['rgba(255,255,255, .7)'],
					fontSize: '11px',
				},
				formatter: (val) =>
					`${val.toFixed(1)} ${dataSet.units ? dataSet.units : ''}`,
				offsetY: 2,
				padding: 10,
			},
		},
		xaxis: {
			type: 'numeric',
			axisTicks: {
				show: false,
			},
			tooltip: {
				enabled: false,
			},
			axisBorder: {
				show: false,
			},
			crosshairs: {
				show: false,
			},
			tickAmount: 4,
			labels: {
				show: true,
				formatter: (val) =>
					`${Number(val).toFixed(1)} ${dataSet.units ? dataSet.units : ''}`,
				style: {
					colors: 'rgba(255,255,255, .7)',
					fontSize: '11px',
				},
			},
		},
		legend: {
			show: true,
			position: 'bottom',
			horizontalAlign: 'center',
			fontFamily: 'Gotham Rounded A, Gotham Rounded B',
			fontSize: '11px',
			fontWeight: 400,
			height: 30,
			inverseOrder: true,
			customLegendItems: legendLabels,
			labels: {
				colors: legendLabelColors,
				useSeriesColors: false,
			},
			// Make legend markers a rounded line
			markers: {
				width: 24,
				height: 5,
				radius: 5,
				offsetY: 0, // Optical centering
				fillColors: legendFillColors,
			},
			itemMargin: {
				horizontal: 8,
			},
		},
		grid: {
			borderColor: 'rgba(255,255,255,0.25)',
			yaxis: {
				lines: {
					show: true,
				},
			},
			xaxis: {
				lines: {
					show: true,
				},
			},
		},
		markers: {
			size: 0,
			hover: {
				size: 4,
			},
			colors: [...dataMarkerColors, ...markerColors],
			fillOpacity: 1,
			strokeWidth: 0,
			showNullDataPoints: false,
		},
		stroke: {
			colors: [...dataColors, ...colors],
			width: [...dataWidths, ...widths],
			dashArray: [...dataDashes, ...dashes],
		},
		tooltip: {
			custom: function ({ series, seriesIndex, dataPointIndex, w }) {
				// All series have the same x axis values, we can grab them from whereever
				const x = w.globals.seriesX[seriesIndex][dataPointIndex];

				const y = series[seriesIndex][dataPointIndex];

				const colorDot = renderToString(
					<IndicatorDot
						status="default"
						color={dataColors[seriesIndex]}
						size={8}
					/>
				);

				const seriesName = dataSet.dataSetNames[seriesIndex].name;

				const label = `${colorDot} ${seriesName} x: ${Number(x).toFixed(1)}${
					dataSet.units ? dataSet.units : ''
				} - y: ${Number(y).toFixed(1)}${dataSet.units ? dataSet.units : ''}`;

				return `
						<div class="LineChart__Label" data-testid="LineChartTooltip">
							<span style="display: flex; gap: 4px; align-items: center;">${label}</span>
						</div> 
						`;
			},
		},
	};

	return (
		<Chart
			data-testid="scatterPlot"
			type="line"
			series={dataSet.data.map((set) => ({ data: set, type: 'line' }))}
			width="100%"
			height={height}
			options={options}
		/>
	);
};
