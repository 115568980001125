import { useFrame, useThree } from '@react-three/fiber';
import { useMemo, useRef, useState } from 'react';
import { Color, Mesh } from 'three';
import { configuration } from '../../../Core/configuration/configuration';

export function SoilLevel({
	level,
	visible,
}: {
	level: number;
	visible: boolean;
}) {
	const size = 100000;
	const gridSize = 100;

	const { camera } = useThree();

	const [soilOpacity, setSoilOpacity] = useState(0.8);

	const soilRef = useRef<Mesh>(null);

	// Set soil to transparent when the camera approaches the plane
	useFrame(() => {
		if (!soilRef.current) {
			return;
		}

		const visibileOpacity = 0.8;
		const hiddenOpacity = 0;

		const heightAboveSoilToHide = 50;
		const heightAtWhichToHide = level + heightAboveSoilToHide;
		const heightAtWhichToStopHiding = level;

		// Upper limit
		if (camera.position.y > heightAtWhichToHide) {
			return setSoilOpacity(visibileOpacity);
		}

		// Lower limit
		if (camera.position.y < heightAtWhichToStopHiding) {
			return setSoilOpacity(hiddenOpacity);
		}

		// "Animated" part

		// Percentage of the trip
		const totalTravelLength = Math.abs(
			heightAtWhichToHide - heightAtWhichToStopHiding
		);

		// Length traveled at the current time
		const traveledAtCurrentTime = Math.abs(
			camera.position.y - heightAtWhichToHide
		);

		// Length as a percentage of the total travel length
		const percentageToGo = traveledAtCurrentTime / totalTravelLength;

		// Opacity by percentage of the traveled length
		const opacity =
			visibileOpacity - percentageToGo * (visibileOpacity - hiddenOpacity);

		setSoilOpacity(opacity);
	});

	const soilColor = useMemo(
		() => new Color(configuration.explorer.soilColor),
		[]
	);

	const gridColor = useMemo(
		() => new Color(configuration.explorer.gridColor),
		[]
	);

	return (
		<group position={[0, level, 0]} visible={visible}>
			<gridHelper
				args={[size, size / gridSize, gridColor, gridColor]}
				position={[0, 0.1, 0]}
			/>

			<mesh receiveShadow rotation={[Math.PI / -2, 0, 0]} ref={soilRef}>
				<planeGeometry attach="geometry" args={[size, size]} />
				<meshPhongMaterial
					attach="material"
					color={soilColor}
					transparent
					// map={texture}
					opacity={soilOpacity}
				/>
			</mesh>
		</group>
	);
}
