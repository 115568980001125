import classNames from 'classnames';
import { FC, useCallback, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useGetSiteMetricsQuery } from '../../Core/Api';
import { Icon } from '../../Core/components/Icon/Icon';
import { LoadingBlock } from '../../Core/components/LoadingBlock/LoadingBlock';
import { last } from '../../Core/utils/Array';
import { ColorLookup } from '../../Core/utils/ColorLookup';
import {
	ChartDataSet,
	ChartDataSetData,
	SensorLocation,
} from '../../SharedTypes/API/Dashboard';
import { setSensorData } from '../Explorer/Explorer.slice';
import {
	setSelectedDate,
	useDateRange,
	useSelectedDate,
} from './Dashboard.slice';
import { DashboardCard } from './DashboardCard';
import { calculateStatusForTesting } from './DashboardDataGenerator';

type Props = {
	isFullscreen: boolean;
	handleSelectMetric: (id: string, title: string) => void;
};

export const DashboardOverview: FC<Props> = ({
	isFullscreen,
	handleSelectMetric,
}) => {
	const { siteId } = useParams();
	const dispatch = useDispatch();
	const dateRange = useDateRange();
	const selectedDate = useSelectedDate();

	const datesValid = useMemo(() => {
		if (dateRange && dateRange[0] && dateRange[1]) {
			return true;
		}
		return false;
	}, [dateRange]);

	const { data, isFetching } = useGetSiteMetricsQuery(
		{
			siteId: siteId ?? '',
			start: dateRange![0]!.getTime(), // The datesValid mechanism ensured no errors here
			stop: dateRange![1]!.getTime(), // The datesValid mechanism ensured no errors here
			maxDataPoints: 30,
		},
		{
			skip: !datesValid,
		}
	);

	useEffect(() => {
		if (!data) {
			// If we have no data, we can't do anything
			return;
		}

		// If it is not a line chart, we can't do our tricks with it
		if (data[0].chartType !== 'line') {
			return;
		}

		// Inspect the data, to get the last timestamp
		if (
			(!selectedDate ||
				(dateRange?.[1] && selectedDate > new Date(dateRange[1])?.getTime())) &&
			data
		) {
			// Set the selected date to be last point in data
			const lastDate = data[0].data.at(-1)?.x;
			if (lastDate) dispatch(setSelectedDate(lastDate));
		}
	}, [data, selectedDate, dateRange, dispatch]);

	const getThresholdsOnDate = useCallback(
		(dataSet: ChartDataSetData[]) => {
			if (!selectedDate) {
				return last(dataSet)?.thresholds ?? [];
			}

			const dateAsNumber = new Date(selectedDate).getTime();

			return (
				dataSet.find((entry) => entry.x === dateAsNumber)?.thresholds ?? []
			);
		},
		[selectedDate]
	);

	const getValueOnDate = useCallback(
		(dataSet: ChartDataSetData[]) => {
			if (!selectedDate || selectedDate > (dateRange?.[1]?.getTime() ?? 0)) {
				return last(dataSet)?.y ?? 0;
			}

			const dateAsNumber = new Date(selectedDate).getTime();

			return dataSet.find((entry) => entry.x === dateAsNumber)?.y ?? 0;
		},
		[dateRange, selectedDate]
	);

	const getStatusOnDate = useCallback(
		(set: ChartDataSet) => {
			if (!selectedDate || selectedDate > (dateRange?.[1]?.getTime() ?? 0)) {
				const thresholds = last(
					set.chartType === 'scatter' ? set.data[0] : set.data
				)?.thresholds;
				const y = last(set.chartType === 'scatter' ? set.data[0] : set.data)?.y;

				if (thresholds && y) {
					return calculateStatusForTesting(thresholds, y, set.thresholdOrder);
				}

				return 'default';
			}

			return (
				calculateStatusForTesting(
					getThresholdsOnDate(
						set.chartType === 'scatter' ? set.data[0] : set.data
					),
					getValueOnDate(set.chartType === 'scatter' ? set.data[0] : set.data),
					set.thresholdOrder
				) ?? 'default'
			);
		},
		[dateRange, getThresholdsOnDate, getValueOnDate, selectedDate]
	);

	useEffect(() => {
		if (!data) {
			return;
		}

		const sensors: SensorLocation[] = data.map((d) => {
			const status = getStatusOnDate(d);
			// We need to map the status to a color somehow...
			const colorOptions = {
				default: ColorLookup['forGraph'].green,
				alert: ColorLookup['forGraph'].yellow,
				warning: ColorLookup['forGraph'].orange,
				critical: ColorLookup['forGraph'].red,
			};

			const renderOrder = {
				default: 0,
				alert: 1,
				warning: 2,
				critical: 3,
			};

			return {
				color: colorOptions[status],
				coordinates: d.sensorCoordinates,
				metricIdentifier: d.id,
				sensorLabel: d.shortName,
				renderOrder: renderOrder[status],
			};
		});

		dispatch(setSensorData(sensors));

		return () => {
			dispatch(setSensorData([]));
		};
	}, [getStatusOnDate, data, dispatch]);

	return (
		<div
			className={classNames(['Dashboard__Charts Dashboard__Overview'], {
				'Dashboard__Charts--Fullscreen': isFullscreen,
			})}
		>
			{data &&
				dateRange &&
				data.map((set) => (
					<DashboardCard
						key={set.id}
						id={set.id}
						dateRange={dateRange}
						selectCard={handleSelectMetric}
						value={getValueOnDate(
							set.chartType === 'scatter' ? set.data[0] : set.data
						)}
						icon={set.icon && <Icon name={set.icon} width={29} />}
						title={set.shortName}
						chartDataSets={[set]}
						dateClicked={(date) =>
							dispatch(setSelectedDate(new Date(date).getTime()))
						}
						selectedDate={selectedDate}
						size={isFullscreen ? 'extended' : 'default'}
						expanded={true}
						expandControls={false}
						interactable={true}
						isAnalytical={false}
					/>
				))}
			{isFetching && (
				<div className="Dashboard__Loading">
					<LoadingBlock iconSize={200} />
				</div>
			)}
		</div>
	);
};
