import { useEffect, useRef, useState } from 'react';

import './LegendInformation.scss';
import { useSectionResult } from '../../UseSectionResult';
import {
	DimensionDisplayConfig,
	DimensionDisplayConfigBarNumeric,
} from '../../../SharedTypes/API/Explorer';
import { ToolTip } from '../../../Core/components/ToolTip/ToolTip';
import { useAppSelector } from '../../../Core/redux/useAppSelector';
import {
	selectElementSortedDimension,
	selectJointSortedDimension,
} from '../../Explorer/Explorer.selectors';

/**
 * Used for displaying the legend information when the user has selected a dimensions of some sort in
 * the AnalysisExplorer
 *
 */

type LegendThresholdProps = {
	min: string | number;
	greenUntil: string | number;
	yellowUntil: string | number;
	orangeUntil: string | number;
	max: string | number;
};

const LegendThresholds = ({
	min,
	greenUntil,
	yellowUntil,
	orangeUntil,
	max,
}: LegendThresholdProps) => {
	// Used to avoid Tooltip.. because elementRef can't be null | undefined
	const emptyRef = useRef<HTMLDivElement>(null);

	// Used for Tooltips
	const minRef = useRef<HTMLDivElement>(null);
	const greenYellowRef = useRef<HTMLDivElement>(null);
	const yellowOrangeRef = useRef<HTMLDivElement>(null);
	const orangeRedRef = useRef<HTMLDivElement>(null);
	const maxRef = useRef<HTMLDivElement>(null);

	return (
		<>
			<div ref={minRef}>
				<ToolTip
					tip={min.toString()}
					elementRef={parseFloat(min.toString()) >= 1000 ? minRef : emptyRef}
				>
					<span className="LegendInformation__minValue">
						<NumberFormatter num={min} />
					</span>
				</ToolTip>
			</div>
			<div ref={greenYellowRef} className="LegendInformation__spanGreenYellow">
				<ToolTip
					tip={greenUntil.toString()}
					elementRef={
						parseFloat(greenUntil.toString()) >= 1000
							? greenYellowRef
							: emptyRef
					}
				>
					<span>
						<NumberFormatter num={greenUntil} />
					</span>
				</ToolTip>
			</div>
			<div
				ref={yellowOrangeRef}
				className="LegendInformation__spanYellowOrange"
			>
				<ToolTip
					tip={yellowUntil.toString()}
					elementRef={
						parseFloat(yellowUntil.toString()) >= 1000
							? yellowOrangeRef
							: emptyRef
					}
				>
					<span>
						<NumberFormatter num={yellowUntil} />
					</span>
				</ToolTip>
			</div>
			<div ref={orangeRedRef} className="LegendInformation__spanOrangeRed">
				<ToolTip
					tip={orangeUntil.toString()}
					elementRef={
						parseFloat(orangeUntil.toString()) >= 1000 ? orangeRedRef : emptyRef
					}
				>
					<span>
						<NumberFormatter num={orangeUntil} />
					</span>
				</ToolTip>
			</div>
			<div ref={maxRef} className="LegendInformation__maxValue">
				<ToolTip
					tip={max.toString()}
					elementRef={parseFloat(max.toString()) >= 1000 ? maxRef : emptyRef}
				>
					<span>
						<NumberFormatter num={max} />
					</span>
				</ToolTip>
			</div>
		</>
	);
};

// Formats E.g. 1500 to 1,5k and 15000 to 15k etc. etc.
const NumberFormatter = ({ num }: { num: string | number }) => {
	const numericValue = parseFloat(num.toString());
	const formattedNumber =
		numericValue >= 1000
			? `${(Math.round((numericValue * 10) / 1000) / 10)
					.toFixed(numericValue % 1000 !== 0 ? 1 : 0)
					.replace(/\.0$/, '')}k`
			: num;

	return <>{formattedNumber}</>;
};

// Used for displaying the legend information when the user has selected a dimensions of some sort in
const ColorSection = ({
	color,
}: {
	color: 'green' | 'yellow' | 'orange' | 'red';
}) => {
	return <div className={`LegendInformation__${color}`} />;
};

const TitleAndUnit = ({
	title,
	unit,
	longName,
}: {
	title: string;
	unit: string;
	longName: string;
}) => {
	const titleRef = useRef<HTMLDivElement>(null);

	if (title === longName) {
		return <>{` (${title}, ${unit})`}</>;
	}

	const [shortname, subscript] = title.split('_');
	return (
		<div ref={titleRef} className="LegendInformation__titleAndUnit">
			<ToolTip tip={longName} elementRef={titleRef}>
				{` (`}
				{shortname}
				<sub className={'LegendInformation__Subscript'}>{subscript}</sub>
				{`, ${unit})`}
			</ToolTip>
		</div>
	);
};

type LegendInformationProps = {
	sectionId: string;
};

export const LegendInformation = ({ sectionId }: LegendInformationProps) => {
	const { sectionResult, state: fetchState } = useSectionResult({
		sectionId,
	});

	const jointSortedDimension = useAppSelector(selectJointSortedDimension);
	const elementSortedDimension = useAppSelector(selectElementSortedDimension);

	let elementSortedIndex;
	if (
		sectionResult &&
		elementSortedDimension &&
		sectionResult.elements.resultDimensions.some(
			(dimension) => dimension.id === elementSortedDimension
		)
	) {
		elementSortedIndex = sectionResult.elements.resultDimensions.findIndex(
			(dimension) => dimension.id === elementSortedDimension
		);
	}

	let jointSortedIndex;
	if (
		sectionResult &&
		jointSortedDimension &&
		sectionResult.joints.resultDimensions.some(
			(dimension) => dimension.id === jointSortedDimension
		)
	) {
		jointSortedIndex = sectionResult.joints.resultDimensions.findIndex(
			(dimension) => dimension.id === jointSortedDimension
		);
	}

	const isThereData =
		sectionResult &&
		(sectionResult?.elements.resultDimensions.length > 0 ||
			sectionResult?.joints.resultDimensions.length > 0);

	const [elementTitle, elementUnit] = useDimensionState(
		fetchState === 'success'
			? sectionResult?.elements.resultDimensions[
					elementSortedIndex ? elementSortedIndex : 0
			  ]
			: undefined
	);
	const [jointTitle, jointUnit] = useDimensionState(
		fetchState === 'success'
			? sectionResult?.joints.resultDimensions[
					jointSortedIndex ? jointSortedIndex : 0
			  ]
			: undefined
	);

	const displayElementsConfig =
		sectionResult?.elements.resultDimensions[
			elementSortedIndex ? elementSortedIndex : 0
		]?.displayConfig;

	const displayJointsConfig =
		sectionResult?.joints.resultDimensions[
			jointSortedIndex ? jointSortedIndex : 0
		]?.displayConfig;

	const elementLongName: string =
		sectionResult?.elements.resultDimensions[
			elementSortedIndex ? elementSortedIndex : 0
		]?.name ?? '';
	const jointLongName: string =
		sectionResult?.joints.resultDimensions[
			jointSortedIndex ? jointSortedIndex : 0
		]?.name ?? '';

	// In some cases the values are inverted if a large number is seen as a posistive. e.g age of fatigue life.
	// This can simply be checked if the first value in the threshold is red or green.
	const isRedLargerThanGreen = (type: 'joint' | 'element') => {
		if (type === 'joint') {
			return displayJointsConfig?.thresholds[0].color === 'red';
		}
		if (type === 'element') {
			return displayElementsConfig?.thresholds[0].color === 'red';
		}
		return false;
	};

	let {
		min: elementMin = '...',
		max: elementMax = '...',
		thresholds: [
			{ until: elementGreenUntil = '...' },
			{ until: elementYellowUntil = '...' },
			{ until: elementOrangeUntil = '...' },
		] = [{}, {}, {}],
	} = fetchState === 'success' &&
	isDimensionDisplayConfigBarNumeric(displayElementsConfig)
		? displayElementsConfig
		: {};

	if (isRedLargerThanGreen('element')) {
		[elementGreenUntil, elementOrangeUntil, elementMax, elementMin] = [
			elementOrangeUntil,
			elementGreenUntil,
			elementMin,
			elementMax,
		];
	}

	let {
		min: jointMin = '...',
		max: jointMax = '...',
		thresholds: [
			{ until: jointGreenUntil = '...' },
			{ until: jointYellowUntil = '...' },
			{ until: jointOrangeUntil = '...' },
		] = [{}, {}, {}],
	} = fetchState === 'success' &&
	isDimensionDisplayConfigBarNumeric(displayJointsConfig)
		? displayJointsConfig
		: {};

	if (isRedLargerThanGreen('joint')) {
		[jointGreenUntil, jointOrangeUntil, jointMax, jointMin] = [
			jointOrangeUntil,
			jointGreenUntil,
			jointMin,
			jointMax,
		];
	}

	return (
		<div
			className={`LegendInformationContainer ${
				isThereData && fetchState !== 'error' ? 'show' : 'hide'
			}`}
		>
			<div
				className={`LegendInformation__text-top ${
					sectionResult && sectionResult?.elements.resultDimensions.length > 0
						? 'shown'
						: 'hidden'
				}`}
			>
				<LegendThresholds
					min={elementMin}
					greenUntil={elementGreenUntil}
					yellowUntil={elementYellowUntil}
					orangeUntil={elementOrangeUntil}
					max={elementMax}
				/>
			</div>
			<div
				className={`LegendInformation__text-topTitle ${
					sectionResult && sectionResult?.elements.resultDimensions.length > 0
						? 'shown'
						: 'hidden'
				}`}
			>
				<span>Elements</span>
				<span className="LegendInformation__textTitle">
					{' '}
					{elementTitle && elementUnit && (
						<TitleAndUnit
							title={elementTitle}
							unit={elementUnit}
							longName={elementLongName}
						/>
					)}
				</span>
			</div>
			<div className={'LegendInformation'}>
				<ColorSection color={'green'} />
				<ColorSection color={'yellow'} />
				<ColorSection color={'orange'} />
				<ColorSection color={'red'} />
			</div>

			<div
				className={`LegendInformation__text-bottom ${
					sectionResult && sectionResult?.joints.resultDimensions.length > 0
						? 'shown'
						: 'hidden'
				}`}
			>
				<LegendThresholds
					min={jointMin}
					greenUntil={jointGreenUntil}
					yellowUntil={jointYellowUntil}
					orangeUntil={jointOrangeUntil}
					max={jointMax}
				/>
			</div>
			<div
				className={`LegendInformation__text-bottomTitle ${
					sectionResult && sectionResult?.joints.resultDimensions.length > 0
						? 'shown'
						: 'hidden'
				}`}
			>
				<span>Joints</span>
				<span className="LegendInformation__textTitle">
					{' '}
					{jointTitle && jointUnit && (
						<TitleAndUnit
							title={jointTitle}
							unit={jointUnit}
							longName={jointLongName}
						/>
					)}
				</span>
			</div>
		</div>
	);
};

interface Dimension {
	name: string;
	shortName: string;
	unit: string;
}

// Custom hook to handle title and unit state
function useDimensionState(
	defaultValue: Dimension | undefined
): [string, string] {
	const [title, setTitle] = useState<string>('');
	const [unit, setUnit] = useState<string>('');

	useEffect(() => {
		if (defaultValue) {
			if (defaultValue.name.length > 20) {
				// If the name is too long, use the short name instead - The name can look very long, and it can cause CSS issues.
				setTitle(defaultValue.shortName || '');
			} else {
				setTitle(defaultValue.name || '');
			}
			setUnit(defaultValue.unit || '');
		}
	}, [defaultValue]);

	return [title, unit];
}

// Custom type guard to check if the display config is a DimensionDisplayConfigBarNumeric
function isDimensionDisplayConfigBarNumeric(
	config: DimensionDisplayConfig | undefined
): config is DimensionDisplayConfigBarNumeric {
	return (
		config !== undefined &&
		(config as DimensionDisplayConfigBarNumeric).min !== undefined
	);
}
