import {
	Matrix4,
	Object3D,
	Object3DEventMap,
	Quaternion,
	Vector3,
} from 'three';
import {
	ModelElement,
	ModelElementColor,
	ModelElementType,
} from '../../models/ModelElement.entity';
import {
	ComponentId,
	PipeGeometry,
	SubElement,
} from '../../../../SharedTypes/API/Explorer';

// Uses the same logic
export const BoxPlacementFn = (element: ModelElement) => {
	return iBeamPlacementFn(element);
};

/**
 * Calculates and applies the necessary transformations to an I-beam element,
 * placing it correctly in a 3D model based on its start and end points, and a unit vector for orientation.
 *
 * @param {ModelElement} element - An array representing the I-beam element, including its start and end points and unit vector for rotation.
 * @returns {Matrix4} - The transformation matrix that positions and orients the I-beam correctly.
 */
export const iBeamPlacementFn = (element: ModelElement): Matrix4 => {
	// Extract element properties
	const diameter = 0.5; // Diameter of the I-beam, assumed to be constant in this case
	const [, , , unitVector, , from, to] = element;

	// Convert from/to points to Vector3 for easier manipulation
	const vectorFrom = new Vector3(...(from as number[]));
	const vectorTo = new Vector3(...(to as number[]));

	// Calculate length and direction of the beam
	const length = vectorTo.clone().sub(vectorFrom).length();
	const direction = vectorTo.clone().sub(vectorFrom).normalize();

	// Create a temporary Object3D for applying transformations
	const tempObject = new Object3D();

	// Rotate object to align with the x-axis (perpendicular to the default direction)
	tempObject.applyMatrix4(new Matrix4().makeRotationX(Math.PI / 2));

	// Apply translations and rotations to align with the from-to direction, and scale according to length and diameter
	tempObject.applyMatrix4(
		new Matrix4()
			.makeTranslation(...(from as [number, number, number]))
			.multiply(
				new Matrix4().makeRotationFromQuaternion(
					new Quaternion().setFromUnitVectors(new Vector3(0, 1, 0), direction)
				)
			)
			.multiply(new Matrix4().makeScale(diameter, length, diameter))
			.multiply(new Matrix4().makeTranslation(0, 1, 0))
	);

	// Ensure the matrix updates are manual to optimize performance
	tempObject.matrixAutoUpdate = false;

	// Rotate the object to match the provided unit vector orientation
	applyCorrectRotation(tempObject, direction, unitVector, vectorFrom, vectorTo);

	return tempObject.matrix;
};

/**
 * Applies the correct rotation to the tempObject to align its orientation with the unit vector provided.
 *
 * @param {Object3D} tempObject - The temporary object being manipulated.
 * @param {Vector3} direction - The normalized direction vector from start to end point of the beam.
 * @param {number[]} unitVector - Unit vector indicating the desired up direction of the beam.
 * @param {Vector3} vectorFrom - Start point of the beam.
 * @param {Vector3} vectorTo - End point of the beam.
 */
function applyCorrectRotation(
	tempObject: Object3D,
	direction: Vector3,
	unitVector: number[],
	vectorFrom: Vector3,
	vectorTo: Vector3
): void {
	const unitVectorForRotation = new Vector3(...unitVector);

	// Determine the axis for initial orientation
	const upVector =
		direction.y === 0 ? new Vector3(0, 0, 1) : new Vector3(0, 1, 0);

	// Apply initial quaternion rotation to align with the upVector
	const vectorForZUnitOfTempObject = upVector.applyQuaternion(
		tempObject.quaternion
	);

	// Normalize vectors for dot product calculation
	const dot = unitVectorForRotation
		.normalize()
		.dot(vectorForZUnitOfTempObject.normalize());

	// Determine if the rotation needs to be adjusted based on the direction and position
	const shouldRotateCounterClockwise = determineRotationAdjustment(
		direction,
		vectorFrom,
		vectorTo
	);

	// Apply rotation adjustment based on the calculated direction
	tempObject.rotateZ((shouldRotateCounterClockwise ? -1 : 1) * Math.acos(dot));

	tempObject.updateMatrix();
}

/**
 * Determines if the rotation adjustment should be clockwise or counter-clockwise based on direction and position.
 *
 * @param {Vector3} direction - The direction vector from start to end point of the beam.
 * @param {Vector3} vectorFrom - Start point of the beam.
 * @param {Vector3} vectorTo - End point of the beam.
 * @returns {boolean} - Returns true if the rotation should be counter-clockwise, false otherwise.
 */
function determineRotationAdjustment(
	direction: Vector3,
	vectorFrom: Vector3,
	vectorTo: Vector3
): boolean {
	// Logic to determine the rotation adjustment
	if (direction.y === 0) {
		return vectorFrom.x > vectorTo.x;
	} else {
		return (
			(direction.y > 0 && direction.x < 0 && direction.z > 0) ||
			(direction.y < 0 && direction.x < 0 && direction.z > 0) ||
			(direction.y > 0 && direction.x < 0 && direction.z < 0)
		);
	}
}

// The function that maps the element to the 3d space
export const pipePlacementFn = (element: any, scale?: boolean) => {
	const diameter = scale ? getPipeScale(element) : 1;

	const from = element[element.length - 2];
	const to = element[element.length - 1];

	if (!from || !to) {
		console.error('Missing from or to coordinates');
		return new Matrix4();
	}

	// The as number[] here are only included as TS seems to not be able to correctly
	// inter the ...coordinates: number[][] in ModelElement
	const vectorFrom = new Vector3(...(from as number[]));
	const vectorTo = new Vector3(...(to as number[]));
	const length = new Vector3().subVectors(vectorTo, vectorFrom).length();
	const direction = new Vector3().subVectors(vectorTo, vectorFrom).normalize();

	const tempObject2 = new Object3D().clone();

	applyMatrixTransformation(
		tempObject2,
		from,
		direction,
		diameter,
		length,
		[0, 0.5, 0]
	);

	tempObject2.matrixAutoUpdate = false;

	tempObject2.updateMatrix();

	return tempObject2.matrix;
};

const getPipeScale = (modelElement: ModelElement): number => {
	const firstComponentId: ComponentId = modelElement[0];

	// If it ends with -0 it is the parent element, so the pipe itself.
	if (typeof firstComponentId === 'string' && firstComponentId.endsWith('-0')) {
		const subElementsArray: SubElement[] = modelElement[4] as SubElement[];

		if (!subElementsArray) return 1;

		const firstSubElement: SubElement = subElementsArray[0];
		const pipeGeometry: PipeGeometry = firstSubElement[2] as PipeGeometry;

		const startDiameter: number = pipeGeometry[0];

		return startDiameter / 2;
	}

	// Else we use the child elements to create the pipe
	else {
		const subElement: SubElement = modelElement[4][0] as SubElement;
		const childPipeGeometry: PipeGeometry = subElement[2] as PipeGeometry;
		// It doesn't matter what diameter is used here. They are the same in this instance.
		const startDiameterOfChild: number = childPipeGeometry[0];
		return startDiameterOfChild / 2;
	}
};

// This function is used to apply the matrix transformation to the object
function applyMatrixTransformation(
	tempObject: Object3D<Object3DEventMap>,
	from:
		| ComponentId
		| ModelElementType
		| ModelElementColor
		| [x: number, y: number, z: number]
		| SubElement[],
	direction: Vector3,
	diameter: number,
	length: number,
	translation: [x: number, y: number, z: number]
) {
	tempObject.applyMatrix4(
		new Matrix4()
			.multiply(
				new Matrix4().makeTranslation(...(from as [number, number, number]))
			)
			.multiply(
				new Matrix4().makeRotationFromQuaternion(
					new Quaternion().setFromUnitVectors(new Vector3(0, 1, 0), direction)
				)
			)
			.multiply(new Matrix4().makeScale(diameter, length, diameter))
			.multiply(new Matrix4().makeTranslation(...translation))
	);
}
