import { ReactNode } from 'react';
import { ModelElement } from '../models/ModelElement.entity';
import { SelectionMesh } from './SelectionMesh';
import { pipePlacementFn } from './utils/PlacementFn';

// Align square cylinders (4 segments) with a horizontal top part
const cylinderAngle = Math.PI * 0.25;

interface Props {
	pipes: ModelElement[];
	pipeSegments?: number;
	pipeWidth?: number;
	isHighlighted?: boolean;
}
export const PipeMesh = ({
	pipes,
	pipeSegments = 6,
	pipeWidth = 0.5,
	isHighlighted,
}: Props) => {
	const InstancedGeometry = ({ children }: { children: ReactNode }) => (
		<cylinderGeometry
			args={[pipeWidth, pipeWidth, 1, pipeSegments, 1, false, cylinderAngle]}
			attach="geometry"
		>
			{children}
		</cylinderGeometry>
	);

	return (
		<SelectionMesh
			category="element"
			components={pipes}
			InstancedGeometry={InstancedGeometry}
			placementFn={pipePlacementFn}
			isHighlighted={isHighlighted}
		/>
	);
};
