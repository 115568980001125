import { ReactNode } from 'react';
import { Matrix4 } from 'three';
import { ModelJoint, ModelJointCoordinate } from '../models/ModelJoint.entity';
import { SelectionMesh } from './SelectionMesh';
import { useAppSelector } from '../../../Core/redux/useAppSelector';

interface Props {
	joints: ModelJoint[];
	detail?: number;
	isHighlighted?: boolean;
}

export const JointMesh = ({ joints, detail = 3, isHighlighted }: Props) => {
	const radius = useAppSelector((state) => state.explorerSettings.jointSize);

	const InstancedGeometry = ({ children }: { children: ReactNode }) => (
		<icosahedronGeometry args={[radius, detail]}>
			{children}
		</icosahedronGeometry>
	);

	const placementFn = (element: ModelJoint) => {
		// Extract with explicit types to be sure we descructure the tuple correctly
		const x: ModelJointCoordinate = element[3];
		const y: ModelJointCoordinate = element[4];
		const z: ModelJointCoordinate = element[5];

		return new Matrix4().makeTranslation(x, y, z);
	};

	return (
		<SelectionMesh
			category="joint"
			components={joints}
			InstancedGeometry={InstancedGeometry}
			placementFn={placementFn}
			isHighlighted={isHighlighted}
		/>
	);
};
