import { ColorLookup } from '../../../Core/utils/ColorLookup';
import {
	ComponentId,
	DimensionId,
	ElementDTO,
	ElementType,
	Result,
	ResultColor,
	SectionResultElement,
	SubElement,
} from '../../../SharedTypes/API/Explorer';
import {
	ModelElement,
	ModelElementColor,
	ModelElementType,
} from './ModelElement.entity';

const createElementColor = (input: string): ModelElementColor =>
	input as ModelElementColor;

const createElementType = (type: ElementType): ModelElementType => {
	switch (type) {
		case 'Box':
			return 'box' as ModelElementType;
		case 'Plate':
			return 'plate' as ModelElementType;
		case 'I':
			return 'I' as ModelElementType;
		default:
			return 'pipe' as ModelElementType;
	}
};

const fromElementDTO = (args: { color?: string } = {}) => {
	const color = args.color ?? ColorLookup.forElement['none'];

	return (element: ElementDTO): ModelElement => {
		const id: ComponentId = element[0];
		const type: ElementType = element[2] as ElementType;
		const coordinates: [x: number, y: number, z: number][] = element[3];
		const unitVector: [x: number, y: number, z: number] = element[4];
		const subElements: SubElement[] = element[5];

		return [
			id,
			createElementType(type),
			createElementColor(color),
			unitVector,
			subElements,
			...coordinates,
		] as ModelElement;
	};
};

const fromSectionResult = (args: {
	sectionResultElement: SectionResultElement;
	childResults: SectionResultElement[];
	selectedDimension: DimensionId | null;
	defaultColor: 'neutral' | 'none';
	structureType: 'realistic' | 'simplified';
}): ModelElement | void => {
	const id: ComponentId = args.sectionResultElement[0];
	const type: ElementType = args.sectionResultElement[2];
	const coordinates: [x: number, y: number, z: number][] =
		args.sectionResultElement[3];
	const dimensionResults: {
		[dimensionId: string]: Result;
	} = args.sectionResultElement[4];
	const unitVector: [x: number, y: number, z: number] | undefined =
		args.sectionResultElement[5];

	// We get the color for the selected dimension or a fallback default color
	// The default is passed in as an argument to differentiate between yellow (none) when no results are chosen
	// and white (neutral) when either a joint or element dimension is visualized. IE the model should be white whenever there is any result
	let color: ResultColor = args.selectedDimension
		? dimensionResults?.[args.selectedDimension]?.[2] ?? 'neutral'
		: args.defaultColor;

	const hexColor = ColorLookup.forElement[color];

	// If the structure is simplified and the element is not a parent, we don't want to create a model element.
	// This is to avoid having multiples of the same element in the model with wrong colored data.
	if (
		args.structureType === 'simplified' &&
		!id.endsWith('0') &&
		hexColor === ColorLookup.forElement['neutral']
	) {
		return; // Exits the function without creating a ModelElement.
	}

	// Contains the child elements of the parents to feed diameter information to the model.
	const subElements: SubElement[] = args.childResults
		// In case of a child having to point to itself (for child highlight) we want to make sure it everything but that child is filtered out
		.filter((childResult: any[]) => {
			const sectionEndsWithZero = args.sectionResultElement[0].endsWith('-0');
			const childEndsWithSameNumber =
				childResult[0].split('-').pop() ===
				args.sectionResultElement[0].split('-').pop();

			return sectionEndsWithZero || childEndsWithSameNumber;
		})
		.map((childResult: any[]) => {
			const id: ComponentId = childResult[0];
			const coordinates: [x: number, y: number, z: number][] = childResult[3];
			const geometry = childResult[5];

			return [id, coordinates, geometry] as SubElement;
		});

	return [
		id,
		createElementType(type),
		createElementColor(hexColor),
		unitVector,
		subElements,
		...coordinates,
	] as ModelElement;
};

const inferParentId = (id: ComponentId): ComponentId | null => {
	const [parentNumber, childNumber] = id.split('-');

	// Parents have no parent
	if (childNumber === '0') {
		return null;
	}

	// Set the parent id as parentNumber-0 as per the business logic
	return `${parentNumber}-0` as ComponentId;
};

export const ModelElementFactory = {
	fromElementDTO,
	fromSectionResult,
	inferParentId,
};
