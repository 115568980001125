/**
    This function performs smooth scrolling animation on an element.
    @param {HTMLElement} element - The element to be scrolled.
    @param {number} target - The target scroll position.
    @param {number} frames - The amount of total frames in one animation cycle - Controls the speed.
    @returns {void}
    */

import { RefObject, useEffect, useMemo, useState } from 'react';

export const smoothHorizontalScroll = (
	element: HTMLElement,
	target: number,
	frames: number
) => {
	let start = element.scrollLeft,
		change = target - start,
		currentFrame = 0,
		increment = 20;

	const animateScroll = function () {
		currentFrame += increment;
		const val = easeInOutQuad(currentFrame, start, change, frames);
		element.scrollLeft = val;
		if (currentFrame < frames) {
			window.requestAnimationFrame(animateScroll);
		}
	};
	animateScroll();
};

/**
    https://easings.net/

    This function calculates the value of easing using the ease-in-out quadratic equation.
    @param {number} t - The current time or progress value.
    @param {number} b - The starting value.
    @param {number} c - The change in value or the total change.
    @param {number} d - The total duration or time span.
    @returns {number} - The calculated easing value at the given time.
    */

const easeInOutQuad = function (t: number, b: number, c: number, d: number) {
	t /= d / 2;
	if (t < 1) return (c / 2) * t * t + b;
	t--;
	return (-c / 2) * (t * (t - 2) - 1) + b;
};

/**
 * `useViewportObserver` is a custom React Hook that observes whether the referenced HTMLDivElement is in the viewport.
 * It leverages the IntersectionObserver API to watch for changes in the intersection of a target element with
 * an ancestor element or with a top-level document's viewport.
 *
 * @export
 * @function
 * @name ViewportObserver
 * @param {RefObject<HTMLDivElement>} ref - A React ref object targeting an HTMLDivElement.
 *
 * @returns {boolean} - Returns a boolean value indicating whether the observed element is in the viewport (true if it is, false otherwise).
 *
 * @example
 * const isElementInViewPort: isInViewPort = useViewportObserver(ref);
 * if (isElementInViewPort) {
 *   // Execute logic when the element is in the viewport
 * }
 *
 * @see {@link https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API | Intersection Observer API - Mozilla Developer} for more info.
 * @see {@link https://bobbyhadz.com/blog/react-check-if-element-in-viewport | Check if an Element is in the Viewport in React.js } for more info.
 *
 */

export type isInViewport = boolean;

export function useViewportObserver(
	ref: RefObject<HTMLDivElement>
): isInViewport {
	const [isInViewPort, setIsInViewPort] = useState<boolean>(false);

	const observer = useMemo(() => {
		return new IntersectionObserver(([entry]) => {
			setIsInViewPort(entry.isIntersecting);
		});
	}, []);

	useEffect(() => {
		if (ref.current) {
			observer.observe(ref.current);
		}

		return () => {
			observer.disconnect();
		};
	}, [observer, ref]);

	return isInViewPort;
}
