import { Color } from 'three';
import { ModelElement } from '../../models/ModelElement.entity';
import { ModelJoint } from '../../models/ModelJoint.entity';
import { ModelJointSubResult } from '../../models/ModelJointSubResult.entity';

/**
 * A memo class for getting a color as a ThreeJS color
 * Memoizes so duplicate colors are not calculated multiple times
 */
export class ColorMemo {
	private colorMap = new Map<string, number[]>();

	// Get either the memoized value or create a new one
	get(value: string): number[] {
		const memoColor = this.colorMap.get(value);

		if (memoColor !== undefined) {
			return memoColor;
		}

		const color = this.creator(value);

		this.colorMap.set(value, color);

		return color;
	}

	private creator(value: string): number[] {
		// SRBG color doesn't translate well to ThreeJS color
		return new Color(value).toArray();
	}
}

export function createColorArray<
	T extends ModelElement | ModelJoint | ModelJointSubResult
>({
	components,
}: // hoveredComponent,
// selectedComponent,
{
	components: T[];
	// hoveredComponent: string | null;
	// selectedComponent: string | null;
}) {
	// Use a memoized creator to improve performance by reusing the color creation logic
	const colorMemo = new ColorMemo();

	const newArray = Float32Array.from(
		components.flatMap((component) => colorMemo.get(component[2]))
	);

	return newArray;
}
