import { CanvasTexture, LinearFilter, Texture } from 'three';
import { animate } from 'framer-motion';

interface Props {
	sectionHover: boolean;
	cubeHover: boolean;
	text?: string;
	[key: string]: any;
}

// To generate a Texture from Text to apply. This is used to create the text on the faces of the cube.
// Uses a small animation to change the color of the secton when hovering.
export const createTextureFromText = ({
	sectionHover,
	cubeHover,
	text,
}: Props): Texture | null => {
	const canvas = document.createElement('canvas') as HTMLCanvasElement;
	const context = canvas.getContext('2d') as CanvasRenderingContext2D;
	if (!context) return null;

	canvas.width = canvas.height = 200;

	context.fillStyle = '#F6F9FA'; // Initial color - Applied before animation starts
	context.fillRect(0, 0, canvas.width, canvas.height);

	if (text && cubeHover && !sectionHover) {
		context.fillStyle = sectionHover // Color of the section when hovered
			? 'rgba(247, 250, 250, 0.8)'
			: 'rgba(0, 36, 51, 0.3)';
		context.font = '700 32px Gotham Rounded A, Gotham Rounded B';
		context.textBaseline = 'middle';
		context.textAlign = 'center';
		context.fillText(text, canvas.width / 2, canvas.height / 2);
	}

	const texture: CanvasTexture = new CanvasTexture(canvas);
	texture.minFilter = LinearFilter; // This is needed to avoid a black border around the text

	const duration: number = 0.25; // Duration of the animation in seconds

	if (sectionHover) {
		// If the section is hovered, animate the color of the section
		animate('#F6F9FA', '#105876', {
			duration: duration,
			onUpdate: (value) => {
				context.fillStyle = value;
				context.fillRect(0, 0, canvas.width, canvas.height);
			},
		});
		// Animate the text color
		animate('rgba(0, 36, 51, 0.3)', 'rgba(247, 250, 250, 0.8)', {
			duration: duration,
			onUpdate: (value) => {
				if (text && cubeHover) {
					context.fillStyle = value;
					context.font = '700 32px Gotham Rounded A, Gotham Rounded B';
					context.textBaseline = 'middle';
					context.textAlign = 'center';
					context.fillText(text, canvas.width / 2, canvas.height / 2);
				}
				texture.needsUpdate = true;
			},
		});
	}
	return texture;
};
