// import { ThreeEvent } from '@react-three/fiber';
import React, {
	ReactNode,
	Ref,
	useLayoutEffect,
	useRef,
	useState,
} from 'react';
import {
	BufferGeometry,
	DoubleSide,
	FrontSide,
	InstancedMesh,
	Material,
	Matrix4,
	Vector3,
} from 'three';
import { mergeRefs } from '../../../Core/utils/mergeRefs';
import { ComponentId } from '../../../SharedTypes/API/Explorer';
import { ModelElement } from '../models/ModelElement.entity';
import { ModelJoint } from '../models/ModelJoint.entity';
import { ModelJointSubResult } from '../models/ModelJointSubResult.entity';
import { useAppSelector } from '../../../Core/redux/useAppSelector';
import { createColorArray } from './utils/Color';
import { Select } from '@react-three/postprocessing';

/**
 * Data saved in the mesh to look up the component id on pointer events
 */
export type ComponentUserData = {
	category: 'element' | 'joint';
	componentIds: ComponentId[];
};

/** Guard to ensure that user data is in the correct format */
export function isComponentUserData(test: any): test is ComponentUserData {
	return (
		test !== null &&
		'category' in test &&
		['element', 'joint', 'brace'].includes(test.category) &&
		Array.isArray(test.componentIds)
	);
}

interface Props<T extends ModelElement | ModelJoint | ModelJointSubResult> {
	components: T[];
	InstancedGeometry: ({ children }: { children: ReactNode }) => JSX.Element;
	placementFn: (element: T, scale?: boolean) => Matrix4;
	opacity?: number;
	category: 'element' | 'joint' | 'brace' | 'sensor';
	sides?: 'double' | 'single';
	shadows?: boolean;
	scale?: boolean;
	isHighlighted?: boolean;
}

export const SelectionMesh = <
	T extends ModelElement | ModelJoint | ModelJointSubResult
>({
	components,
	InstancedGeometry,
	placementFn,
	opacity = 1,
	category,
	instanceRef = React.createRef<InstancedMesh>(),
	sides = 'single',
	shadows = true,
	scale,
	isHighlighted,
}: Props<T> & { instanceRef?: Ref<InstancedMesh> }) => {
	// Refrence for the instanced mesh
	const meshRef = useRef<InstancedMesh>();

	// When the nodes change, place them at their correct positions
	// according to the given placement function
	// useEffect should be avoided as it causes issues with the WebGL renderer on initial load. useLayoutEffect is used instead.
	useLayoutEffect(() => {
		components.forEach((component, i) => {
			meshRef.current?.setMatrixAt(i, placementFn(component, scale));
		});
		if (meshRef.current) {
			meshRef.current.frustumCulled = false;
			meshRef.current.geometry.computeBoundingSphere();
			meshRef.current.instanceMatrix.needsUpdate = true;
		}
	}, [components, placementFn, meshRef, scale]);

	// When the nodes change, save their ids as userData on
	// the instancedMesh so parent components can look up
	// the component id from the instanceId
	useLayoutEffect(() => {
		if (meshRef.current) {
			const componentIds = components.map((component) => {
				// We do it this verbosely for type safety
				const id: ComponentId = component[0];
				return id;
			});

			meshRef.current.userData = {
				category,
				componentIds,
			} as ComponentUserData;
		}
	}, [components, placementFn, meshRef, category]);

	const [colorArray, setColorArray] = useState<Float32Array>(
		new Float32Array()
	);

	// Reset the array when hovered is null
	// This prevents stray colored geometry from building up
	useLayoutEffect(() => {
		setColorArray(
			createColorArray({
				components,
			})
		);
	}, [components]);

	// Used to determine if the structure colors should be displayed - When false the color is white. Controlled by the vertexColors in the mesh.
	const displayStructureColors = useAppSelector(
		(state) => state.explorerSettings.displayStructureColors
	);

	const mergedRef = mergeRefs(instanceRef, meshRef) as
		| Ref<InstancedMesh<BufferGeometry, Material | Material[]>>
		| undefined;

	return (
		<>
			<Select enabled={isHighlighted}>
				<instancedMesh
					scale={new Vector3(1, 1, -1)}
					ref={mergedRef}
					args={[
						null as unknown as BufferGeometry,
						null as unknown as Material,
						components.length,
					]}
					castShadow={shadows}
					receiveShadow={shadows}
				>
					<InstancedGeometry>
						<instancedBufferAttribute
							attach={`attributes-color`}
							args={[colorArray, 3]}
						/>
					</InstancedGeometry>

					{/* Lambert has better performance than Phong for non shiny surfaces https://threejs.org/docs/#api/en/materials/MeshLambertMaterial */}
					<meshLambertMaterial
						transparent={opacity < 1} // Note this messes with the z order and requires manual ordering
						opacity={opacity}
						side={sides === 'double' ? DoubleSide : FrontSide}
						vertexColors={displayStructureColors ? true : undefined}
					/>
				</instancedMesh>
			</Select>
		</>
	);
};
