import './SiteSelector.scss';
import background from './login-background.jpg';
import { UserHeader } from './UserHeader';
import { SiteControl, SiteControlButton } from './SiteControl';
import { useGetSitesQuery } from '../Core/Api';
import { useCallback, useEffect, useMemo } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { resetChosenDimensions } from '../RIAMS/Explorer/Explorer.slice';
import { setDateRange } from '../RIAMS/Dashboard/Dashboard.slice';

export const SiteSelector = () => {
	const dispatch = useDispatch();

	/** Reset configurations only concerning the chosen site */
	useEffect(() => {
		dispatch(resetChosenDimensions());
		dispatch(setDateRange(null));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const { isFetching, isLoading, isSuccess, data } = useGetSitesQuery({});

	const navigate = useNavigate();

	const [searchParams] = useSearchParams();

	const showResults = useMemo(
		() => isSuccess && !(isFetching || isLoading),
		[isFetching, isLoading, isSuccess]
	);

	const handleSiteSelect = useCallback(
		({ siteId }: { siteId: string }) => {
			navigate(`/riams/${siteId}/explorer/`);
		},
		[navigate]
	);

	// When the redirectToSingles search param is set and there is only one site, redirect diretly to it
	useEffect(() => {
		if (!data || searchParams.get('redirectToSingles') !== 'true') {
			return;
		}

		// Get the total list of sites regardless of acconunt
		const totalSites = data.accounts.flatMap(({ sites }) => sites);

		// If there is exactly 1 site available, redirect to it
		if (totalSites.length === 1) {
			handleSiteSelect({ siteId: totalSites[0].id });
		}
	}, [data, handleSiteSelect, searchParams]);

	return (
		<>
			{showResults && (
				<div className="SiteSelector">
					<div
						className="SiteSelector__BackgroundImage"
						style={{ backgroundImage: `url(${background})` }}
					></div>
					<div className="SiteSelector__Sidebar">
						<header className="SiteSelector__SidebarHeader">
							<UserHeader />
						</header>
						<main className="SiteSelector__SidebarMain">
							{data?.accounts.map(({ id: accountId, name, sites }) => (
								<SiteControl name={name} key={accountId}>
									{sites.map(({ id: siteId, name }) => (
										<SiteControlButton
											onClick={() => handleSiteSelect({ siteId })}
											key={siteId}
										>
											{name}
										</SiteControlButton>
									))}
								</SiteControl>
							))}
						</main>
					</div>
				</div>
			)}
		</>
	);
};
