import { NavigationButton } from './NavigationButton';
import classNames from 'classnames';
import './ExplorerNavigation.scss';
import { IconButton } from '../../Core/components/IconButton/IconButton';
import { useAppDispatch } from '../../Core/redux/useAppDispatch';
import { setZoomConstant } from './Explorer.slice';
import { useLocation } from 'react-router-dom';

type Props = {
	onToggleExplorerSettings?: () => void;
	onToggleAnalysisExplorer?: () => void;
};

export const ExplorerNavigation = ({
	onToggleExplorerSettings,
	onToggleAnalysisExplorer,
}: Props) => {
	const dispatch = useAppDispatch();

	const zoomIn = () => {
		dispatch(setZoomConstant(0.8));
	};

	const zoomOut = () => {
		dispatch(setZoomConstant(1.25));
	};

	const location = useLocation();
	const pathSegments = location.pathname.split('/');

	return (
		<nav className="ExplorerNavigation">
			<div className="ExplorerNavigation__Button" onClick={zoomIn}>
				<NavigationButton iconName="Plus" iconWidth={12} />
			</div>
			<div className="ExplorerNavigation__Button" onClick={zoomOut}>
				<NavigationButton iconName="Minus" iconWidth={12} />
			</div>
			{onToggleExplorerSettings && (
				<div
					className="ExplorerNavigation__Button"
					onClick={onToggleExplorerSettings}
				>
					<NavigationButton iconName="ViewSettings" iconWidth={19} />
				</div>
			)}
			{onToggleAnalysisExplorer && (
				<div
					className={classNames('ExplorerNavigation__AnalysisExplorerButton', {
						'ExplorerNavigation__AnalysisExplorerButton--Active':
							pathSegments.includes('analysis'),
						'ExplorerNavigation__AnalysisExplorerButton--NoActive':
							!pathSegments.includes('analysis'),
					})}
					onClick={onToggleAnalysisExplorer}
				>
					<IconButton icon="AnalysisExplorer" iconSize={20}>
						Explore
					</IconButton>
				</div>
			)}
		</nav>
	);
};
