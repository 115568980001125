import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../../../Core/redux/store';
import { useAppSelector } from '../../../../Core/redux/useAppSelector';
import {
	ResultTableCellColumn,
	ResultTableColumn,
	sortDirection,
} from '../ResultsTable';

// Currently used to deal with moving the coloumns around in the analysisExplorer

const initialState = {
	dragTarget: null as ResultTableCellColumn | null,
	dragStart: null as ResultTableCellColumn | null,
	dragging: false,
	tableColumns: [] as ResultTableColumn[],
	lastClickedKey: 'sort-desc' as sortDirection,
};

export const results = createSlice({
	name: 'results',
	initialState: initialState,
	reducers: {
		setDragTarget: (
			state,
			{ payload }: PayloadAction<ResultTableCellColumn | null>
		) => {
			state.dragTarget = payload;
		},
		setDragStart: (
			state,
			{ payload }: PayloadAction<ResultTableCellColumn>
		) => {
			state.dragStart = payload;
		},
		setDragging: (state, { payload }: PayloadAction<boolean>) => {
			state.dragging = payload;
		},
		setTableColumns: (
			state,
			{ payload }: PayloadAction<ResultTableColumn[]>
		) => {
			state.tableColumns = payload;
		},
		setLastClickedKey: (state, { payload }: PayloadAction<sortDirection>) => {
			state.lastClickedKey = payload;
		},
	},
});

export const {
	setDragTarget,
	setDragStart,
	setDragging,
	setTableColumns,
	setLastClickedKey,
} = results.actions;

export const useDragTarget = (): ResultTableCellColumn | null =>
	useAppSelector((state: RootState) => state.results.dragTarget);

export const useDragStart = (): ResultTableCellColumn | null =>
	useAppSelector((state: RootState) => state.results.dragStart);

export const useDragging = (): boolean =>
	useAppSelector((state: RootState) => state.results.dragging);

export const useTableColumns = (): ResultTableColumn[] =>
	useAppSelector((state: RootState) => state.results.tableColumns);

export const useLastClickedkey = (): sortDirection =>
	useAppSelector((state: RootState) => state.results.lastClickedKey);
