import { ReactNode } from 'react';
import { ModelElement } from '../../models/ModelElement.entity';
import { SelectionMesh } from '../SelectionMesh';
import { BoxPlacementFn } from '../utils/PlacementFn';
import { BoxExtrusion } from './geometry/BoxExtrusion';
import { BoxGeometry } from '../../../../SharedTypes/API/Explorer';
import { sortArrays } from '../utils/SortArrays';

interface BoxMeshProps {
	modelBoxElements: ModelElement[];
	isHighlighted?: boolean;
}

export const RealisticBoxMesh = ({
	modelBoxElements,
	isHighlighted,
}: BoxMeshProps) => {
	const sortedBoxElements = sortArrays(modelBoxElements);

	return (
		<>
			{sortedBoxElements.map((group) => (
				<BoxGroup
					key={group.key}
					modelBoxElements={group.elements}
					isHighlighted={isHighlighted}
				/>
			))}
		</>
	);
};

const BoxGroup = ({ modelBoxElements, isHighlighted }: BoxMeshProps) => {
	const geometry = modelBoxElements[0][4][0][2] as BoxGeometry; // Get the geometry of the first element

	const InstancedBoxGeometry = ({ children }: { children: ReactNode }) => (
		<BoxExtrusion
			height={geometry[0] * 2}
			width={geometry[1] * 2}
			flangeThickness={geometry[2] * 2}
			webThickness={geometry[3] * 2}
		>
			{children}
		</BoxExtrusion>
	);

	return (
		<SelectionMesh
			category="element"
			components={modelBoxElements}
			InstancedGeometry={InstancedBoxGeometry}
			placementFn={BoxPlacementFn}
			isHighlighted={isHighlighted}
		/>
	);
};
