import React, { useRef, useState, useEffect } from 'react';
import { Mesh, Camera, MeshPhongMaterial } from 'three';
import { useCamera } from '@react-three/drei';
import { useThree } from '@react-three/fiber';
import { animate, AnimationPlaybackControls } from 'framer-motion';
import { Vector3 } from 'three';
import { createTextureFromText } from '../utils/CreateTextureFromText';
import { useViewCubeFunctions } from '../hooks/useViewCubeFunctions';
import { OrbitControls as OrbitControlsImpl } from 'three-stdlib';
import { useCubeHover } from '../state/state';

// Add the type for PlaneComponentProps
type BoxComponentProps = {
	boxRotation?: [number, number, number];
	boxPosition: [number, number, number];
	boxSize: [number, number, number];
	opacity: number;
	fadeInDuration: number;
	fadeOutDuration: number;
	vbCamera: React.MutableRefObject<Camera>;
	targetCameraPosition: React.MutableRefObject<Vector3 | undefined>;
};

const BoxComponent: React.FC<BoxComponentProps> = ({
	boxRotation,
	boxPosition,
	boxSize,
	opacity,
	fadeInDuration,
	fadeOutDuration,
	vbCamera,
	targetCameraPosition,
}: BoxComponentProps) => {
	const controls = useThree((state) => state.controls) as OrbitControlsImpl;
	const { camera } = useThree();
	const boxRef = useRef<Mesh>(null!);
	const [sectionHover, setSectionHover] = useState<boolean>(false);
	const cubeHover = useCubeHover();
	const useVbCamera = useCamera(vbCamera);
	const { handlePointerOut, handlePointerMove, handleCubeClick } =
		useViewCubeFunctions(camera, controls, targetCameraPosition);

	const animationControls = useRef<AnimationPlaybackControls>(); // Used to stop the animation when the cube is no longer hovered
	// Animation for opacity.
	useEffect(() => {
		const material = boxRef.current.material as MeshPhongMaterial;

		// This hides the box while cube is not hovered. This option is better than using opacity because it doesn't affect the raycast and we avoid depth rendering.
		// Conditional rendering in parent component is not an option because it would cause the box to be re-rendered every time the cube is hovered and quirks in Three makes it look very odd.
		if (material && cubeHover) {
			boxRef.current.scale.set(1, 1, 1);
		}
		if (material && !cubeHover) {
			boxRef.current.scale.set(0, 0, 0);
		}

		// //Fade in
		if (material && cubeHover) {
			animationControls.current && animationControls.current.stop(); // To avoid hover bug
			animationControls.current = animate(opacity, 1, {
				duration: fadeInDuration,
				onUpdate: (value) => {
					material.opacity = value;
					material.needsUpdate = true;
				},
			});
		}
		//Fade out
		else if (material && !cubeHover) {
			animationControls.current && animationControls.current.stop(); // To avoid hover bug
			animationControls.current = animate(1, opacity, {
				duration: fadeOutDuration,
				onUpdate: (value) => {
					material.opacity = value;
					material.needsUpdate = true;
				},
			});
			setSectionHover(false);
		}
	}, [cubeHover, fadeInDuration, fadeOutDuration, opacity, sectionHover]);

	return (
		<mesh
			ref={boxRef}
			raycast={useVbCamera}
			position={boxPosition}
			rotation={boxRotation}
			onPointerOut={(e) => {
				handlePointerOut(e, setSectionHover);
			}}
			onPointerMove={(e) => {
				handlePointerMove(e, setSectionHover);
			}}
			onClick={(e) => {
				setSectionHover(false);
				handleCubeClick(boxPosition, e);
			}}
		>
			<boxGeometry args={boxSize} />
			<meshPhongMaterial
				transparent
				map={createTextureFromText({ sectionHover, cubeHover })}
			/>
		</mesh>
	);
};

export default BoxComponent;
