import { Icon } from '../Icon/Icon';
import './LoadingBlock.scss';

interface LoadingBlockProps {
	iconSize?: number;
	color?: string;
}

/**
 * A component that displays a loading spinner.
 *
 * @param {number} [iconSize=30] - The size of the loading spinner icon.
 * @param {string} [color='#fff'] - The color of the loading spinner icon. Accepts a HEX color code or an RGBA color string.
 * @returns {JSX.Element} The rendered loading block.
 */
export const LoadingBlock = ({
	iconSize = 30,
	color = '#fff',
}: LoadingBlockProps): JSX.Element => {
	return (
		<div className="LoadingBlock">
			<Icon name="LoadingSpinner" width={iconSize} style={{ color }} />
		</div>
	);
};
