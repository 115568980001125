import classNames from 'classnames';
import {
	FC,
	ReactNode,
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react';
import { Button } from '../../Core/components/Button/Button';
import { Card } from '../../Core/components/Card/Card';
import { Icon } from '../../Core/components/Icon/Icon';
import { IntegrityLineChart } from '../../Core/components/Chart/LineChart';
import { ProgressBar } from '../../Core/components/ProgressBar/ProgressBar';
import { DashboardStatus } from '../../Core/types/DashboardStatus';
import './DashboardCard.scss';
import { IndicatorDot } from '../../Core/components/IndicatorDot/IndicatorDot';
import { useOnClickOutside } from 'usehooks-ts';

import { PopMenu, PopMenuItems } from '../../Core/components/PopMenu/PopMenu';
import { AnimateHeight } from '../../Core/components/AnimateHeight/AnimateHeight';
import {
	ChartDataSet,
	ChartDataSetData,
	RFCDataSet,
	ScatterDataSet,
} from '../../SharedTypes/API/Dashboard';
import { IntegrityScatterPlotChart } from '../../Core/components/Chart/ScatterPlotChart';
import { calculateStatusForTesting } from './DashboardDataGenerator';
import { last } from '../../Core/utils/Array';
import { useAppDispatch } from '../../Core/redux/useAppDispatch';
import { setSelectedSensor } from '../Explorer/Explorer.slice';
import { IntegrityRFCChart } from '../../Core/components/Chart/RFCChart';
import { ColorLookup } from '../../Core/utils/ColorLookup';
import { useParams } from 'react-router-dom';
import { useZoomRange } from './Dashboard.slice';
import { useGetMetricQuery } from '../../Core/Api';

type DashboardCardProps = {
	id: string;
	value: number; // Represents the progress on the progress bar
	icon: ReactNode;
	title: string;
	chartDataSets: ChartDataSet[];
	dateClicked: (date: Date | number) => void;
	selectCard: (id: string, title: string) => void;
	selectedDate?: number;
	size?: 'default' | 'extended' | 'fullscreen';
	expanded: boolean;
	expandControls: boolean;
	interactable?: boolean;
	dateRange: [Date, Date];
	isAnalytical?: boolean;
};

export const DashboardCard: FC<DashboardCardProps> = ({
	id,
	value,
	icon,
	title,
	chartDataSets,
	dateClicked,
	selectedDate,
	size = 'default',
	selectCard,
	expanded = false,
	expandControls = true,
	interactable = false,
	dateRange,
	isAnalytical = true,
}) => {
	// Used by PopMenu component
	const menuRef = useRef<HTMLDivElement>(null);
	const params = useParams();
	const zoomRange = useZoomRange();
	const dispatch = useAppDispatch();

	const [selectedSetId, setSelectedSetId] = useState<string>(
		chartDataSets[0].id
	);

	const [latestThreeSets, setLatestThreeSets] = useState([] as ChartDataSet[]);
	const [hiddenDataSets, setHiddenDataSets] = useState<ChartDataSet[]>([]);
	const [showSelect, setShowSelect] = useState(false);
	useOnClickOutside(menuRef, () => setShowSelect(false));

	/** Checks if we should query data, other than the default dataSet we get via props */
	const shouldFetchData = (): boolean => {
		if (!zoomRange) {
			return false;
		}

		return true;
	};

	/**
	 * Some complicated logic to construct the childId
	 * 	In the future we should have a parrent id "path" on the object itself
	 */
	const getChildId = (setId: string) => {
		if (setId === params['*']) {
			return setId;
		}

		if (setId.includes('||')) {
			const split = setId.split('||');

			return [split[0], split[1]].join('||');
		}

		return `${params['*']}||${setId}`;
	};

	const { data } = useGetMetricQuery(
		{
			siteId: params.siteId!,
			metricId: getChildId(chartDataSets[0].id),
			// We use a fallback, since "skip" does not block the checking of args
			start: zoomRange?.[0] ? zoomRange?.[0] : dateRange![0].getTime(),
			stop: zoomRange?.[1] ? zoomRange?.[1] : dateRange![1].getTime(),
		},
		{
			skip: !shouldFetchData(),
		}
	);

	const intelligentChartDataSets = useMemo<ChartDataSet[]>(() => {
		const hasFetchedData = data && zoomRange;
		if (!hasFetchedData) {
			return chartDataSets;
		}

		if (Array.isArray(data[0])) {
			return data[0];
		}

		return data;
	}, [chartDataSets, data, zoomRange]);

	// based on the selected date and zoomrange, we can see what thing we need to return
	const selectedChart = useMemo(() => {
		const extractedId = selectedSetId.split('||').at(-1);

		return intelligentChartDataSets.find(
			(set) => set.id.split('||').at(-1) === extractedId
		);
	}, [intelligentChartDataSets, selectedSetId]);

	const firstChart = intelligentChartDataSets[0];

	const getValueOnChart = useMemo(() => {
		if (zoomRange && selectedChart?.chartType === 'line') {
			if (
				selectedChart.id !== intelligentChartDataSets[0].id &&
				intelligentChartDataSets[0].chartType === 'line'
			) {
				const point = intelligentChartDataSets[0].data.find(
					(point) => point.x === selectedDate
				);

				return point?.y;
			}

			const point = selectedChart.data.find(
				(point) => point.x === selectedDate
			);

			return point?.y;
		}
		return value;
	}, [
		intelligentChartDataSets,
		selectedChart?.chartType,
		selectedChart?.data,
		selectedChart?.id,
		selectedDate,
		value,
		zoomRange,
	]);

	useEffect(() => {
		setLatestThreeSets(chartDataSets.slice(0, 3));
	}, [chartDataSets]);

	const getButtonLabel = (name: string): string => {
		if (!name) {
			return 'No name found...';
		}
		if (name.includes('|>')) {
			return name.split('|>')[1];
		}
		return name;
	};

	const selectDataSet = (id: string): void => {
		const extractedId = id.split('||').at(-1);

		if (!extractedId) {
			return;
		}

		const found = latestThreeSets.find(
			(set) => set.id.split('||').at(-1) === extractedId
		);

		if (!found) {
			const dataSet = chartDataSets.find(
				(set) => set.id.split('||').at(-1) === extractedId
			);
			if (!dataSet) {
				return;
			}
			const withoutSummarySet = latestThreeSets.filter(
				(set) =>
					set.id.split('||').at(-1) !== chartDataSets[0].id.split('||').at(-1)
			);

			const newLatest = [chartDataSets[0], dataSet, ...withoutSummarySet].slice(
				0,
				3
			);
			setLatestThreeSets(newLatest);
		}

		setSelectedSetId(extractedId);
	};

	// Update the hiddenDataSets here, as a side effect of setting the lastest 3 sets
	useEffect(() => {
		if (chartDataSets.length > 3) {
			const hiddenSets = filterLatestFromRest({
				latestThreeSets,
				allSets: chartDataSets,
			});
			setHiddenDataSets(hiddenSets);
		}
	}, [chartDataSets, latestThreeSets]);

	// Allow only to expand, when the size is defined as expanded, otherwise, disable the functionality
	const handleChangeExpandedState = () => {
		selectCard(id, title);
	};

	const topCardRadiusState = useMemo(
		() => getTopRadius({ expanded, size }),
		[expanded, size]
	);

	const iconClassnames = classNames(
		expanded
			? ['DashboardCard__TitleAction DashboardCard__TitleAction--Expanded']
			: ['DashboardCard__TitleAction']
	);

	const chartCardTheme = size === 'fullscreen' ? 'default' : 'dark';
	const chartCardRadius = size === 'fullscreen' ? 'right' : 'bottom';

	// Finds the thresholds for the given date (or default to the last threshold set)
	const getThresholdsOnDate = useCallback(
		(dataSet: ChartDataSetData[]) => {
			if (!selectedDate || selectedDate > (dateRange?.[1]?.getTime() ?? 0)) {
				return last(dataSet)?.thresholds ?? [];
			}

			const dateAsNumber = new Date(selectedDate).getTime();

			return (
				dataSet?.find((entry) => entry.x === dateAsNumber)?.thresholds ?? []
			);
		},
		[dateRange, selectedDate]
	);

	// Finds the value on a given date (defaults to last value)
	const getValueOnDate = useCallback(
		(dataSet: ChartDataSetData[]) => {
			if (!selectedDate || selectedDate > (dateRange?.[1]?.getTime() ?? 0)) {
				return last(dataSet)?.y ?? 0;
			}

			const dateAsNumber = new Date(selectedDate).getTime();

			return dataSet?.find((entry) => entry.x === dateAsNumber)?.y ?? 0;
		},
		[dateRange, selectedDate]
	);

	const getIndicatorStatus = (setId: string): DashboardStatus => {
		// The ids of fetched data, is point all the way to the metric we fetch
		// Hence we need to grab the "last" id, of that string
		const normalizedId = setId.split('||').at(-1);
		const foundSet = intelligentChartDataSets.find(
			(set) => set.id.split('||').at(-1) === normalizedId
		);

		if (!foundSet) {
			return 'default';
		}

		/** The Scatter & RFC plots do not follow traditional thresholding math */
		if (
			foundSet &&
			(foundSet.chartType === 'scatter' || foundSet.chartType === 'rfc')
		) {
			return 'default';
		}

		return getStatusOnDate(foundSet);
	};

	const getColorOverride = (setId: string): string | undefined => {
		const normalizedId = setId.split('||').at(-1);
		const foundSet = intelligentChartDataSets.find(
			(set) => set.id.split('||').at(-1) === normalizedId
		);
		if (foundSet?.chartType === 'line') {
			return undefined;
		}

		return ColorLookup.forGraph['blue'];
	};

	// Returns the "color" of the chart based on the values on a given day
	const getStatusOnDate = useCallback(
		(set: ChartDataSet) => {
			if (!selectedDate || selectedDate > (dateRange?.[1]?.getTime() ?? 0)) {
				return calculateStatusForTesting(
					last(set.chartType === 'scatter' ? set.data[0] : set.data)
						?.thresholds ?? [],
					last(set.chartType === 'scatter' ? set.data[0] : set.data)?.y ?? 0,
					set.thresholdOrder
				);
			}

			return calculateStatusForTesting(
				getThresholdsOnDate(
					set.chartType === 'scatter' ? set.data[0] : set.data
				),
				getValueOnDate(set.chartType === 'scatter' ? set.data[0] : set.data),
				set.thresholdOrder
			);
		},
		[dateRange, getThresholdsOnDate, getValueOnDate, selectedDate]
	);

	const popMenuItems: PopMenuItems = hiddenDataSets.map((set) => ({
		key: set.id,
		label: set.shortName,
		title: getButtonLabel(set?.name),
		icon: (
			<IndicatorDot
				status={getIndicatorStatus(set.id)}
				color={getColorOverride(set.id)}
			/>
		),
	}));

	const variants = {
		open: {
			opacity: 1,
			height: 'auto',
		},
		collapsed: { opacity: 0, height: 0 },
	};

	const getCardLabel = (name: string): string => {
		if (!name) {
			return 'No name found...';
		}
		if (name.includes('|>')) {
			return name.split('|>')[0];
		}
		return name;
	};

	return (
		<div
			className={classNames(['DashboardCard'], {
				DashboardCard__WithHover: interactable,
			})}
			data-size={size}
		>
			<Card
				radiusPosition={topCardRadiusState}
				onClick={handleChangeExpandedState}
				onPointerEnter={() => dispatch(setSelectedSensor(chartDataSets[0].id))}
				onPointerLeave={() => dispatch(setSelectedSensor(null))}
			>
				<div className="DashboardCard__Grid">
					<DashboardCardStatus
						status={getIndicatorStatus(firstChart.id)}
						icon={icon}
					/>
					<div className="DashboardCard__Main" data-size={size}>
						<div className="DashboardCard__Title">
							<h1 className="DashboardCard__TitleText">
								{getCardLabel(title)}
							</h1>
							{size === 'extended' && expandControls && (
								<div className={iconClassnames}>
									<Icon name="CaretDown" width={12} />
								</div>
							)}
						</div>
						<div className="DashboardCard__Value">
							<h2 className="DashboardCard__ValueText">
								{getValueOnChart ?? 0}%
							</h2>
						</div>
						<div className="DashboardCard__Progress">
							<ProgressBar
								thresholds={getThresholdsOnDate(
									firstChart.chartType === 'scatter'
										? firstChart.data[0]
										: firstChart.data
								)}
								progress={getValueOnChart ?? 0}
								status={getIndicatorStatus(firstChart.id)}
							/>
							<div className="DashboardCard__Indicators">
								<IndicatorDot status="default" />
								<IndicatorDot status="critical" />
							</div>
						</div>
					</div>
				</div>
			</Card>
			<AnimateHeight
				ease="easeOut"
				duration={0.2}
				variants={variants}
				isVisible={expanded}
				className="u-full-height"
			>
				<Card
					theme={chartCardTheme}
					radiusPosition={chartCardRadius}
					padding="tiny"
				>
					{latestThreeSets.length > 1 && (
						<div className="DashboardCard__Selectors">
							{latestThreeSets.map((set, index) => (
								<Button
									theme="TrafficLight"
									data-active={selectedSetId === set.id.split('||').at(-1)}
									onClick={() => selectDataSet(set.id)}
									key={set?.id ?? index}
									title={getButtonLabel(set?.name)}
								>
									<span
										style={{
											display: 'flex',
											alignItems: 'center',
											gap: '4px',
											pointerEvents: 'none',
											fontSize: '13px',
											color:
												selectedSetId === set.id.split('||').at(-1)
													? 'rgba(255,255,255,1)'
													: 'rgba(255,255,255,0.5)',
										}}
									>
										<IndicatorDot
											status={getIndicatorStatus(set.id)}
											size={10}
											color={getColorOverride(set.id)}
										/>
										<label className="DashboardCard__Selectors-Label">
											{getButtonLabel(set?.shortName)}
										</label>
									</span>
								</Button>
							))}
							{hiddenDataSets.length > 0 && (
								<PopMenu
									theme="Dark"
									items={popMenuItems}
									placement="bottom-start"
									onChangeState={(state) => setShowSelect(state === 'open')}
									onSelectItem={selectDataSet}
									elementRef={menuRef}
									className={classNames('DashboardCard__MenuIcon', {
										isOpen: showSelect,
									})}
								>
									<Icon name="ThreeDotsH" width={14} ref={menuRef} />
								</PopMenu>
							)}
						</div>
					)}
					{selectedChart?.chartType === 'line' && (
						<IntegrityLineChart
							height={150}
							dataSet={selectedChart!}
							dateClicked={dateClicked}
							chosenDate={selectedDate}
							size={size}
							isAnalytical={isAnalytical}
						/>
					)}
					{selectedChart?.chartType === 'rfc' && (
						<IntegrityRFCChart
							height={350}
							dataSet={selectedChart! as RFCDataSet}
						/>
					)}
					{selectedChart?.chartType === 'scatter' && (
						<IntegrityScatterPlotChart
							dataSet={selectedChart! as ScatterDataSet}
						/>
					)}
				</Card>
			</AnimateHeight>
		</div>
	);
};

type DashboardCardStatusProps = {
	status: DashboardStatus;
	icon: ReactNode | null;
};

const DashboardCardStatus: FC<DashboardCardStatusProps> = ({
	status,
	icon,
}) => (
	<div
		className={classNames([
			'DashboardCardStatus',
			`DashboardCardStatus__Status-${status}`,
		])}
	>
		{icon && <>{icon}</>}
	</div>
);

type FilterLatestFromRestArgs = {
	latestThreeSets: ChartDataSet[];
	allSets: ChartDataSet[];
};
const filterLatestFromRest = ({
	latestThreeSets,
	allSets,
}: FilterLatestFromRestArgs) => {
	return allSets
		.reduce((acc: ChartDataSet[], current: ChartDataSet) => {
			const isInLatestThree = latestThreeSets.find(
				(set) => set.id === current.id
			);
			if (isInLatestThree) {
				return acc;
			}

			return [current, ...acc];
		}, [])
		.sort();
};

type GetTopRadiusArgs = {
	expanded: boolean;
	size: 'default' | 'extended' | 'fullscreen';
};
const getTopRadius = ({ expanded, size }: GetTopRadiusArgs) =>
	expanded && size !== 'fullscreen'
		? 'top'
		: expanded && size === 'fullscreen'
		? 'left'
		: 'all';
