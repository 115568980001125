import { ApexOptions } from 'apexcharts';
import Chart from 'react-apexcharts';
import classNames from 'classnames';
import { FC, useMemo } from 'react';
import { RFCDataSet } from '../../../SharedTypes/API/Dashboard';
import { createArrayWith } from '../../utils/Array';
import { ChartNoData } from './ChartNoData';
import { createChartThresholdData } from './ChartThresholdData';
import { renderToString } from 'react-dom/server';
import { Icon } from '../Icon/Icon';
import { ColorLookup } from '../../utils/ColorLookup';
import { useGetMetricQuery } from '../../Api';
import { useParams } from 'react-router-dom';
import {
	useDateRange,
	useSelectedDate,
} from '../../../RIAMS/Dashboard/Dashboard.slice';
import { ChartWarning } from './ChartWarning';
import {
	getDate,
	getHour,
	getMinutes,
	getMonth,
	getPadded,
	getYear,
} from '../../utils/Dates';
import { getChartDownloadName } from './ChartOptions';
import { IndicatorDot } from '../IndicatorDot/IndicatorDot';
import { ApexSeries } from '../../types/ChartDataSet';
import { AnimatePresence, motion } from 'framer-motion';
import './MultiLineChart.scss';
import './LineChart.scss';
import './RFCChart.scss';

type RFCChartProps = {
	dataSet: RFCDataSet;
	height?: number;
	dateRange: [Date, Date] | null;
};

export const IntegrityRFCChart: FC<Omit<RFCChartProps, 'dateRange'>> = ({
	dataSet: dataSetFromProps,
	height,
}) => {
	const selectedDate = useSelectedDate();
	const dateRange = useDateRange();
	const params = useParams();

	const siteId = useMemo(() => {
		return params.siteId!;
	}, [params]);

	/**
	 * Checks if the date range is a single, 24 hours period
	 * Because of the way dates are picked in the dae picker,
	 * the first day in the picker is the the actual date we want, whereas the second date is the day after
	 * Thus the logic here is, that consecutive dates mean a single day
	 */
	const dateRangeIsSingleDay = useMemo(() => {
		const date1 = dateRange?.[0];
		const date2 = dateRange?.[1];

		// No date range. We are basically in an invalid state
		if (!date1 || !date2) {
			return false;
		}

		// Determine if date1 is one day before date2
		const date2OneDayBefore = new Date(date2.getTime());
		date2OneDayBefore.setDate(date2OneDayBefore.getDate() - 1);

		// If date1 is one day before date2, we are in single day mode
		// Use toDateString to only compare dates and not time
		return date1.toDateString() === date2OneDayBefore.toDateString();
	}, [dateRange]);

	/** Checks if we should query data, other than the default dataSet we get via props */
	const shouldFetchData = useMemo(() => {
		// We only fetch new data if we are in "single hour" mode,
		// Which is when the date range is a single day
		if (dateRangeIsSingleDay === false) {
			console.log('ShouldFetchData: No. We are in multi day mode');

			return false;
		}

		if (!selectedDate && !dateRange?.[1]) {
			console.log('ShouldFetchData: No. No selected date or date range end');
			return false;
		}

		if (selectedDate === dateRange?.[1].getTime()) {
			console.log(
				'ShouldFetchData: No. Selected date is the same as the end of the date range'
			);

			return false;
		}

		console.log(
			'ShouldFetchData: Yes. There is both a selected date and range end and they are not the same'
		);

		return true;
	}, [dateRange, dateRangeIsSingleDay, selectedDate]);

	/** Fetch dataset for a selected date that is not part of the original query */
	const { data: fetchedDataSet, isFetching: isFetchingDataSet } =
		useGetMetricQuery(
			{
				siteId,
				metricId: dataSetFromProps.id,
				start: selectedDate - 1000 * 60 * 60,
				stop: selectedDate,
			},
			{
				skip: !shouldFetchData,
			}
		);

	const dataSet = useMemo(() => {
		// If the fetch is not needed, we show the data from props
		if (!shouldFetchData) {
			return dataSetFromProps;
		}

		if (!fetchedDataSet || fetchedDataSet[0].chartType !== 'rfc') {
			return null;
		}

		return fetchedDataSet[0];
	}, [shouldFetchData, fetchedDataSet, dataSetFromProps]);

	// The date depicted on the graph
	// This can either be the selected date on the graph
	// Or the end of the date range in the date picker
	const dateDepicted = useMemo(() => {
		if (!dateRange?.[1]) {
			console.log('No date range');
			return '';
		}

		const date = shouldFetchData ? selectedDate : dateRange[1].getTime();

		const day = getPadded(getDate(date));
		const month = getPadded(getMonth(date));
		const year = getYear(date);
		const hour = getPadded(getHour(date));
		const minute = getPadded(getMinutes(date));

		return `${day}/${month}/${year} ${hour}:${minute}`;
	}, [dateRange, selectedDate, shouldFetchData]);

	// Wether or not to show the date warning
	// This should only be shown in multi-date mode,
	// since the selected date does not have any impact on the graph in multi-date mode
	const showDateWarning = useMemo(() => {
		// The warning should only be shown in multi-date mode
		const dateRangeIsMultipleDays = !dateRangeIsSingleDay;

		// We neeed an end date to compare with the selected date
		const isDateRangeEndAvailable = dateRange?.[1] ? true : false;

		// The selected date should be different from the end of the date range
		// ie. the selected date is not the one depicted on thte graph
		// and thus a warning should be shown
		const isDateRangeEndDifferentFromSelectedDate =
			selectedDate !== dateRange?.[1].getTime();

		// All statements should be true for the warning to be shown
		return (
			dateRangeIsMultipleDays &&
			isDateRangeEndAvailable &&
			isDateRangeEndDifferentFromSelectedDate
		);
	}, [dateRangeIsSingleDay, dateRange, selectedDate]);

	return (
		<div className={classNames(['LineChart', 'LineChart--Analytical'])}>
			{(!dataSet || dataSet.data.length === 0) && <ChartNoData />}
			{dataSet !== null && (
				<>
					<RFCChart dataSet={dataSet} dateRange={dateRange} height={height} />
					<ChartWarning
						dateString={dateDepicted}
						showDateWarning={showDateWarning}
					/>
				</>
			)}
			<AnimatePresence>
				{isFetchingDataSet && (
					<motion.div
						className="LineChart__LoadingOverlay"
						initial={{ opacity: 0 }}
						animate={{ opacity: 1 }}
						exit={{ opacity: 0 }}
					>
						<Icon name="LoadingSpinner" width={14} />
					</motion.div>
				)}
			</AnimatePresence>
		</div>
	);
};

export const RFCChart: FC<RFCChartProps> = ({
	dateRange,
	dataSet,
	height = 100,
}) => {
	// Devise a function that can check what the highest value is, and then only plot in the thresholds that are needed (plus/minus a bit)
	const neededThresholds = useMemo(() => {
		if (!dataSet) {
			return null;
		}

		return createChartThresholdData(
			dataSet.data,
			dataSet.minValue,
			dataSet.maxValue
		);
	}, [dataSet]);

	const options: ApexOptions | null = useMemo(() => {
		if (!dataSet || !neededThresholds) {
			return null;
		}

		const stepLines = createArrayWith<'smooth' | 'straight' | 'stepline'>(
			neededThresholds.length,
			dataSet.chartType === 'rfc' ? 'smooth' : 'stepline'
		);
		const dashes = createArrayWith<number>(neededThresholds.length, 0);

		const widths = createArrayWith(neededThresholds.length, 2);
		const colors = createArrayWith(neededThresholds.length, '#fff');
		const markerColors = createArrayWith(
			neededThresholds.length,
			'transparent'
		);
		const fillers = createArrayWith(neededThresholds.length, 'solid');

		const dataUnits = dataSet.units ? dataSet.units : ' ';

		// Create the export download name
		const exportDownloadName = getChartDownloadName({
			dateRange: dateRange!,
			name: dataSet.name,
		});

		return {
			stroke: {
				curve: [...stepLines, 'straight'],
				width: [...widths, 4],
				dashArray: [...dashes, 0],
			},
			xaxis: {
				type: 'numeric',
				axisTicks: {
					show: false,
				},
				tooltip: {
					enabled: false,
				},
				labels: {
					show: true,
					formatter: (value) => {
						return `Δ${parseFloat(value).toFixed(1)} mm`;
					},
					style: {
						colors: 'rgba(255,255,255,0.7)',
						cssClass: 'LineChart__YAxis',
					},
				},
				axisBorder: {
					show: false,
				},
				crosshairs: {
					show: false,
				},
			},
			legend: {
				show: true,
				// floating: true,
				position: 'bottom',
				horizontalAlign: 'center',
				customLegendItems: ['Reference period', 'Moving period'],
				fontFamily: 'Gotham Rounded A, Gotham Rounded B',
				fontSize: '11px',
				fontWeight: 400,
				height: 30,
				labels: {
					colors: ['rgba(255,255,255,0.8)', 'rgba(255,255,255,0.8)'],
					useSeriesColors: false,
				},
				// Make legend markers a rounded line
				markers: {
					width: 24,
					height: 5,
					radius: 5,
					offsetY: 0, // Optical centering
				},
				itemMargin: {
					horizontal: 8,
				},
			},
			yaxis: {
				forceNiceScale: true,
				show: true,
				tickAmount: 4,
				logarithmic: true,
				logBase: 1000,
				labels: {
					show: true,
					formatter: formatYAxisValue(dataUnits),
					padding: 5,
					style: {
						colors: 'rgba(255,255,255,0.7)',
						cssClass: 'LineChart__YAxis',
					},
				},
				min: dataSet.minValue,
			},
			colors: [...colors, ColorLookup.forGraph['blue']],
			chart: {
				background: '#003347',
				width: '100%',
				zoom: {
					enabled: true,
					type: 'x',
				},
				toolbar: {
					show: true,
					tools: {
						pan: false,
						zoom: renderToString(<Icon name="ChartSearch" width={16} />),
						download: renderToString(
							<Icon name="ChartBurgerMenu" width={16} />
						),
						zoomin: renderToString(<Icon name="ChartIn" width={16} />),
						zoomout: renderToString(<Icon name="ChartOut" width={16} />),
						reset: renderToString(<Icon name="Reset" width={18} />),
					},
					offsetX: 0,
					offsetY: -25,
					export: {
						png: {
							filename: exportDownloadName,
						},
						svg: {
							filename: exportDownloadName,
						},
						csv: {
							filename: exportDownloadName,
						},
					},
				},
				events: {
					beforeZoom: (_event, { xaxis }) => {
						// Ensures we do not zoom out further than the present datapoints
						const first = dataSet.data[0].x;
						const last = dataSet.data.at(-1)?.x;

						const mainDifference = (last ?? 0) - first!;
						const zoomDifference = xaxis.max - xaxis.min;

						if (zoomDifference > mainDifference)
							return {
								// dont zoom out any further
								xaxis: {
									min: first,
									max: last,
								},
							};
						else {
							return {
								// keep on zooming
								xaxis: {
									min: xaxis.min,
									max: xaxis.max,
								},
							};
						}
					},
				},
				// Sparkline removes anything but the chart itself
				sparkline: {
					enabled: false,
				},
				animations: {
					enabled: true,
					speed: 150,
					easing: 'easeout',
					animateGradually: {
						enabled: false,
					},
					dynamicAnimation: {
						enabled: true,
						speed: 150,
					},
				},
			},
			grid: {
				show: true,
				yaxis: {
					lines: {
						show: true,
					},
				},
				xaxis: {
					lines: {
						show: true,
					},
				},
				borderColor: 'rgba(255,255,255,0.2)',
				padding: {
					left: -5,
				},
			},
			dataLabels: {
				enabled: false,
			},
			markers: {
				size: 0,
				hover: {
					size: 4,
				},
				colors: ['#fff'],
				fillOpacity: 1,
				strokeWidth: 0,
				showNullDataPoints: false,
			},
			fill: {
				type: [...fillers, 'gradient'],
				gradient: {
					shadeIntensity: 1,
					opacityFrom: 0.5,
					opacityTo: 0,
					stops: [0, 100],
					type: 'vertical',
				},
				colors: [...markerColors, '#009de0'],
			},
			tooltip: {
				custom: function ({ series, seriesIndex, dataPointIndex, w }) {
					const timestamp = w.globals.seriesX[seriesIndex][dataPointIndex];
					const dataSets: (ApexSeries | RFCDataSet)[] = [
						...neededThresholds,
						dataSet,
					];

					const values = series.map((serie: any) => serie[dataPointIndex]);

					return renderToString(
						<RFCTooltip
							colors={[...colors, '#009de0']}
							units={dataUnits}
							items={values.map((value: string, index: number) => {
								/** Infer the wanted amount of decimals in the tooltip, based on the unit type */
								const inferValueDecimals = (dataUnits: string): number => {
									switch (dataUnits) {
										case 'counts':
											return 0;
										default:
											return 2;
									}
								};

								const dataSet = dataSets[index] as RFCDataSet;

								// All theshold data is saved as a reference line, since it
								// doesn't quite align with the normal naming scheme
								const isThresholdData = dataSet.name.includes('threshold');

								if (isThresholdData) {
									return {
										seriesName: 'Reference line',
										value: `Δ${timestamp} mm - ${Number(value).toFixed(
											inferValueDecimals(dataUnits)
										)}`,
									};
								}

								return {
									seriesName: dataSet.shortName,
									value: `Δ${timestamp} mm - ${Number(value).toFixed(
										inferValueDecimals(dataUnits)
									)}`,
								};
							})}
						/>
					);
				},
				followCursor: true,
			},
		} as ApexOptions;
	}, [dataSet, neededThresholds, dateRange]);

	return (
		<>
			{dataSet &&
				dataSet.data.length > 0 &&
				neededThresholds !== null &&
				options && (
					<>
						<Chart
							data-testid="lineChart"
							type="area"
							series={[...neededThresholds, { ...dataSet, type: 'area' }]}
							height={height}
							width="100%"
							options={options}
						/>
					</>
				)}
		</>
	);
};

/** Formats the value of yaxis to be more easily readable */
const formatYAxisValue = (units: string) => (value: number) => {
	const formattedValue = Intl.NumberFormat('en-GB', {
		notation: 'compact',
	}).format(value);

	return `${formattedValue} ${units}`;
};

type TooltipProps = {
	items: { seriesName: string; value: string }[];
	colors: string[];
	units: string;
};

export const RFCTooltip: FC<TooltipProps> = ({ items, colors, units }) => (
	<div className="MultilineChartTooltip">
		<div className="MultilineChartTooltip__Content">
			{items.map((item, index) => (
				<div className="MultilineChartTooltip__Item" key={item.seriesName}>
					<IndicatorDot status="default" size={8} color={colors[index]} />
					<div className="MultilineChartTooltip__Label">{item.seriesName}</div>
					<div className="MultilineChartTooltip__Value">
						{item.value}&nbsp;
						{units}
					</div>
				</div>
			))}
		</div>
	</div>
);
