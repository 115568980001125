import { FC, PropsWithChildren, ReactNode } from 'react';
import './ActionModal.scss';

type Props = {
	title: string;
	footerContent: ReactNode;
	width?: string;
};

export const ActionModal: FC<PropsWithChildren<Props>> = ({
	footerContent,
	title,
	children,
	width,
}) => {
	const style = {
		width,
	};

	return (
		<>
			<div className="ModalOverlay">
				<div className="ActionModal" style={style}>
					<div className="ActionModal__Header">
						<div
							className="ActionModal__Title"
							dangerouslySetInnerHTML={{ __html: title }}
						></div>
					</div>
					<div className="ActionModal__Body">{children}</div>
					<div className="ActionModal__Footer">{footerContent}</div>
				</div>
			</div>
		</>
	);
};
