import {
	FormInput,
	InputType,
} from '../../../Core/components/FormInput/FormInput';
import { useForm } from 'react-hook-form';
import { Modal } from '../../../Core/components/Modal/Modal';
import './ExplorerSettings.scss';
import { KeyboardEvent, useEffect, useMemo, useRef } from 'react';
import { useAppSelector } from '../../../Core/redux/useAppSelector';
import { useAppDispatch } from '../../../Core/redux/useAppDispatch';
import {
	setSeaElevation,
	setViewCubeSize,
	toggleSeaEnabled,
	toggleSoilEnabled,
	toggleViewCubeEnabled,
	ViewCubeSizeOptions,
	toggleDisplayStructureColors,
	StructureType,
	setStructureType,
	toggleDisplayLegendInformation,
	setSoilElevation,
	setJointSize,
} from './Explorer.Settings.slice';
import { PayloadActionCreator } from '@reduxjs/toolkit';
import { Button } from '../../../Core/components/Button/Button';

/**
 * ExplorerSettingsProps
 * @interface ExplorerSettingsProps
 * @property {() => void} onClose - Callback function to close modal. Called when user clicks cancel or submits form.
 */

type StateProperties = {
	viewCubeEnabled: boolean;
	viewCubeSize: ViewCubeSizeOptions;
	seaEnabled: boolean;
	seaElevation: number;
	soilEnabled: boolean;
	soilElevation: number;
	displayStructureColors: boolean;
	structureType: StructureType['label'];
	displayLegendInformation: boolean;
	jointSize: number;
};

type KeyOptions = {
	key: string;
	label: string;
};

type FormInputData = {
	label: string;
	name: keyof StateProperties;
	inputType: InputType;
	disabled?: boolean;
	options?: KeyOptions[];
	allowOnlyNumbers?: boolean;
	action: PayloadActionCreator<any, string>;
};

interface ExplorerSettingsProps {
	onClose: () => void;
}

// Form inputs definition outside the component
const formInputsData: FormInputData[] = [
	{
		label: 'Sea Enabled',
		name: 'seaEnabled',
		inputType: 'toggle',
		action: toggleSeaEnabled,
	},
	{
		label: 'Sea Elevation',
		name: 'seaElevation',
		inputType: 'number',
		allowOnlyNumbers: true,
		action: setSeaElevation,
	},
	{
		label: 'Soil Enabled',
		name: 'soilEnabled',
		inputType: 'toggle',
		action: toggleSoilEnabled,
	},
	{
		label: 'Soil Elevation',
		name: 'soilElevation',
		inputType: 'number',
		allowOnlyNumbers: true,
		action: setSoilElevation,
	},
	{
		label: 'ViewCube Enabled',
		name: 'viewCubeEnabled',
		inputType: 'toggle',
		action: toggleViewCubeEnabled,
	},
	{
		label: 'ViewCube Size',
		name: 'viewCubeSize',
		inputType: 'select',
		options: [
			{
				key: 'small',
				label: 'Small',
			},
			{
				key: 'regular',
				label: 'Regular',
			},
			{
				key: 'large',
				label: 'Large',
			},
			{
				key: 'veryLarge',
				label: 'Very Large',
			},
		],
		action: setViewCubeSize,
	},
	{
		label: 'Display Structure Colors',
		name: 'displayStructureColors',
		inputType: 'toggle',
		action: toggleDisplayStructureColors,
	},
	{
		label: 'Structure Type',
		name: 'structureType',
		inputType: 'select',
		options: [
			{
				key: 'realistic',
				label: 'Realistic',
			},
			{
				key: 'simplified',
				label: 'Simplified',
			},
		],
		action: setStructureType,
	},
	{
		label: 'Display Legend Information',
		name: 'displayLegendInformation',
		inputType: 'toggle',
		action: toggleDisplayLegendInformation,
	},
	{
		label: 'Joint Size',
		name: 'jointSize',
		inputType: 'number',
		allowOnlyNumbers: true,
		action: setJointSize,
	},
];

export const ExplorerSettings = ({ onClose }: ExplorerSettingsProps) => {
	// Get current settings from redux store
	const {
		viewCubeEnabled,
		viewCubeSize,
		seaEnabled,
		soilEnabled,
		seaElevation,
		soilElevation,
		displayStructureColors,
		structureType,
		displayLegendInformation,
		jointSize,
	} = useAppSelector((state) => state.explorerSettings);

	const stateValues: StateProperties = useMemo(() => {
		return {
			viewCubeEnabled,
			viewCubeSize: viewCubeSize.label,
			seaEnabled,
			seaElevation,
			soilEnabled,
			soilElevation,
			displayStructureColors,
			structureType: structureType.label,
			displayLegendInformation,
			jointSize,
		};
	}, [
		viewCubeEnabled,
		viewCubeSize.label,
		seaEnabled,
		seaElevation,
		soilEnabled,
		soilElevation,
		displayStructureColors,
		structureType.label,
		displayLegendInformation,
		jointSize,
	]);

	// Form with default values
	const { control, getValues, watch } = useForm({
		defaultValues: stateValues,
	});

	// Watch variable to disable child input if parent is disabled
	const isSeaEnabled = watch('seaEnabled');
	const isViewCubeEnabled = watch('viewCubeEnabled');
	const isSoilEnabled = watch('soilEnabled');

	// useMemo for formInputs
	const formInputs = useMemo(() => {
		return formInputsData.map((input) => ({
			...input,
			currentValue: stateValues[input.name as keyof StateProperties],
			disabled:
				(input.name === 'seaElevation' && !isSeaEnabled) ||
				(input.name === 'viewCubeSize' && !isViewCubeEnabled) ||
				(input.name === 'soilElevation' && !isSoilEnabled),
		}));
	}, [isSeaEnabled, isSoilEnabled, isViewCubeEnabled, stateValues]);

	const explorerSettingsRef = useRef<HTMLDivElement>(null);

	//Ensures that the modal is focused when it is opened to enable tabbing
	useEffect(() => {
		if (explorerSettingsRef.current) {
			explorerSettingsRef.current.focus();
		}
	}, [explorerSettingsRef]);

	const dispatch = useAppDispatch();

	// Handle keydown events
	const handleKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
		if (event.key === 'Enter') {
			onSubmit();
		} else if (event.key === 'Escape') {
			handleCancelModal();
		}
	};

	// Handle cancel button
	const handleCancelModal = () => {
		onClose();
	};

	// Submit form
	const onSubmit = () => {
		const values = getValues() as Partial<StateProperties>;

		formInputs.forEach(({ name, disabled, action, currentValue }) => {
			if (!disabled && values[name as keyof StateProperties] !== currentValue) {
				dispatch(action(values[name as keyof StateProperties]));
			}
		});

		onClose();
	};

	return (
		<div
			className="ExplorerSettings"
			ref={explorerSettingsRef}
			tabIndex={-1} // Needed to focus the div for keydown events
			onKeyDown={handleKeyDown}
		>
			<Modal
				title="Explorer Settings"
				footerContent={
					<div className="Modal__Footer__Buttons">
						<Button
							className="Button Button--Default"
							onClick={handleCancelModal}
							theme="OutlineBlue"
						>
							Cancel
						</Button>
						<Button className="Button Button--Primary" onClick={onSubmit}>
							Save
						</Button>
					</div>
				}
				onClickOutside={handleCancelModal}
			>
				<div className="ExplorerSettings__FormContainer">
					<form className="ExplorerSettings__Form" onSubmit={onSubmit}>
						{formInputs.map((input) => (
							<FormInput
								key={input.name}
								label={input.label}
								theme={'Default'}
								control={control}
								// @ts-ignore
								name={input.name}
								inputType={input.inputType}
								disabled={input.disabled}
								options={input.options}
							/>
						))}
					</form>
				</div>
			</Modal>
		</div>
	);
};
