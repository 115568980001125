import { ReactNode, useMemo } from 'react';
import { ExtrudeGeometryOptions, Shape } from 'three';

interface BoxExtrusionProps {
	children?: ReactNode;
	height?: number;
	width?: number;
	flangeThickness?: number;
	webThickness?: number;
}

// This is the extrusion of the Box shape. It is only used in the RealisticBoxMesh component.
export const BoxExtrusion = ({
	children,
	height = 1.4,
	width = 1.4,
	flangeThickness = 0.2,
	webThickness = 0.2,
}: BoxExtrusionProps) => {
	// This is the extrusion settings of the Box.
	// bevel is disabled because it is not needed and it is expensive to calculate + it looks bad :)
	// It is on purpose the depth is set to 1, because the IBeam is scaled in the RealisticBoxMesh component.
	const extrudeSettings: ExtrudeGeometryOptions = useMemo(
		() => ({
			bevelEnabled: false,
			depth: 1,
		}),
		[]
	);

	// The shape is created with the help of the Shape class from three.js.
	// Instead of using one shape, two shapes are used to create the Box.
	// This is because the Box has a different shape on the top and bottom.
	// And the extrusion have a tendency to create a weird shape on the top and bottom
	// if the shape is not split into two.

	const shapeFirstPart = useMemo(() => {
		const shape = new Shape();
		shape.moveTo(width / 2, 0);
		shape.lineTo(width / 2, height / 2);
		shape.lineTo(-width / 2, height / 2);
		shape.lineTo(-width / 2, height / 2 - flangeThickness);
		shape.lineTo(width / 2 - webThickness, height / 2 - flangeThickness);
		shape.lineTo(width / 2 - webThickness, -height / 2);
		shape.lineTo(width / 2, -height / 2);

		return shape;
	}, [flangeThickness, height, webThickness, width]);

	const shapeOtherPart = useMemo(() => {
		const shapeOther = new Shape();
		shapeOther.moveTo(-width / 2, 0);
		shapeOther.lineTo(-width / 2, -height / 2);
		shapeOther.lineTo(width / 2, -height / 2);
		shapeOther.lineTo(width / 2, -height / 2 - flangeThickness);
		shapeOther.lineTo(-width / 2 - webThickness, -height / 2 - flangeThickness);
		shapeOther.lineTo(-width / 2 - webThickness, height / 2);
		shapeOther.lineTo(-width / 2, height / 2);

		return shapeOther;
	}, [flangeThickness, height, webThickness, width]);

	return (
		<extrudeGeometry args={[[shapeFirstPart, shapeOtherPart], extrudeSettings]}>
			{children}
		</extrudeGeometry>
	);
};
