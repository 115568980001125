import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../Core/redux/store';
import { useAppSelector } from '../../Core/redux/useAppSelector';

interface State {
	dateRange: [Date, Date] | null;
	selectedDate: number;
	zoomRange: [number, number] | null;
}

const initialState: State = {
	dateRange: null,
	selectedDate: new Date().getTime(),
	zoomRange: null,
};

export const dashboard = createSlice({
	name: 'dashboard',
	initialState: initialState,
	reducers: {
		setDateRange: (
			state,
			{ payload }: PayloadAction<[Date, Date] | null>
		): State => {
			state.dateRange = payload;

			return state;
		},
		setSelectedDate: (state, { payload }: PayloadAction<number>): State => {
			state.selectedDate = payload;

			return state;
		},
		setZoomRange: (
			state,
			{ payload }: PayloadAction<[number, number] | null>
		): State => {
			state.zoomRange = payload;

			return state;
		},
	},
});

export const { setDateRange, setSelectedDate, setZoomRange } =
	dashboard.actions;

const getMemoizedDateRange = createSelector(
	(state: RootState) => state.dashboard.dateRange,
	(dates) => dates
);

export const useDateRange = (): [Date, Date] | null =>
	useAppSelector(getMemoizedDateRange);

export const useSelectedDate = () =>
	useAppSelector((state: RootState) => state.dashboard.selectedDate);

export const useZoomRange = () =>
	useAppSelector((state: RootState) => state.dashboard.zoomRange);
